<!-- <header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
        <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>


        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/home">What's New</a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/style">Style</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-1"><i
                                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/collect">
                        Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                            class="badge badge-danger ml-1">{{collectItemsCount}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
                        <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
                        <!-- Wishlist
                    </a>
                </li>
                <li class="nav-item dropdown btn-group dropleft" dropdown>
                    <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
                        (click)="checkAuth()">
                        Profile<span class="caret"></span></a>
                    <div *ngIf="auth.user$ | async as user">
                        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
                            <div class="divider dropdown-divider"></div>
                            <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<br> -->
<!--Section: Products v.3-->
<section class="section pb-3 wow fadeIn" data-wow-delay="0.3s">

    <!--Section heading-->
    <h1 class="font-weight-bold text-center h1 my-2">Favourite Shops</h1>
    <!--Section description-->

    <div class="row row-cols-1 row-cols-md-3 ml-2 mr-2">
        <div class="col mb-4">
            <div class="card h-100">
                <!--Card image-->
                <div class="view overlay">
                    <img class="card-img-top"
                        src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fthe_bead_shop_outside_photo.jpeg?alt=media&token=8153358f-263a-423d-a0bd-3e87894675fd"
                        alt="Card image cap">
                    <a routerLink="/shopmain/UTq5ihN1arwp60h9bckk">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!--Card content-->
                <div class="card-body">

                    <!--Title-->
                    <h4 class="card-title">The Bead Shop</h4>
                    <!--Text-->
                    <p class="card-text">At The Bead Shop, we provide a friendly knowledgeable bead buying experience
                        whether you visit us in our bricks and mortar shop, online or attend one of our workshops.</p>
                    <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
                    <button type="button" class="btn btn-primary"><mdb-icon fas icon="store"class="mr-1"></mdb-icon>Visit
                        Shop</button>

                </div>

            </div>
            <!-- Card -->
        </div>
        <div class="col mb-4">
            <div class="card h-100">
                <!--Card image-->
                <div class="view overlay">
                    <img class="card-img-top"
                        src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/notts.jpg?alt=media&token=28166491-7cd8-4b28-9911-b18bba52e577"
                        alt="Card image cap">
                    <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!--Card content-->
                <div class="card-body">

                    <!--Title-->
                    <h4 class="card-title">Shop Name</h4>
                    <!--Text-->
                    <p class="card-text">Some quick example text to build on the Shop Name and make up the bulk of the
                        card's content.</p>
                    <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
                    <button type="button" class="btn btn-primary">View Shop</button>

                </div>

            </div>
            <!-- Card -->
        </div>
        <div class="col mb-4">
            <div class="card h-100">
                <!--Card image-->
                <div class="view overlay">
                    <img class="card-img-top"
                        src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/notts.jpg?alt=media&token=28166491-7cd8-4b28-9911-b18bba52e577"
                        alt="Card image cap">
                    <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!--Card content-->
                <div class="card-body">

                    <!--Title-->
                    <h4 class="card-title">Shop Name</h4>
                    <!--Text-->
                    <p class="card-text">Some quick example text to build on the Shop Name and make up the bulk of the
                        card's content. Some quick example text to build on the Shop Name and make up the bulk of the
                        card's content.</p>
                    <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
                    <button type="button" class="btn btn-primary">View Shop</button>

                </div>

            </div>
            <!-- Card -->
        </div>
        <div class="col mb-4">
            <div class="card h-100">
                <!--Card image-->
                <div class="view overlay">
                    <img class="card-img-top"
                        src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/notts.jpg?alt=media&token=28166491-7cd8-4b28-9911-b18bba52e577"
                        alt="Card image cap">
                    <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!--Card content-->
                <div class="card-body">

                    <!--Title-->
                    <h4 class="card-title">Shop Name</h4>
                    <!--Text-->
                    <p class="card-text">Some quick example text to build on the Shop Name and make up the bulk of the
                        card's content.</p>
                    <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
                    <button type="button" class="btn btn-primary">View Shop</button>

                </div>

            </div>
            <!-- Card -->
        </div>
    </div>

</section>