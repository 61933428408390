import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { ToastrModule } from 'ngx-toastr';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { NgAisModule } from 'angular-instantsearch';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { ProductsService } from './products.service';
import { GeoSearchComponent } from "./geo-search/geo-search.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';

// Firebase Libs
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ShopsComponent } from './shops/shops.component';
import { HomeComponent } from './home/home.component';
import { ShopmainComponent } from './shopmain/shopmain.component';
import { StyleComponent } from './style/style.component';
import { SearchComponent } from './search/search.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { MessagesComponent } from './messages/messages.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { MystyleComponent } from './mystyle/mystyle.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { LoginComponent } from './login/login.component';
import { CollectComponent } from './collect/collect.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { LandingpageComponent } from './landingpage/landingpage.component';


@NgModule({
  declarations: [
    AppComponent,
    ShopsComponent,
    HomeComponent,
    ShopmainComponent,
    StyleComponent,
    SearchComponent,
    GeoSearchComponent,
    SubcategoryComponent,
    MessagesComponent,
    FavouritesComponent,
    ProductdetailComponent,
    MystyleComponent,
    QrcodeComponent,
    LoginComponent,
    CollectComponent,
    WishlistComponent,
    LandingpageComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBixl0VQBfxBbTBMhdX_fqhEiImJwOTmrU"
    }),
    BrowserAnimationsModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    FormsModule,
    NgxEmojiPickerModule.forRoot(),
    HttpClientModule,
    NgxQRCodeModule, 
    YouTubePlayerModule,
    ToastModule.forRoot({
      maxOpened: 1, newestOnTop: true, preventDuplicates: true
    }),
    MDBBootstrapModulesPro.forRoot(),
    FontAwesomeModule,
    // Firebase Modules
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    [NgAisModule.forRoot(), BrowserModule],
    AppRoutingModule
  ],
  providers: [AuthService, MDBSpinningPreloader, ProductsService],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
