import { Component, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Collect } from '../collect.model';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { LocationStrategy } from '@angular/common';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Category } from '../category.model';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss']
})
export class SubcategoryComponent implements OnInit {

  constructor(private afs: AngularFirestore, private route: ActivatedRoute,
    location: LocationStrategy,
    private router: Router,
    private AngularFireAuth: AngularFireAuth) {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
    })
    location.onPopState(() => {
      // alert(window.location);
      this.backPressed();
    });
  }

  subCategoriesCollection: AngularFirestoreCollection<Category>;
  subCategories: Observable<Category[]>;
  categoryname: string;

  categoryDoc: AngularFirestoreDocument<Category>;
  category: Observable<any>;
  categorydata: any;

  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;

  docid: string;
  categoryid: string;
  values: string;
  filter: string;
  parentdisplayname: string;
  displayname: string;
  algoliaindex: string;
  retailerlogo: string;
  userId: string;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.docid = params['docid']
      this.values = params['values']
      this.filter = params['filter']
      this.displayname = params['displayname']
      this.parentdisplayname = params['parentdisplayname']
      this.algoliaindex = params['algoliaindex']
      this.retailerlogo = params['retailerlogo']
    })
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.countCollects()
      console.log("ngOnInit - The userid is ", this.userId)
    })
    this.categoryid = "dKsLAkee5DvgiVMblCsz"
    this.subCategoriesCollection = this.afs.collection('Retailers').doc(this.docid).collection('Categories').doc(this.categoryid).collection('Subcategory', subCategoriesRef => {
      // Compose a query using multiple .where() methods
      return subCategoriesRef
        .where('location', '==', 'homepage').orderBy('position')
    });
    this.subCategories = this.subCategoriesCollection.valueChanges();

    console.log("The docid from the params to subcategory is: ", this.docid)

    this.categoryDoc = this.afs.collection('Retailers').doc(this.docid).collection('Categories').doc(this.categoryid);
    this.category = this.categoryDoc.valueChanges();
    this.categoryDoc.ref.get().then((doc) => {
      this.categorydata = doc.data();
      console.log('Category data is: ', this.categorydata);
    });
  };

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  logoClicked() { // without type info
    this.router.navigate(['/shopmain', this.docid]);
  }

  categoryClicked(categoryname: string, categorysubmenu: boolean, displayname: string) {
    this.filter = categoryname;
    this.displayname = displayname;
    console.log('Category data: ', categoryname, categorysubmenu);
      console.log('This category has a submenu as "true", so when the code is ready it will route to the associated category submenu page');
      // this.router.navigate(['/subcategory', this.docid, this.values, this.filter, this.displayname, this.algoliaindex, this.retailerlogo]);
      this.router.navigate(['/search', this.docid, this.values, this.filter, this.displayname, this.parentdisplayname, this.algoliaindex, this.retailerlogo]);
    }

    backPressed() {
      // this.router.navigate(['/subcategory', this.docid, this.values, this.filter, this.displayname, this.parentdisplayname, this.config.indexName, this.retailerlogo]);
      this.router.navigate(['/shopmain', this.docid]);
      console.log("Back button pressed: ")
    }

    countCollects() {
      this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
      this.collectCollection.valueChanges()
        .pipe(map(res => {
          this.collectsdata = res;
          this.collectItemsCount = this.collectsdata.length;
          console.log('Number of Collect items is: ', this.collectItemsCount);
        })
        )
        .subscribe();
    }

}
