import { Component, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Collect } from '../collect.model';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

const searchClient = algoliasearch(
  'NEE65IZ6KW',
  '80a2a740dddc661c372c243d9ebf2a1e'
);

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.css']
})

export class ShopsComponent implements OnInit {
  config = {
    // indexName: 'demo_ecommerce',
    indexName: 'contact',
    searchClient,
  };

  public searchParameters = {
    hitsPerPage: 4,
    query: 'vinyl',

  };

  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  userId: string;
  hasLoggedIn: boolean = false;

  faTag = faTag;
  faPortrait = faPortrait;
  faHeart = faHeart;
  faCog = faCog;

  constructor(private router: Router, public auth: AuthService,
    private afs: AngularFirestore,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth) {
  }

  ngOnInit() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.countCollects()
      console.log("ngOnInit - The userid is ", this.userId)
    })
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  myClickFunction(docid) {
    let movieId = docid;
    this.router.navigate(['/shopmain', docid]);
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }


}
