import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit {
  // collectCollection: AngularFirestoreCollection<Collect>;
  // collects: Observable<Collect[]>;
  // collectsdata: any;
  // collectItemsCount: 0;
  faTag = faTag;
  faPortrait = faPortrait;
  faHeart = faHeart;
  faCog = faCog;
  userId: string;
  login: any;
  hasLoggedIn: boolean = false;
  shopId: string;
  numberOfLoginAttempts: number = 0;
  results: Observable<string[]>;
  contactForm: FormGroup;
  anonymousLogin: boolean = false;

  signUpClickedOnce = false;

  constructor(private router: Router,
    public auth: AuthService,
    private AngularFireAuth: AngularFireAuth,
    private toastr: ToastrService,
    private afs: AngularFirestore) { }

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  id = '4Pi9IpFgUP8'
  playerVars = {
    cc_lang_pref: 'en',
  };
  private player;
  private ytEvent;

  onStateChange(event) {
    this.ytEvent = event.data;
  }
  savePlayer(player) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  ngOnInit() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) {
        this.userId = user.uid;
        console.log("ngOnInit - The userid is ", this.userId);
        this.numberOfLoginAttempts = this.numberOfLoginAttempts + 1;
        firebase.auth().currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            this.shopId = idTokenResult.claims.shopId;
            if (!this.shopId) {
              const options = { opacity: 1, maxOpened: 1, timeOut: 2000, newestOnTop: true, preventDuplicates: true };
              if (this.numberOfLoginAttempts < 2 && !this.anonymousLogin) {
                this.toastr.warning('Not registered as a Beta account', 'STREETSHOPS', options);
                console.log("No shopId with this account");
              }
            } else {
              console.log("User custom claims shopId is: ", idTokenResult.claims.shopId)
              console.log("User custom claims level is: ", idTokenResult.claims.level)
              return this.router.navigate(['/home']);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })
    this.contactForm = new FormGroup({
      nameInput: new FormControl(null, Validators.required),
      emailInput: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null, Validators.required),
    });
  }

  get nameInput() { return this.contactForm.get('nameInput'); }
  get emailInput() { return this.contactForm.get('emailInput'); }
  get message() { return this.contactForm.get('message'); }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  loginUser() {
    this.auth.googleSignin();
    // this.checkAuth();
  }

  onSubmit(): void {
    this.addSignUp();
  }

  addSignUp() {
    if (!this.signUpClickedOnce) {
      if (!this.hasLoggedIn) {
        this.auth.anonymousLogin();
        if (this.AngularFireAuth.authState) {
          this.anonymousLogin = true;
        }
        console.log('Annonymous state is: ', this.AngularFireAuth.authState);
        this.sendContact();
      }
    } else {
      console.log("Contact button already clicked");
    }
  }


  // checkAuth() {
  //   this.AngularFireAuth.authState.subscribe(user => {
  //     if (user) {
  //       this.hasLoggedIn = true;
  //       this.numberOfLoginAttempts = this.numberOfLoginAttempts + 1;
  //       if (this.hasLoggedIn) {
  //         firebase.auth().currentUser.getIdTokenResult()
  //           .then((idTokenResult) => {
  //             this.shopId = idTokenResult.claims.shopId;
  //             if (!this.shopId) {
  //               const options = { opacity: 1, maxOpened: 1, timeOut: 2000, newestOnTop: true, preventDuplicates: true };
  //               if (this.numberOfLoginAttempts < 2) {
  //                 this.toastr.warning('Not registered as a Beta account', 'STREETSHOPS', options);
  //                 console.log("No shopId with this account");
  //               }
  //             } else {
  //               console.log("User custom claims shopId is: ", idTokenResult.claims.shopId)
  //               console.log("User custom claims level is: ", idTokenResult.claims.level)
  //               return this.router.navigate(['/home']);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       }
  //     }
  //     else if (!this.hasLoggedIn) {
  //       console.log("user has NOT logged in");
  //     }
  //   })
  // }


  sendContact() {
    this.signUpClickedOnce = true;
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) {
        this.hasLoggedIn = true
        this.afs.collection('mail').doc(user.uid).set({
          to: "beta@streetshops.co.uk",
          message: {
            subject: "Contact message from StreetShops.",
            text: "Name: " + this.nameInput.value + "  <br />  Email: " + this.emailInput.value + "  <br />  Message: " + this.message.value,
            html: "Name: " + this.nameInput.value + "  <br />  Email: " + this.emailInput.value + "  <br />  Message: " + this.message.value,
          },
        })
          .then(function () {
            console.log("Document successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
      }
    })
    this.toastr.success('Message sent', '', {
      timeOut: 4000,
    });
  }

  refresh(): void {
    window.location.reload();
  }

}