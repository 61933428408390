<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    #jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    #toprow {
        background: #F2F3F4;
        /* height: 6vh; */
        margin-bottom: 10px;
        /* color: rgb(74, 33, 150); */
        line-height: 6vh;
        text-align: center;
    }

    .avatar {
        vertical-align: middle;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        margin-top: 20px;
        -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, .2);
        -moz-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, .2);
        box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, .2);
    }

    .aboutme {
        align-items: center;
        justify-content: center;
    }

    /* h5 {
      font-weight:bold;
  } */
</style>
<header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
        <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
        <!-- Collapsible content -->

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/home">Home</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link active" pageScroll routerLink="/style">Style</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/collect">
                        Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                            class="badge badge-danger ml-1">{{collectItemsCount}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
                        <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
                        Wishlist
                    </a>
                </li>
                <li class="nav-item dropdown btn-group dropleft" dropdown>
                    <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
                        (click)="checkAuth()">
                        Profile<span class="caret"></span></a>
                    <div *ngIf="auth.user$ | async as user">
                        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
                            <div class="divider dropdown-divider"></div>
                            <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<div class="mt-3"></div>
<!-- Nav tabs -->
<!-- <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-4 pills-secondary purple margin'" [contentClass]="''" > -->
<!-- <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-4 pills-dark grey margin'" [contentClass]="''" > -->
<mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-dark mb-2'" [justified]="true" [contentClass]="'card'">
    <!--Panel 1-->
    <mdb-tab heading="My Style">
        <div class="row">
            <div class="col-12">
                <br>
                <!-- <p>This is your list of style choices. Create a collage of styles for specific shops, or across shops.
                    Share your style choices with friends using social media or email.</p> -->

                <!--Section: Testimonials v.1-->
                <section class="section pb-3 text-center">
                    <!--Section heading-->
                    <h1 class="section-heading h1 pt-4">My Style</h1>
                    <!--Section description-->
                    <p class="section-description">This is your list of style choices. <br>Create a collage of styles for
                        specific shops, or across shops.<br>
                        Share your style choices with friends using social media or email.</p>

                    <button type="button" mdbBtn color="default" rounded="true" data-toggle="modal"
                        data-target="#basicExample" (click)="frame.show()" mdbWavesEffect>Add Style <mdb-icon fas
                            icon="pen" class="prefix white-text"></mdb-icon></button>

                    <div mdbModal #frame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1"
                        role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header text-center">
                                    <h4 class="modal-title w-100 font-weight-bold">New Style</h4>
                                    <button type="button" #closeBtn class="close" data-dismiss="modal"
                                        aria-label="Close" (click)="frame.hide()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                </div>
                                <div class="modal-body mx-3">
                                    <div class="md-form mb-5">
                                        <!-- <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon> -->
                                        <textarea class="form-control pl-2 my-0" id="exampleFormControlTextarea1"
                                            rows="3" [(ngModel)]="message1"
                                            placeholder="New style name here...">  </textarea>
                                        <i (click)="toggled = !toggled" [(emojiPickerIf)]="toggled"
                                            [emojiPickerDirection]="'bottom' || 'top' || 'left' || 'right'"
                                            (emojiPickerSelect)="handleSelection($event)">
                                            <button mdbBtn type="button" color="primary" size="sm" rounded="true"
                                                mdbWavesEffect> Add Emoji 😄
                                            </button>
                                        </i>
                                    </div>

                                    <!-- <!-- <div class="md-form mb-4"> -->
                                    <div class="md-form mb-5">
                                        <!-- <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon> -->
                                        <textarea class="form-control pl-2 my-0" id="exampleFormControlTextarea2"
                                            rows="3" [(ngModel)]="message2" placeholder="Add a summary..."></textarea>
                                    </div>

                                </div>
                                <div class="modal-footer d-flex justify-content-center">
                                    <button mdbBtn color="default" (click)="newStyle(message1, message2)"
                                        class="waves-light #closeBtn" mdbWavesEffect>Add</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Grid column-->
                    <div class="card-deck">
                        <div *ngFor="let style of styleArray">
                            <div class="card mt-3 view overlay zoom">
                                <div class="card-body">

                                    <!-- Background color -->
                                    <div class="card-up indigo lighten-1"></div>

                                    <!--Avatar-->
                                    <!-- <div class="avatar mx-auto white"><img src='{{userPhoto}}'
                                            alt="avatar mx-auto white" class="rounded-circle img-fluid">
                                    </div> -->
                                    <div class="avatar  white"><img src='{{userPhoto}}'
                                            (click)="showMyStyleList(style.styleid, style.owner, style.stylename, style.styleSummary, style.ownerAvatar)"
                                            alt="avatar mx-auto white" class="rounded-circle img-fluid">
                                    </div>
                                    <h5 class="card-title mt-2">{{style.owner}}</h5>
                                    <h3 class="card-text">{{style.stylename}}</h3>
                                    <p class="card-text">(version: {{style.version}})</p>
                                    <p class="card-text">{{style.styleSummary}}</p>
                                    <!-- <span class="badge badge-pill badge-primary">Views {{style.views}}</span><span
                                        class="badge badge-pill badge-success"> Followers
                                        {{style.followers}}</span> -->
                                    <!-- <button class="btn btn-outline-default btn-rounded waves-effect"
                                        (click)="showMyStyleList(style.styleid, style.owner, style.stylename, style.styleSummary, style.ownerAvatar)">View
                                        <i class="fas fa-eye ml-1"></i></button> -->
                                </div>
                                <div class="card-footer">
                                    <small class="text-muted">
                                        <div class="form-check float-left mb-1">
                                            <button mdbBtn color="alert" class="waves-light" type="button"
                                                (click)="deleteStylelist(style.styleid, 'ownStyle')" mdbWavesEffect>
                                                <mdb-icon fas icon="trash-alt" class="mr-1"></mdb-icon>Delete
                                            </button>
                                        </div>
                                    </small>
                                </div>
                                <!-- delete -->
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </mdb-tab>
    <!--Panel 2-->
    <mdb-tab heading="Follow">
        <div class="row">
            <!--Section: Testimonials v.1-->
            <section class="section pb-3 col-12 text-center">

                <!--Section heading-->
                <h1 class="section-heading h1 pt-4">Follow</h1>
                <!--Section description-->
                <p class="section-description">These are the styles you are following.</p>


                <!--Grid column-->
                <div class="card-deck">
                    <div *ngFor="let style of followStyleArray, let i = index ">
                        <div class="card mt-3 view overlay zoom">
                            <div class="card-body">
                                <!-- delete -->
                                <div *ngIf="style.version != followStyleLocalVersion[i]" class="float-right">
                                    <a class="btn-floating btn-sm btn-warning"><i class="fas fa-redo-alt"></i></a>
                                    <p>updated</p>
                                </div>
                                <!-- <div class="form-check float-left">
                                    <input type="checkbox" class="form-check-input" id="materialUnchecked">
                                    <label class="form-check-label" for="materialUnchecked">delete</label>
                                </div> -->
                                <br>
                                <!-- Background color -->
                                <div class="card-up indigo lighten-1"></div>

                                <!--Avatar-->
                                <div class="avatar  white"><img src='{{style.ownerAvatar}}' (click)="showFollowStyleList(style.styleid, style.version, style.createdBy, style.stylename, style.styleSummary, style.ownerAvatar)"
                                        class="rounded-circle img-fluid">
                                </div>

                                <h5 class="card-title mt-2">{{style.owner}}</h5>
                                <h3 class="card-text">{{style.stylename}}</h3>
                                <p class="card-text">(version: {{style.version}})</p>
                                <p class="card-text">{{style.styleSummary}}</p>
                                <!-- <span class="badge badge-pill badge-primary">Views {{style.views}}</span><span
                                    class="badge badge-pill badge-success"> Followers
                                    {{style.followers}}</span> -->
                                <!-- <button class="btn btn-outline-default btn-rounded waves-effect"
                                    (click)="showFollowStyleList(style.styleid, style.version, style.createdBy, style.stylename, style.styleSummary, style.ownerAvatar)">View
                                    <i class="fas fa-eye ml-1"></i></button> -->
                            </div>
                            <div class="card-footer">
                                <small class="text-muted">
                                    <div class="form-check float-left mb-4">
                                        <button mdbBtn color="alert" class="waves-light" type="button"
                                            (click)="deleteStylelist(style.styleid, 'followStyle')" mdbWavesEffect>
                                            <mdb-icon fas icon="trash-alt" class="mr-1"></mdb-icon>Delete
                                        </button>
                                    </div>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                </div>


            </section>
            <!--Section: Testimonials v.1-->

        </div>
    </mdb-tab>
</mdb-tabset>

 <!--Footer-->
 <footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
    <hr class="my-4">
    
    <!--Copyright-->
    <div class=" footer-copyright py-3">
      © 2021 Copyright:
      <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
    </div>
    <!--/.Copyright-->

  </footer>
  <!--/.Footer-->