import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";
import { Collect } from '../collect.model';
import { Style } from '../style.model';
import { auth } from 'firebase/app';
import algoliasearch from 'algoliasearch/lite';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';
import { Product } from '../product.model';
import { Retailer } from '../retailer.model';
import { Url } from 'url';
import { getJSDocThisTag } from 'typescript';

// declare var productImg: string;

var req = {
  method: 'POST',
  url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAR8jswyW10qMkHjZuIoZDYP4aY8opEtoc',
  headers: {
    'Content-Type': 'application/json'
  },
  data: { longDynamicLink: 'https://streetshops.page.link?sd=This%20is%20for%3A%20Just%20a%20test%20style%20%F0%9F%A4%94&si=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fssdev-54939.appspot.com%2Fo%2FRetailers%252Fretailer1%252F4%252F61791.jpg%3Falt%3Dmedia%26token%3D5828c5ff-184d-4603-891d-5b0d952e77ef&st=Hey%2C%20checkout%20my%20style&amv=0&apn=com.google.firebase.quickstart.streetshops&link=http%3A%2F%2Flocalhost%3A4200%2Fmystyle%2FLoF4Cj83VCV2dlviHpus' }
}

@Component({
  selector: 'app-mystyle',
  templateUrl: './mystyle.component.html',
  styleUrls: ['./mystyle.component.scss']
})
export class MystyleComponent implements OnInit {

  @ViewChild('content', { 'static': true }) content: ElementRef;
  retailerLogo: string;
  retailername: string;
  userId: string;
  userName: string;
  docid: string;
  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  styleDocId: string;
  stylelistArray: Array<AngularFirestoreDocument>;
  styleCollection: AngularFirestoreCollection<Style>;
  stylelistdata: any;
  stylelistLength: 0;
  stylelistDoc: AngularFirestoreDocument<Style>;
  styleDoc: AngularFirestoreDocument<Style>;
  stylelistProduct: any;
  styleOwner: string;
  styleName: string;
  styleSummary: string;
  ownerAvatar: string;
  styleData: any;
  productImgArray: Array<string>;
  styleLink: string;
  version: string;
  productImg: string;
  postId;
  postData;
  shortLink;
  shared: string;
  hasLoggedIn: boolean = false;
  productRoute: string;
  showLink: boolean = false;
  sharedBy: string;



  // value = 'Testing with value 1 2 3';

  value = `https://streetshops.page.link?sd=This%20is%20for%3A%20My%20music%20choices%20%F0%9F%8E%B5&si=null&st=Hey%2C%20checkout%20my%20style&amv=0&apn=com.google.firebase.quickstart.streetshops&link=https%3A%2F%2Fstreetshops.co.uk%2FUpxcxkfiGI3JtDz3MYCK%2FMy%20music%20choices%20%F0%9F%8E%B5%2F3%2Ffriend%2FRobin%20Youlton`;

  navbarOpen = false;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth,
    private http: HttpClient
  ) {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
    })
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // this.retailername = params['retailername']
      // this.retailerLogo = params['retailerlogo']
      this.styleDocId = params['styleid'],
        console.log('Style Id is: ', this.styleDocId)
      this.sharedBy = params['sharedby'],
        console.log('Style Id is: ', this.sharedBy)
      this.shared = params['shared'],
        console.log('This style shared: ', this.shared);
    });
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.userName = user.displayName
      this.countCollects()
      console.log("ngOnInit - The userid is ", this.userId)
      this.getStyleOwner();
      this.getStyleProducts();
    })
  }

  logoClicked() { // without type info
    this.router.navigate(['/shopmain', this.docid]);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }

  copyStyleLink() {
    this.showLink = true;
    this.toastr.info('Style link copied', 'STREETSHOPS', {
      timeOut: 2000
    });
  }

  getStyleOwner() {
    // var that = this;
    if (this.shared == 'shared') {
      this.styleDoc = this.afs.collection('MyStyle').doc(this.sharedBy).collection('Stylelists').doc(this.styleDocId);
    } else {
      this.styleDoc = this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(this.styleDocId);
    }
    this.styleDoc.ref.get().then((doc) => {
      this.styleData = doc.data();
      this.styleOwner = this.styleData.styleOwner;
      this.styleName = this.styleData.stylename;
      this.styleSummary = this.styleData.styleSummary;
      this.ownerAvatar = this.styleData.ownerAvatar;
      this.productImg = this.styleData.productImg;
      this.version = this.styleData.version;
      var uridetail = 'This is for: ' + this.styleName + '&si=null&st=Hey, checkout my style&amv=0&apn=com.google.firebase.quickstart.streetshops&link=https://streetshops.co.uk/' + this.styleDocId + '/' + this.styleName + '/' + this.version + '/' + 'friend' + '/' + this.userName;
      // this.styleLink = 'https://streetshops.page.link?sd=' + encodeURIComponent('This is for: ') + encodeURIComponent(this.styleName) + '&si=' + encodeURIComponent(this.productImg) + '&st=' + encodeURIComponent('Hey, checkout my style') + '&amv=0&apn=' + encodeURIComponent('com.google.firebase.quickstart.streetshops') + '&link=' + encodeURIComponent('https://streetshops.co.uk/') + encodeURIComponent(this.styleDocId + '/') + encodeURIComponent(this.styleName + '/') + encodeURIComponent(this.version + '/' + 'friend' + '/') + encodeURIComponent(this.userName);
      console.log('Within getStyleOwner first product Image is: ', this.productImg);
      this.styleLink = 'https://streetshops.page.link?sd=' + encodeURIComponent('This is for: ') + encodeURIComponent(this.styleName) + '&si=' + encodeURIComponent(this.productImg) + '&st=' + encodeURIComponent('Hey, checkout my style') + '&amv=0&apn=' + encodeURIComponent('com.google.firebase.quickstart.streetshops') + '&link=' + encodeURIComponent('https://ssdev-54939.web.app/mystyle/') + encodeURIComponent(this.styleDocId) + '/' + encodeURIComponent(this.userId) + '/shared';
      console.log('Stylelink is: ', this.styleLink);

      this.http.post<any>('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAR8jswyW10qMkHjZuIoZDYP4aY8opEtoc', { longDynamicLink: this.styleLink }).subscribe(data => {
        this.postId = data.id;
        this.postData = data;
        this.shortLink = data.shortLink;
        console.log('The POST short URL response is: ', this.shortLink);
      });
    })
  }

  getStyleProducts() {
    // var that = this;
    console.log('Style products data being gathered');
    this.stylelistArray = [];
    this.productImgArray = [];
    var runCount = 0;
    if (this.shared == 'shared') {
      this.styleCollection = this.afs.collection('MyStyle').doc(this.sharedBy).collection('Stylelists').doc(this.styleDocId).collection('products')
    } else {
      this.styleCollection = this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(this.styleDocId).collection('products')
    }
    this.styleCollection.valueChanges()
      .pipe(
        map(res => {
          runCount = runCount + 1;
          if (runCount < 2) {
            this.stylelistdata = res;
            console.log('Style list length of items is: ', this.stylelistdata.length);
            this.stylelistLength = this.stylelistdata.length;
            for (var i = 0; i <= this.stylelistLength - 1; i++) {
              this.stylelistDoc = this.afs.collection('Retailers').doc(this.stylelistdata[i].retailerreference).collection('Products').doc(this.stylelistdata[i].productid);
              this.stylelistDoc.ref.get().then((doc) => {
                this.stylelistProduct = doc.data();
                this.stylelistArray.push(this.stylelistProduct);
                this.productImgArray.push(this.stylelistProduct.mainimage);
                this.productImg = this.productImgArray[0];
                console.log('The first product image is: ', this.productImg);
                console.log('the encoded productImg is: ', encodeURIComponent(this.productImg));
              });
            }
          }
        })
      )
      .subscribe()
  };

  followStyle() {
    console.log('Will Follow this style');
    this.afs.collection('Customers').doc(this.userId).collection('MyStyle').doc(this.styleDocId).set({
      changestate: 'unchanged',
      savedstate: 'saved',
      source: 'friend',
      styleid: this.styleDocId,
      sharedBy: this.sharedBy,
      version: this.version,
      createdDate: new Date(),
    }, { merge: true })
      .then(() => {
        console.log("Shared Style Data Document successfully written!");
        // this.getProducts();
      }
      )
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }

  productSelected(shopId: string, productId: string) {
    console.log('shopId is: ', shopId, ' productid is: ', productId);
    this.productRoute = "/product/" + shopId + '/' + productId;
    return this.router.navigate([this.productRoute]);
  }

  deleteProduct(productId: string) {
    this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(this.styleDocId).collection('products').doc(productId).delete()
      .then(function () {
        console.log("Document successfully deleted");
      })
      .catch(function (error) {
        console.error("Error deleting document: ", error);
      });
    this.getStyleProducts();
  }

}

