import { Component, ViewChild, OnInit, Inject, ɵSafeHtml } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';
import algoliasearch from 'algoliasearch/lite';
import { Product } from '../product.model';
import { Retailer } from '../retailer.model';
import { Variant } from '../variant.model';
import { Collect } from '../collect.model';
import { Style } from '../style.model';
import { DocumentRef } from '@agm/core/utils/browser-globals';
import { Url } from 'url';

const searchClient = algoliasearch(
  'NEE65IZ6KW',
  '80a2a740dddc661c372c243d9ebf2a1e'
);

// @Pipe({
//   name: 'byPassSecurity'
// })


@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.scss']
})

export class ProductdetailComponent implements OnInit {

  productDoc: AngularFirestoreDocument<Product>;
  product: Observable<Product>;
  productdata: any;

  styleArray: Array<AngularFirestoreDocument>;
  styleCollection: AngularFirestoreCollection<Style>;
  followStyleCollection: AngularFirestoreCollection<Style>;
  styleData: any;
  myStyleDoc: AngularFirestoreDocument<Style>;
  myStyleData: any;
  versionNumber: number;

  variantDoc: AngularFirestoreCollection<Variant>;
  variants: Observable<any>;
  variantdata: any;

  collectCollection: AngularFirestoreCollection;
  collectDocId: string;
  collectDocRef: DocumentReference;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;

  retailer: Observable<any>;
  retailerDoc: AngularFirestoreDocument<Retailer>;
  retailerdata: any;
  retailername: string;
  productid: string
  userId: string
  docid: string
  filter: string;
  displayname: string;
  parentdisplayname: string;
  values = '';
  retailerlogo: string;
  wishlistClickedOnce: boolean = false;
  collectClickedOnce: boolean = false;
  variantClicked: boolean = true;
  //*** Todo: variantClicked to be made dependent on whether varinats exist for this product. then the boolean check an be applied for asociated vraint products (lines 267 to 275)
  // variantClicked: boolean = false;
  variantType: string;
  hasLoggedIn: boolean = false;
  images: { img: string, thumb: string, description: string }[];
  youTubeURL: string;
  videoId: string;
  videoURLReady: boolean = false;
  productName: string;


  config = {
    // indexName: 'demo_ecommerce',
    indexName: 'contact',
    searchClient,
    // routing: true
  };

  constructor(private afs: AngularFirestore, private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    public domSanitizer: DomSanitizer,
    private AngularFireAuth: AngularFireAuth) {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
    })
  }
  transform(value: any, ...args: any[]) {
    throw new Error('Method not implemented.');
  }

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  navbarOpen = false;

  id = '4Pi9IpFgUP8'
  playerVars = {
    cc_lang_pref: 'en',
  };
  private player;
  private ytEvent;

  onStateChange(event) {
    this.ytEvent = event.data;
  }
  savePlayer(player) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.docid = params['docid']
      this.productid = params['productid']
      this.retailerlogo = params['retailerlogo']
    })
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid;
      this.countCollects();
      this.getOwnStyle();
      console.log("shopmain ngOnInit - The userid is ", this.userId);
    })
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    this.retailerDoc = this.afs.collection('Retailers').doc(this.docid);
    this.retailer = this.retailerDoc.valueChanges();
    this.retailerDoc.ref.get().then((doc) => {
      this.retailerdata = doc.data();
      console.log('Shop data: ', this.retailerdata);
    });

    this.productDoc = this.afs.collection('Retailers').doc(this.docid).collection('Products').doc(this.productid);
    this.product = this.productDoc.valueChanges();
    this.productDoc.ref.get().then((doc) => {
      this.productdata = doc.data();
      console.log('Product data: ', this.productdata + " productRef: ", this.productDoc);
      console.log('Product images: ', this.productdata.images);
      this.images = this.imagesToJSON(this.productdata.images);
      this.videoId = this.productdata.videoid;
      this.productName = this.productdata.productname;
    });

    this.variantDoc = this.afs.collection('Retailers').doc(this.docid).collection('Products').doc(this.productid).collection('SKU');
    this.variants = this.variantDoc.valueChanges();
    console.log('The variant data is: ', this.variants)
  };

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  onKey(event: any) { // without type info
    this.values = event.target.value;
    this.filter = "";
    this.displayname = "";
    this.parentdisplayname = "";
    this.router.navigate(['/search', this.docid, this.values, this.filter, this.displayname, this.parentdisplayname, this.retailerdata.algoliaindex, this.retailerdata.retailerlogo]);
  }

  logoClicked() { // without type info
    this.router.navigate(['/shopmain', this.docid]);
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }


  imagesToJSON(firestoreImages: Array<String>) {
    var images = [];
    for (var i = 0; i <= firestoreImages.length - 1; i++) {
      images.push({
        img: firestoreImages[i],
        thumb: firestoreImages[i],
        description: 'image',
      })
    }
    console.log('Images array is: ', images);
    return images;
  }

  getVideo(videoId: string) {
    if (videoId && !this.videoURLReady) {
      this.youTubeURL = "https://www.youtube.com/embed/" + videoId;
      this.videoURLReady = true;
      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.youTubeURL);
    }
  }

  messageShop() { // without type info
    var shopMessagePath = '/messages/' + this.docid + '/' + this.retailerdata.retailername + '/Product/' + this.productName;
    this.router.navigate([shopMessagePath]);
  }

  getOwnStyle() {
    console.log('Style data being gathered');
    this.styleArray = [];
    this.styleCollection = this.afs.collection('Customers').doc(this.userId).collection('MyStyle', ref => ref.where('source', '==', 'local'));
    this.styleCollection.valueChanges()
      .pipe(
        map(res => {
          this.styleData = res;
          console.log('Style length of items is: ', this.styleData.length);
          for (var i = 0; i <= this.styleData.length - 1; i++) {
            console.log('StyleDocRefId is: ', this.styleData[i].styleid);
            console.log('Style local data is: ', this.styleData[i]);
            this.myStyleDoc = this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(this.styleData[i].styleid);
            this.myStyleDoc.ref.get().then((doc) => {
              this.myStyleData = doc.data();
              this.styleArray.push(this.myStyleData);
              console.log('My Style data is: ', this.myStyleData);
            });
          }
        })
      )
      .subscribe();
  }

  addToStyle(styleid: string, version: string) {
    console.log('Add to style id: ', styleid);
    this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(styleid).collection('products').doc(this.productid).set({
      retailerreference: this.docid,
      productid: this.productid,
      docref: this.afs.doc('Retailers/' + this.docid + '/Products/' + this.productid).ref,
    }, { merge: true });

    this.versionNumber = Number(version) + 1
    this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(styleid).set({
      version: String(this.versionNumber)
    }, { merge: true });

    // this.styleCollection = this.afs.collection('Customers').doc(this.userId).collection('MyStyle', ref => ref.where('styleid', '==', styleid));
    // this.afs.collection('Customers').doc(this.userId).collection('MyStyle').doc(styleid).set({
    //   version: String(this.versionNumber)
    // }, { merge: true });
  }

  addWishlist() {
    if (!this.wishlistClickedOnce) {
      this.toastr.success('Added to Wishlist', this.retailerdata.retailername, {
        timeOut: 4000,
      });
      this.wishlistClickedOnce = true
      this.afs.collection('Customers').doc(this.userId).collection('Wishlist').doc(this.productid).set({
        docref: this.productDoc.ref,
        // retailername: this.retailername,
        retailername: this.retailerdata.retailername,
        retailerreference: this.docid,
        productreference: this.productid,
      })
        .then(function () {
          // alert("Added to your Wishlist");
          console.log("Document successfully written!");
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    }
  }

  variantSelected(variantType: string) {
    this.variantClicked = true;
    this.variantType = variantType;
    console.log('Variant has been clicked is: ', this.variantClicked + ' The selected variant type is:', this.variantType)
  }

  collectButtonClicked() {
    console.log('Click & Collect button has been clicked')
    if (!this.variantClicked) {
      console.log('No variant selected yet')
      this.toastr.success('Please select a variant', this.retailerdata.retailername, {
        timeOut: 2000,
      });
    } else {
      if (!this.collectClickedOnce) {
        this.toastr.success('Added to Collect', this.retailerdata.retailername, {
          timeOut: 2000,
        });
        this.collectClickedOnce = true;
        this.afs.collection('Retailers').doc(this.docid).collection('ClickNCollect')
          .add({
            // retailerName: this.retailerdata.retailername, retailerreference: this.docid, customerId: this.userId, productName: this.productdata.productname,
            // productPrice: this.productdata.productprice, variant: this.variantType, productImage: this.productdata.mainimage, productID: this.productid,
            // status: 'NEW', heldUntil: 'tbc', timestamp: new Date()
            //Todo: add variant attribute vale if associated for this product
            retailerName: this.retailerdata.retailername, retailerreference: this.docid, customerId: this.userId, productName: this.productdata.productname,
            productPrice: this.productdata.productprice, productImage: this.productdata.mainimage, productID: this.productid,
            status: 'NEW', heldUntil: 'tbc', timestamp: new Date()
          }
          ).then(docRef => {
            this.collectDocId = docRef.id;
            this.collectDocRef = docRef;
            console.log("Document written with ID: ", this.collectDocId)
            this.afs.collection('Retailers').doc(this.docid).collection('ClickNCollect').doc(docRef.id).set({
              collectDocRef: docRef.id,
            }, { merge: true })

            this.afs.collection('Customers').doc(this.userId).collection('Collect').add({
              collectDocRef: this.collectDocRef,
              retailerName: this.retailerdata.retailername,
              retailerreference: this.docid,
              productDocRef: this.productid,
              collectDocRefId: this.collectDocId
            })
              .then(function () {
                // alert("Added to your Wishlist");
                console.log("Document successfully written!");
              })
              .catch(function (error) {
                console.error("Error writing document: ", error);
              });
          })
      }
    }
  }

}

