<style>
  .fakeimg {
    height: 200px;
    background: #aaa;
  }

  /* Make the image fully responsive */
  .picsum-img-wrapper img {
    width: 100%;
    /* height: 100%; */
  }

  li {
    list-style-type: none;
  }

  /* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
  @media screen and (min-width: 601px) {
    div.example {
      font-size: 80px;
    }
  }

  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    div.example {
      font-size: 30px;
    }
  }

  @media (min-width: 34em) {
    .card-columns {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }

  @media (min-width: 48em) {
    .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  }

  @media (min-width: 62em) {
    .card-columns {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 3;
    }
  }

  @media (min-width: 75em) {
    .card-columns {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 3;
    }
  }
</style>

<!-- <header id="top-section">
  <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
    <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>


    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
      (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/home">What's New</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/style">Style</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
        </li>
      </ul> -->
<!-- 
      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/collect">
            Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
              class="badge badge-danger ml-1">{{collectItemsCount}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
 
            Wishlist
          </a>
        </li>
        <li class="nav-item dropdown btn-group dropleft" dropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
            (click)="checkAuth()">
            Profile<span class="caret"></span></a>
          <div *ngIf="auth.user$ | async as user">
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
              <div class="divider dropdown-divider"></div>
              <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header> -->

<section class="section pb-3 wow fadeIn" data-wow-delay="0.3s">

  <!--Section heading-->
  <h1 class="font-weight-bold text-center h1 my-2">Find Shops</h1>
  <!--Section description-->

  <ais-instantsearch [config]="config">

    <div class="container">
      <div class="row">
        <div class="col-md-4">
        </div>
        <div class="col-md-8">
          <div class="mt-4 mb-2" style="widows: 600px;">
            <div class="col-md-4">

            </div>

            <ais-search-box placeholder='Search for shops'>
            </ais-search-box>


          </div>
        </div>
      </div>
    </div>


    <app-geo-search>initialZoom={{2}}</app-geo-search>
    <div class="mt-4">
      <button mdbBtn color="primary" class="waves-light ml-3" type="button" (click)="filter.toggle()" mdbWavesEffect>
        <mdb-icon fas icon="filter" class="mr-1"></mdb-icon>Filter
      </button>
    </div>

    <div class="container" style="margin-top:30px">
      <div class="row">

        <div class="col-sm-3">
          <div mdbCollapse #filter="bs-collapse">

            <!-- <h2>Stretched Link in Card</h2> -->
            <h5>Shop Types</h5>
            <ais-refinement-list attribute="shop_type" [limit]="8"></ais-refinement-list>
            <br>

            <h5>Products Category</h5>
            <ais-refinement-list attribute="main_category" [limit]="8"></ais-refinement-list>
            <br>

            <!-- <h2>Stretched Link in Card</h2> -->
            <h5>2nd Category</h5>
            <ais-refinement-list attribute="category2" [limit]="8"></ais-refinement-list>
            <br>

            <!-- <h2>Stretched Link in Card</h2> -->
            <h5>3rd Category</h5>
            <ais-refinement-list attribute="category3" [limit]="8"></ais-refinement-list>
            <br>

            <!-- <h2>Stretched Link in Card</h2> -->
            <h5>4th Category</h5>
            <ais-refinement-list attribute="category4" [limit]="8"></ais-refinement-list>

          </div>
        </div>

        <div class="col-sm-9">
          <ais-hits>
            <ng-template let-hits="hits">
              <!-- <ol class="ais-Hits-list"> -->
              <div class="card-columns">
                <!-- <li *ngFor="let hit of hits" class="ais-Hits-item"> -->
                <li *ngFor="let hit of hits" class="card">
                  <img class="card-img-top" src="{{hit.thumbnail_url}}" alt="Card image" height="200px" width="200px">
                  <!-- src="https://www.jumblebee.co.uk/site/wp-content/uploads/2014/06/JB-FE-Shop_10.png" alt="Card image" > -->
                  <div class="card-body card-body-cascade text-center">
                    <h4 class="card-title">{{hit.name}}</h4>
                    <h6 class="font-weight-bold indigo-text py-2">{{hit.shop_type}}</h6>
                    <p class="card-text">{{hit.smart_location}}</p>
                    <p class="card-text">Open: {{hit.opening_times}}</p>
                    <button (click)="myClickFunction(hit.objectID)" class="btn btn-primary stretched-link">
                      <mdb-icon fas icon="store" class="mr-1"></mdb-icon>Visit
                      Shop
                    </button>
                  </div>



                  <!-- <div class="card-footer text-muted">
                  Footer
                </div> -->
                  <!-- </div> -->
                </li>
              </div>

              <!-- </ol> -->

            </ng-template>
          </ais-hits>
        </div>
      </div>
    </div>
    <ais-pagination></ais-pagination>
  </ais-instantsearch>


  <!-- <div class="container">
  <h2>Stretched Link in Card</h2>
  <p>Add the .stretched-link class to a link inside the card, and it will make the whole card clickable (the card
    will act as a link):</p>
  <div class="card-columns">
    <li class="card" *ngFor="let shop of shops | async">
      <img class="card-img-top" src="{{ shop.retailerlogo }}" alt="Card image" height="200px" width="200px">
      <div class="card-body">
        <h4 class="card-title">{{  shop.retailername }}</h4>
        <p class="card-text">{{ shop.retailername  }}</p>
        <a href="#" class="btn btn-primary stretched-link">See Shop</a>
      </div>
    </li>
  </div>
</div> -->

</section>