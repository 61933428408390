<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    #jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    /* .card-img-top {
      width: 100%;
      height: 15vw;
      object-fit: cover;
    } */
</style>
<header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
      <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
      <!-- Collapsible content -->
  
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
        (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" pageScroll routerLink="/home">What's New</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" pageScroll routerLink="/style">Style</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                  class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
          </li>
        </ul>
  
        <ul class="navbar-nav ml-auto nav-flex-icons">
          <li class="nav-item">
            <a class="nav-link" pageScroll routerLink="/collect">
              Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                class="badge badge-danger ml-1">{{collectItemsCount}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
              Wishlist
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <!-- <fa-icon [icon]='faPortrait'></fa-icon>  -->
              Profile
            </a>
          </li>
  
  
          <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
              aria-expanded="false">User1</a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#">User2</a>
                <a class="dropdown-item" href="#">User3</a>
                <a class="dropdown-item" href="#">Kids</a>
              </div>
            </li> -->
        </ul>
      </div>
    </nav>
  </header>

<div class="container">
    <div class="row">
        <div class="col-md-4">
            <!-- <img src="https://mdbootstrap.com/img/logo/mdb-transparent.png" height="30" alt="mdb logo"> -->
            <!-- <p class="mt-4">The Shop ID is: {{docid}}</p> -->
            <div class="mt-4">
                <img src="{{retailerlogo}}" id="retailerlogo" alt="" (click)="logoClicked()" class="z-depth-0">
            </div>
        </div>
        <div class="col-md-8">
            <div class="mt-4" style="widows: 600px;">

                <!-- <ais-search-box placeholder='Search for products' [searchAsYouType]="true" [autofocus]="true">
                </ais-search-box> -->

            </div>
        </div>
    </div>
</div>

<div class="text-center mt-2 mb-2 lm-5 rm-5">
    <h2>{{parentdisplayname}}</h2>
    <div class="container">
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-8">
                <h5>{{ (category | async)?.categorydesc }}</h5>
            </div>
            <div class="col-2">
            </div>
        </div>
    </div>
</div>


<div class="card-deck align-items-center justify-content-center">
    <div *ngFor="let subcategory of subCategories | async ; let i=index">
        <!-- <div *ngIf="i == 0 || i == 1 || i == 2 "> -->
        <!-- Card -->
        <mdb-card class="mb-4" style="width: 20rem;"
            (click)="categoryClicked(subcategory.categoryname, subcategory.categorysubmenu, subcategory.displayname)">
            <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                <!-- Card img -->
                <mdb-card-img src="{{subcategory.categoryimage}}" alt="sample photo" class="card-img-top">
                </mdb-card-img>
                <a>
                    <div class="mask"></div>
                </a>
            </div>
            <!--Card content-->
            <mdb-card-body class="text-center no-padding">
                <!--Title-->
                <mdb-card-title>
                    <h4>{{ subcategory.displayname }}</h4>
                </mdb-card-title>
            </mdb-card-body>
        </mdb-card>
    </div>
    <!-- </div> -->
</div>