import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { auth } from 'firebase/app';
import algoliasearch from 'algoliasearch/lite';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';
import { Product } from '../product.model';
import { Retailer } from '../retailer.model';
import { Category } from '../category.model';
import { Wishlist } from '../wishlist.model';
import { Loyalty } from '../loyalty.model';
import { Collect } from '../collect.model';
import { stringify } from 'querystring';
// import { AuthService } from '../services/auth.service';

import { map } from "rxjs/operators";
import { DocumentRef } from '@agm/core/utils/browser-globals';
// import { productsService } from '../products.service';

const searchClient = algoliasearch(
  'NEE65IZ6KW',
  '80a2a740dddc661c372c243d9ebf2a1e'
);

@Component({
  selector: 'app-shopmain',
  templateUrl: './shopmain.component.html',
  styleUrls: ['./shopmain.component.scss']
})
export class ShopmainComponent implements OnInit {

  config = {
    // indexName: 'demo_ecommerce',
    indexName: 'contact',
    searchClient,
    // routing: true
  };

  docid: string;
  productsCollection: AngularFirestoreCollection<Product>;
  retailerCollection: AngularFirestoreCollection<Retailer>;
  categoriesCollection: AngularFirestoreCollection<Category>;
  wishlistCollection: AngularFirestoreCollection<Wishlist>;
  collectCollection: AngularFirestoreCollection<Collect>;
  wishlists: Observable<Wishlist[]>;
  text: string;
  values = '';
  favClickedOnce = false;
  tabClickedOnce = false;
  hasLoggedIn: boolean = false;


  productDoc: AngularFirestoreDocument<Product>;
  products: Observable<Product[]>;
  product: any;

  categories: Observable<Category[]>;
  categoryname: string;
  categorysubmenu: string;
  filter: string;
  displayname: string;
  parentdisplayname: string;

  user: Observable<firebase.User>;
  userId: string;
  productname: string;

  retailerDoc: AngularFirestoreDocument<Retailer>;
  retailer: Observable<any>;
  retailerdata: any;
  retailername: string;
  retailerLogo: string;
  algoliaIndex: string;

  loyaltyDoc: AngularFirestoreDocument<Loyalty>;
  loyalty: Observable<any>;
  loyaltyCurrentPoints: 0;
  loyaltyData: any;

  collectDoc: AngularFirestoreDocument<Collect>;
  collect: Observable<any>;
  collectArray: Array<AngularFirestoreDocument>;
  collectData: any;
  collectProductData: any;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;

  wishlistDoc: AngularFirestoreDocument<Wishlist>;
  // wishlistItems: [any];
  wishlistdata: any;
  wishlistDocRefData: any;
  wishlistDocRef: DocumentReference;
  wishlist: Observable<any>;
  wishlistProduct: any;
  productDescription: Array<any>;
  wishlistArray: Array<AngularFirestoreDocument>;
  productheader: Array<string>;
  wishlistLength: 0;
  public wishlistProducts: Wishlist[] = [];


  faTag = faTag;
  faPortrait = faPortrait;
  faHeart = faHeart;
  faCog = faCog;


  // constructor(private afs: AngularFirestore, private route: ActivatedRoute, public auth: AuthService, 

  constructor(private afs: AngularFirestore, private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth) {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
    })
  }
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  navbarOpen = false;

  id = '4Pi9IpFgUP8'
  playerVars = {
    cc_lang_pref: 'en',
  };
  private player;
  private ytEvent;

  onStateChange(event) {
    this.ytEvent = event.data;
  }
  savePlayer(player) {
    this.player = player;
  }

  playVideo() {
    this.player.playVideo();
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  addShopFavourite() {
    if (!this.favClickedOnce) {
      this.toastr.success('Added to Favourites', this.retailerdata.retailername, {
        timeOut: 4000,
      });
      this.favClickedOnce = true
    }
  }

  messageShop() { // without type info
    var shopMessagePath = '/messages/' + this.docid + '/' + this.retailerdata.retailername + '/General' ;
    this.router.navigate([shopMessagePath]);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.docid = params['docid']
      this.retailerLogo = params['retailerlogo']
    }
    )
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.collectArray = [];
      this.countCollects()
      console.log("shopmain ngOnInit - The userid is ", this.userId)
    })
    console.log("The docid from the paramas to shopdetail is: ", this.docid)
    this.productsCollection = this.afs.collection('Retailers').doc(this.docid).collection('Products', productsRef => {
      // Compose a query using multiple .where() methods
      return productsRef
        .where('location', '==', 'homepage')
    });
    this.products = this.productsCollection.valueChanges();

    this.categoriesCollection = this.afs.collection('Retailers').doc(this.docid).collection('Categories', categoriesRef => {
      // Compose a query using multiple .where() methods
      return categoriesRef
        .where('location', '==', 'homepage').orderBy('position')
    });
    this.categories = this.categoriesCollection.valueChanges();
    // console.log('Shop data: ', this.categories);

    this.retailerDoc = this.afs.collection('Retailers').doc(this.docid);
    this.retailer = this.retailerDoc.valueChanges();
    this.retailerDoc.ref.get().then((doc) => {
      this.retailerdata = doc.data();
      console.log('Shop data: ', this.retailerdata);
    });
  }

  onKey(event: any) { // without type info
    this.values = event.target.value;
    this.filter = "";
    this.displayname = "";
    this.parentdisplayname = "";
    this.router.navigate(['/search', this.docid, this.values, this.filter, this.displayname, this.parentdisplayname, this.retailerdata.algoliaindex, this.retailerdata.retailerlogo]);
  }

  logoClicked() { // without type info
    this.router.navigate(['/shopmain', this.docid]);
  }

  categoryClicked(categoryname: string, categorysubmenu: boolean, displayname: string) {
    this.filter = categoryname;
    this.displayname = displayname;
    this.parentdisplayname = displayname;
    console.log('Category data: ', categoryname, categorysubmenu);
    if (!categorysubmenu) {
      this.router.navigate(['/search', this.docid, this.values, this.filter, this.displayname, this.parentdisplayname, this.retailerdata.algoliaindex, this.retailerdata.retailerlogo]);
    } else {
      console.log('This category has a submenu as "true", so when the code is ready it will route to the associated category submenu page');
      this.router.navigate(['/subcategory', this.docid, this.values, this.filter, this.displayname, this.parentdisplayname, this.retailerdata.algoliaindex, this.retailerdata.retailerlogo]);
    }
  }

  productClicked(docid: string, productid: string) {
    this.router.navigate(['/product', docid, productid]);
    console.log("Product item selected: ", productid)
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }


  getWishlist() {
    console.log('Wishlist data being gathered');
    this.wishlistArray = [];
    var runCount = 0;
    this.wishlistCollection = this.afs.collection('Customers').doc(this.userId).collection('Wishlist', ref => ref.where('retailername', '==', this.retailerdata.retailername))
    this.wishlistCollection.valueChanges()
      .pipe(
        map(res => {
          runCount = runCount + 1;
          if (runCount < 2) {
            this.wishlistdata = res;
            console.log('Wishlist length of items is: ', this.wishlistdata.length);
            // for (var i = 0; i <= this.wishlistdata.length; i++) {
            // console.log('Wishlist data name is: ', this.wishlistdata[i].name);
            this.wishlistLength = this.wishlistdata.length;
            console.log('1st WishlistLength is: ', this.wishlistLength);
            console.log('Wishlist data All is: ', this.wishlistdata);
            for (var i = 0; i <= this.wishlistLength - 1; i++) {
              console.log('Wishlist productreference is: ', this.wishlistdata[i].productreference);

              this.wishlistDoc = this.afs.collection('Retailers').doc(this.docid).collection('Products').doc(this.wishlistdata[i].productreference);

              // this.wishlistDoc = this.wishlistdata[i].docref;
              // console.log('This docref is: ', this.wishlistDocRef);
              // this.wishlist = this.wishlistDoc.valueChanges();
              this.wishlistDoc.ref.get().then((doc) => {
                this.wishlistProduct = doc.data();
                // this.wishlistProduct.productdescription = doc.get('productdescription');
                this.wishlistArray.push(this.wishlistProduct);
                console.log('Product description is: ', this.wishlistProduct.productdescription);
                // this.productheader.push(this.wishlistProduct.productheader);
                console.log('Wishlist Product Header is : ', this.wishlistProduct.productheader);
                console.log('Wishlist Product Image is : ', this.wishlistProduct.mainimage);
              });
            }
          }
        }
        )
      )

      .subscribe();
  }

  getLoyalty() {
    console.log('Loyalty data being gathered');
    this.loyaltyDoc = this.afs.collection('Retailers').doc(this.docid).collection('Loyalty').doc(this.userId);
    this.loyalty = this.loyaltyDoc.valueChanges();
    this.loyaltyDoc.ref.get().then((doc) => {
      this.loyaltyData = doc.data();
      console.log('Loyalty data: ', this.loyaltyData);
    });

  }

  getCollect() {
    console.log('Collect data being gathered');
    this.collectArray = [];
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('Collect', ref => ref.where('retailerName', '==', this.retailerdata.retailername))
    this.collectCollection.valueChanges()
      .pipe(
        map(res => {
          this.collectData = res;
          console.log('Collect length of items is: ', this.collectData.length);
          for (var i = 0; i <= this.collectData.length - 1; i++) {
            console.log('Collect collectDocRefId is: ', this.collectData[i].collectDocRefId);
            this.collectDoc = this.afs.collection('Retailers').doc(this.docid).collection('ClickNCollect').doc(this.collectData[i].collectDocRefId);
            this.collectDoc.ref.get().then((doc) => {
              this.collectProductData = doc.data();
              this.collectArray.push(this.collectProductData);
              console.log('Collect Status is: ', this.collectProductData.status);
              console.log('Collect Message is: ', this.collectProductData.message);
              console.log('Collect Product Name is : ', this.collectProductData.productName);
            });
          }
        })
      )
      .subscribe();
  }

  getCustomerData() {
    if (!this.tabClickedOnce) {
      this.toastr.info('Get Customer Data', this.retailerdata.retailername, {
        timeOut: 2000,
      });
      this.tabClickedOnce = true
      this.getCollect();
      this.getWishlist();
      this.getLoyalty();
    }
  }

  deleteWishlistOneItem(productreference: string, retailer: string) {
    this.afs.collection('Customers').doc(this.userId).collection('Wishlist').doc(productreference).delete()
      .then(function () {
        // this.getWishlistAll();
        // this.runGetAllOnce = false;
        console.log("Wishlist Document successfully deleted");
        // if (retailer != 'All') {
        //   this.getWishlistOne(retailer);
        // } else {
        //   this.getWishlistAll('All');
        // }
      })
      .catch(function (error) {
        console.error("Error deleting document: ", error);
      });
    this.getWishlist();
  }

}