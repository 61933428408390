<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    .jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    #toprow {
        background: #F2F3F4;
        /* height: 6vh; */
        margin-bottom: 10px;
        /* color: rgb(74, 33, 150); */
        line-height: 6vh;
        text-align: center;
    }

    /* h5 {
      font-weight:bold;
  } */
    img.resize {
        /* max-height: 100px;
      max-width: 100px; */
        height: 150px;
        width: 150px;
    }

    .bg-image {
        background: url(img/image.jpg) no-repeat center top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        max-height: 50%;
    }

    .btn-space-login {
        top: 10px;
        right: 10px;
        margin-right: 100px;
        margin-top: 30px;
        /* margin-bottom: 20px; */
    }

    .btn-space-logout {
        top: 10px;
        right: 10px;
        margin-top: -20px;
        margin-right: 100px;
        margin-bottom: 20px;

    }

    /* #authButton {
      top: 10px;
      right: 10px;
      padding: 2px;
      /* margin-right: 100px;
      margin-top: 30px;
      margin-bottom: 20px; */
</style>

<!-- <div *ngIf="auth.user$ | async as user; else login"> -->

<!-- <button class="button" (click)="auth.googleSignin()">Login with Google</button> -->

<!-- <ng-template .login>
    <button class="button" (click)="auth.googleSignin()">Login with Google</button>
  </ng-template> -->


<header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
        <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
        <!-- Collapsible content -->

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/home">Home</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/style">Style</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item active">
                    <a class="nav-link" pageScroll routerLink="/collect">
                        Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                            class="badge badge-danger ml-1">{{collectItemsCount}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
                        <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
                        Wishlist
                    </a>
                </li>
                <li class="nav-item dropdown btn-group dropleft" dropdown>
                    <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
                        (click)="checkAuth()">
                        Profile<span class="caret"></span></a>
                    <div *ngIf="auth.user$ | async as user">
                        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
                            <div class="divider dropdown-divider"></div>
                            <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>

<!-- <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-dark mb-2'" [justified]="true" [contentClass]="'card'">
    <mdb-tabset> -->



<mdb-card class="ml-3 mr-3">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-8">
                    <!-- <img src="{{ (retailer | async)?.retailerlogo }}" class="rounded float-left resize mb-2 mr-3" -->
                    <!-- alt="..."> -->
                    <!-- <h4 class="ml-3 mr-3 mt-5" style="font-size:3vw;">Here are the items you have requested for -->
                    <h5 class="ml-3 mr-3 mt-5">Here are the items you have requested for
                        <strong>Click &
                            Collect</strong>. <br><br>We will let you know the
                        moment
                        your items
                        are
                        ready for collection.
                    </h5>
                </div>
                <div class="col-4">
                    <a routerLink="/qrcode/{{docid}}">
                        <img class="resize mb-2 mr-0 rounded img-fluid img-resize"
                            src="../assets/img/shutterstock_767922013.png" />Show QR Code
                    </a>
                </div>
                <!-- <div class="col-6">
                    <a routerLink="/qrcode/{{docid}}">
                        <img class="resize mb-2 mr-0" src="../assets/img/shutterstock_767922013.eps" />Show QR Code
                    </a>
                </div> -->
            </div>

            <div class="dropdown" mdbDropdown>
                <a mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light btn btn-primary btn-lg"
                    type="button" mdbWavesEffect><i class="fas fa-tag mr-1"></i>
                    Retailer
                </a>

                <div class="dropdown-menu dropdown-primary">
                    <a class="dropdown-item" (click)="getCollectAll()">All</a>
                    <!-- <a class="dropdown-item" (click)="getCollect('The Bead Shop')">The Bead Shop</a>
                    <a class="dropdown-item" (click)="getCollect('J.COLES')">J Coles</a> -->
                    <a *ngFor="let retailer of allCollectArray" class="dropdown-item"
                        (click)="getCollectOne(retailer)">{{retailer}}</a>
                </div>
            </div>
            <hr>

            <div class="list-group-flush">
                <div class="list-group-item">
                    <h3 class="mb-0 mt-3"><i class="fas fa-check fa-1x mr-4  green p-3 white-text rounded "
                            aria-hidden="true"></i><strong>Ready to collect</strong></h3>
                </div>
                <!-- Table  -->
                <table class="table table-bordered">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th>Retailer</th>
                            <th>Product</th>
                            <th>Qty</th>
                            <!-- <th>Date Ordered</th> -->
                            <th>Collect by</th>
                        </tr>
                    </thead>
                    <!-- Table head -->

                    <!-- Table body -->
                    <tbody *ngFor="let item of collectArray">
                        <tr *ngIf="item.status == 'Ready to collect'">
                            <!-- <div *ngIf="item.status == 'READY TO COLLECT'"> -->
                            <td>{{item.retailerName}}</td>
                            <th scope="row">
                                <!-- Material unchecked -->
                                <div class="form-check">
                                    <!-- <input type="checkbox" class="form-check-input" id="tableMaterialCheck2"> -->
                                    <label class="form-check-label" for="tableMaterialCheck2">
                                        {{item.productName}}
                                        {{item.variant}}
                                    </label>
                                </div>
                            </th>
                            <td>x 1</td>
                            <!-- <td>26-07-2020</td> -->
                            <td>{{item.collectBy}}</td>
                            <!-- </div> -->
                        </tr>
                    </tbody>
                    <!-- Table body -->
                </table>
                <!-- Table  -->
                <div class="list-group-item">
                    <h3 class="mb-0 mt-3"> <i class="fas fa-cog fa-1x mr-4 mr-4 grey p-3 white-text rounded" aria-hidden="true"></i><strong>In Progress</strong></h3>
                </div>
                <!-- Table  -->
                <table class="table table-bordered">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th>Retailer</th>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Date Ordered</th>
                        </tr>
                    </thead>
                    <!-- Table head -->

                    <!-- Table body -->
                    <tbody *ngFor="let item of collectArray">
                        <tr *ngIf="item.status == 'In progress'">
                            <td>{{item.retailerName}}</td>
                            <th scope="row">
                                <!-- Material unchecked -->
                                <div class="form-check">
                                    <!-- <input type="checkbox" class="form-check-input" id="tableMaterialCheck3"> -->
                                    <label class="form-check-label" for="tableMaterialCheck3">
                                        {{item.productName}}
                                        {{item.variant}}
                                    </label>
                                </div>
                            </th>
                            <td>x 1</td>
                            <td>{{item.timestamp.seconds*1000 | date:
                                'dd/MM/yyyy h:mm:ss a'}}</td>
                            <!-- <td>tbc</td> -->
                        </tr>
                    </tbody>
                    <!-- Table body -->
                </table>
                <!-- Table  -->

                <!-- Table  -->
                <div class="list-group-item">
                    <h3 class="mb-0 mt-3"> <i class="fas fa-plus-square fa-1x mr-4 mr-4 blue p-3 white-text rounded"
                            aria-hidden="true"></i><strong>New</strong></h3>
                </div>
                <!-- Table  -->
                <table class="table table-bordered">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th>Retailer</th>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Date Ordered</th>
                        </tr>
                    </thead>
                    <!-- Table head -->

                    <!-- Table body -->
                    <tbody *ngFor="let item of collectArray">
                        <tr *ngIf="item.status == 'New'">
                            <td>{{item.retailerName}}</td>
                            <th scope="row">
                                <!-- Material unchecked -->
                                <div class="form-check">
                                    <!-- <input type="checkbox" class="form-check-input" id="tableMaterialCheck3"> -->
                                    <label class="form-check-label" for="tableMaterialCheck3">
                                        {{item.productName}}
                                        {{item.variant}}
                                    </label>
                                </div>
                            </th>
                            <td>x 1</td>
                            <td>{{item.timestamp.seconds*1000 | date:
                                'dd/MM/yyyy h:mm:ss a'}}</td>
                            <!-- <td>tbc</td> -->
                        </tr>
                    </tbody>
                    <!-- Table body -->
                </table>
                <!-- Table  -->
                <!-- <div class="list-group-item">
                    <p class="mb-0"><i class="fab fa-snapchat fa-2x mr-4 mr-4 purple p-3 white-text rounded" aria-hidden="true"></i>Vacation</p>
                  </div> -->
            </div>
        </div>
    </div>

</mdb-card>
<!-- </mdb-tabset>
</mdb-tabset> -->

 <!--Footer-->
 <footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
    <hr class="my-4">
    
    <!--Copyright-->
    <div class=" footer-copyright py-3">
      © 2021 Copyright:
      <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
    </div>
    <!--/.Copyright-->

  </footer>
  <!--/.Footer-->