import { Component, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { LocationStrategy } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { Collect } from '../collect.model';
import { ToastrService } from 'ngx-toastr';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

const searchClient = algoliasearch(
  'NEE65IZ6KW',
  '80a2a740dddc661c372c243d9ebf2a1e'
);

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  config = {
    // indexName: 'demo_ecommerce',
    indexName: 'contact',
    searchClient,
  };

  public searchParameters = {
    hitsPerPage: 4,
    query: '',
    filters: ''
  };

  retailerlogo: string;
  faTag = faTag;
  faPortrait = faPortrait;
  faHeart = faHeart;
  faCog = faCog;
  docid: string;
  product: string
  values: string;
  filter: string;
  displayname: string;
  parentdisplayname: string;
  hasLoggedIn: boolean = false;

  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  userId: string;

  constructor(public auth: AuthService,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth, private router: Router, private afs: AngularFirestore, private route: ActivatedRoute, location: LocationStrategy) {
    location.onPopState(() => {
      // alert(window.location);
      this.backPressed();
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.docid = params['docid']
      this.values = params['values']
      this.filter = params['filter']
      this.displayname = params['displayname']
      this.parentdisplayname = params['parentdisplayname']
      this.config.indexName = params['algoliaindex']
      this.retailerlogo = params['retailerlogo']
    })
    this.searchParameters.query = this.values
    if (this.filter == null || this.filter == undefined || this.filter == "" || this.filter.length == 0) {
      this.searchParameters.filters = ""
    } else {
      this.searchParameters.filters = "category: " + [this.filter]
    }
    console.log("The filter string is: ", this.searchParameters.filters)
    console.log("The docid from the paramas to shopdetail is: ", this.docid)
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.countCollects()
      console.log("ngOnInit - The userid is ", this.userId)
    })
  };

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  logoClicked() { // without type info
    // this.router.navigate(['/shopmain', this.docid, this.retailerlogo]);
    this.router.navigate(['/shopmain', this.docid]);
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }

  productClicked(product: string) {
    this.router.navigate(['/product', this.docid, product]);
    console.log("Product item selected: ", product)
  }

  backPressed() {
    this.router.navigate(['/subcategory', this.docid, this.values, this.filter, this.displayname, this.parentdisplayname, this.config.indexName, this.retailerlogo]);
    console.log("Back button pressed: ")
  }
}
