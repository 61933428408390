import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ShopsComponent } from './shops/shops.component';
import { ShopmainComponent } from './shopmain/shopmain.component';
import { StyleComponent } from './style/style.component';
import { SearchComponent } from './search/search.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { MessagesComponent } from './messages/messages.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { MystyleComponent } from './mystyle/mystyle.component';
// import { LoginComponent } from './login/login.component';
import { CollectComponent } from './collect/collect.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { AuthGuard } from './services/auth.guard';
import { LandingpageComponent } from './landingpage/landingpage.component';

const routes: Routes = [
  { path: '', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'landing', component: LandingpageComponent },
  { path: 'home', component: HomeComponent,  canActivate: [AuthGuard] },
  // { path: 'login', component: LoginComponent },
  { path: 'wishlist', component: WishlistComponent, canActivate: [AuthGuard] },
  { path: 'shops', component: ShopsComponent, canActivate: [AuthGuard] },
  { path: 'shopmain/:docid', component: ShopmainComponent, canActivate: [AuthGuard] },
  { path: 'style', component: StyleComponent, canActivate: [AuthGuard] },
  { path: 'messages', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: 'messages/:shopId/:retailerName/:topic', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: 'messages/:shopId/:retailerName/:topic/:productName', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: 'favourites', component: FavouritesComponent, canActivate: [AuthGuard] },
  { path: 'product/:docid/:productid', component: ProductdetailComponent, canActivate: [AuthGuard] },
  // { path: 'qrcode/:docid/:retailername/:retailerlogo', component: QrcodeComponent},
  { path: 'qrcode/:docid', component: QrcodeComponent, canActivate: [AuthGuard] },
  { path: 'mystyle/:styleid', component: MystyleComponent, canActivate: [AuthGuard]},
  { path: 'mystyle/:styleid/:sharedby/:shared', component: MystyleComponent, canActivate: [AuthGuard]},
  { path: 'collect', component: CollectComponent, canActivate: [AuthGuard] },
  { path: 'collect/:docid', component: CollectComponent, canActivate: [AuthGuard] },
  { path: 'search/:docid/:values/:filter/:displayname/:parentdisplayname/:algoliaindex/:retailerlogo', component: SearchComponent },
  { path: 'subcategory/:docid/:values/:filter/:displayname/:parentdisplayname/:algoliaindex/:retailerlogo', component: SubcategoryComponent, canActivate: [AuthGuard] },
  // { path: 'moviedetail/:docid', component: MovieDetailComponentComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
