<style>
    img {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 5px;
        width: 250px;
    }

    img:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
</style>
<agm-map [zoom]="16" [latitude]="center.lat" [longitude]="center.lng" style="height: 400px">

    <agm-marker [latitude]="item._geoloc.lat" [longitude]="item._geoloc.lng" [label]="item.name"
        *ngFor="let item of state.items || []">

        <agm-info-window>


            <div class="card card-cascade">
                <!-- Card image -->
                <div class="view view-cascade overlay">
                    <img class="card-img-top" src="{{item.thumbnail_url}}" alt="Card image cap">
                    <a>
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>
                <!-- Card content -->
                <div class="card-body card-body-cascade text-center">
                    <h4 class="card-title">{{item.name}}</h4>
                    <h6 class="font-weight-bold indigo-text py-2">{{item.shop_type}}</h6>
                    <p class="card-text">{{item.smart_location}}
                    </p>
                    <button (click)="myClickFunction(item.objectID)" class="btn btn-primary stretched-link">See
                        Shop</button>
                </div>
            </div>
        </agm-info-window>
    </agm-marker>
</agm-map>