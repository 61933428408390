<style>
  #retailerlogo {
    /* border: 1px solid #ddd;
      border-radius: 4px; */
    padding: 5px;
    width: 150px;
  }

  #retailerlogo:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }

  .jumbotron1 {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .map-container-5 {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .map-container-5 iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  #up_button {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  #categoryimage {
    /* border: 1px solid #ddd;
      border-radius: 4px; */
    padding: 5px;
    width: 50px;
  }

  #toprow {
    background: #F2F3F4;
    /* height: 6vh; */
    margin-bottom: 10px;
    /* color: rgb(74, 33, 150); */
    line-height: 6vh;
    text-align: center;
  }

  /* h5 {
    font-weight:bold;
} */
  img.resize {
    /* max-height: 100px;
    max-width: 100px; */
    height: auto;
    width: auto;
  }

  .bg-image {
    background: url(img/image.jpg) no-repeat center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    max-height: 50%;
  }

  .btn-space-login {
    top: 10px;
    right: 10px;
    margin-right: 10%;
    margin-top: 30px;
    /* margin-bottom: 20px; */
  }

  .btn-space-logout {
    top: 10px;
    right: 10px;
    margin-right: 10%;
    margin-top: 30px;
    /* margin-bottom: 20px; */
  }

  /* #authButton {
    top: 10px;
    right: 10px;
    padding: 2px;
    /* margin-right: 100px;
    margin-top: 30px;
    margin-bottom: 20px; */
</style>

<!-- <div *ngIf="auth.user$ | async as user; else login"> -->

<!-- <button class="button" (click)="auth.googleSignin()">Login with Google</button> -->

<!-- <ng-template .login>
  <button class="button" (click)="auth.googleSignin()">Login with Google</button>
</ng-template> -->


<header id="top-section">
  <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
    <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
    <!-- Collapsible content -->

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
      (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" pageScroll routerLink="/home">Home</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/favourites">Favourites</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/shops">Shops</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/style">Style</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/messages">Messages<span
              class="badge  ml-2"><i class="far fa-envelope"></i></span><span
              class="badge badge-danger ml-1">1</span></a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/collect">
            Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
              class="badge badge-danger ml-1">{{collectItemsCount}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
            <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
            Wishlist
          </a>
        </li>
        <li class="nav-item dropdown btn-group dropleft" dropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
            (click)="checkAuth()">
            Profile<span class="caret"></span></a>
          <div *ngIf="auth.user$ | async as user">
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
              <div class="divider dropdown-divider"></div>
              <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>



<!--Main Navigation-->
<!-- <header>
  <div *ngIf="auth.user$ | async as user; else login">
    <button mdbBtn id="authButton" class="btn btn-warning btn-rounded float-right btn-space-logout" type="button"
      (click)="auth.signOut()" gradient="peach" size="lg" mdbWavesEffect>Logout <i class="fas fa-unlock"></i></button>
    <br>
  </div>

<div *ngIf="auth.user$ | async as user; else login"></div>

<ng-template #login>
    <button mdbBtn id="authButton" class="btn btn-warning btn-rounded float-right btn-space-login" type="button"
      (click)="auth.googleSignin()" gradient="peach" size="lg" mdbWavesEffect>Login <i class="fas fa-lock"></i></button>
    <br>
  </ng-template>
</header> -->


<!--Main layout-->
<!-- <main class="mt-0 pt-5"> -->
  <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-dark mb-2'" [justified]="true" [contentClass]="'card'">
    <!--Panel 1-->
    <mdb-tab heading="News">
      <!-- <div class="container"> -->

        <!-- Jumbotron -->
        <!-- <div class="jumbotron jumbotron-fluid py-0"
            style="background-repeat: no-repeat; background-image: url(https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/shutterstock_344963789.jpg?alt=media&token=d07dd648-bfd5-45b5-acff-5f062dfe3580);">
            <div class="text-white text-center rgba-stylish-strong px-4">
              <div class="py-5"> -->

        <!-- Content -->
        <!-- <h1 class="h1 orange-text">STREETSHOPS</h1>
                <h2 class="h2 my-4 py-2">The new way to shop the High Street</h2>
                <h3 class="mb-0 pb-2 px-md-5 mx-md-5"><strong>Discover and connect</strong> with all your favourite
                  shops
                </h3>
                <a routerLink="/shops" (click)="checkAuth()" class="btn peach-gradient"><i
                    class="fas fa-clone left"></i>
                  View Shops</a> -->

        <!-- </div>
            </div>
          </div> -->
        <!-- Jumbotron -->

        <!-- <hr class="my-5"> -->

        <!--Section: Magazine v.1-->
        <section class="wow fadeIn">

          <!--Section heading-->
          <h2 class="h1 text-center my-2 font-weight-bold">What's New</h2>

          <!--Grid row-->
          <div class="row text-left">

            <!--Grid column-->
            <div class="col-lg-6 col-md-12">

              <!--Image-->
              <div class="view overlay rounded z-depth-1-half mb-3">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2FDSC_0124.JPG?alt=media&token=f32dcb99-beb3-4ae1-9ce7-372a99299718"
                  class="img-fluid" alt="Sample post image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

              <!--Excerpt-->
              <div class="news-data">
                <a href="" class="light-blue-text">
                  <h6>
                    <i class="fas fa-lightbulb"></i>
                    <strong> Shopping ideas</strong>
                  </h6>
                </a>
                <p>
                  <strong>
                    <i class="fa fa-clock-o"></i> 20/08/2021</strong>
                </p>
              </div>
              <h3>
                <a>
                  <strong>Boutique shopping experiences</strong>
                </a>
              </h3>
              <p> Discover new shopping experiences in Nottingham. Flying Horse walk is an exclusive shopping emporium
                full of select independent boutiques...
                <i class="fas fa-external-link-alt"></i>
              </p>

              <!--/Featured news-->

              <hr>

              <!--Small news-->
              <div class="row">
                <div class="col-md-3">

                  <!--Image-->
                  <div class="view overlay rounded z-depth-1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/DSC_0115.jpg?alt=media&token=f9fcc256-9d2d-4feb-bbce-42b4b2d3fe25"
                      class="img-fluid" alt="Minor sample post image">
                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>


                </div>

                <!--Excerpt-->
                <div class="col-md-9">
                  <p class="dark-grey-text">
                    <strong>18/08/2021</strong>
                  </p>
                  <a>Finding the perfect gift
                    <i class="fa fa-angle-right float-right"></i>
                  </a>
                </div>

              </div>
              <!--/Small news-->

              <hr>

              <!--Small news-->
              <div class="row">
                <div class="col-md-3">

                  <!--Image-->
                  <div class="view overlay rounded z-depth-1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/portsmouth-electrics.jpg?alt=media&token=2bdea2f5-9bc7-4255-a350-9bc95748a19d"
                      class="img-fluid" alt="Minor sample post image">
                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                </div>

                <!--Excerpt-->
                <div class="col-md-9">
                  <p class="dark-grey-text">
                    <strong>18/08/2021</strong>
                  </p>
                  <a>Nottingham’s biggest guitar shop
                    <i class="fa fa-angle-right float-right"></i>
                  </a>
                </div>

              </div>
              <!--/Small news-->

              <hr>

              <!--Small news-->
              <div class="row">
                <div class="col-md-3">

                  <!--Image-->
                  <div class="view overlay rounded z-depth-1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/DSC_0150.jpg?alt=media&token=a690dcf1-0ea7-4ff7-b5fa-e2a38e60610f"
                      class="img-fluid" alt="Minor sample post image">
                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                </div>



                <!--Excerpt-->
                <div class="col-md-9 mb-2">
                  <p class="dark-grey-text">
                    <strong>17/08/2021</strong>
                  </p>
                  <a>Hey check this out
                    <i class="fa fa-angle-right float-right"></i>
                  </a>
                </div>

                <hr>

              </div>
              <!--/Small news-->

            </div>
            <!--Grid column-->



            <!--Grid column-->
            <div class="col-lg-6 col-md-12">



              <!--Image-->
              <div class="view overlay rounded z-depth-1-half mb-3">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2FHana-The-Bead-Shop-Nottingham.jpg?alt=media&token=1bac81ef-579b-4e1a-90f4-53ee935901a8"
                  class="img-fluid" alt="Sample post image">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>

              <!--Excerpt-->
              <div class="news-data">
                <a href="" class="light-blue-text">
                  <h6>
                    <i class="far fa-newspaper"></i>
                    <strong> Retail Community News</strong>
                  </h6>
                </a>
                <p>
                  <strong>
                    <i class="fa fa-clock-o"></i> 20/08/2021</strong>
                </p>
              </div>
              <h3>
                <a>
                  <strong>As if by magic the shopkeeper appeared...</strong>
                </a>
              </h3>
              <p>Welcome back to The Bead Shop! "In some ways it’s been a very long 4 months since we shut our doors
                due
                to the Covid-19 pandemic. In other ways it feels like the time has flown by..."
                <i class="fas fa-external-link-alt"></i>
              </p>

              <!--/Featured news-->

              <hr>

              <!--Small news-->
              <div class="row">
                <div class="col-md-3">

                  <!--Image-->
                  <div class="view overlay rounded z-depth-1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/DSC_0120-2.jpg?alt=media&token=0a0d1b6f-5a85-41b2-a623-14d3937c45b2"
                      class="img-fluid" alt="Minor sample post image">
                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                </div>

                <!--Excerpt-->
                <div class="col-md-9">
                  <p class="dark-grey-text">
                    <strong>19/08/2021</strong>
                  </p>
                  <a>Exchange Arcade, a tour
                    <i class="fa fa-angle-right float-right"></i>
                  </a>
                </div>

              </div>
              <!--/Small news-->

              <hr>




              <!--Small news-->
              <div class="row">
                <div class="col-md-3">

                  <!--Image-->
                  <div class="view overlay rounded z-depth-1">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/pop-up-shop.jpg?alt=media&token=4f65553d-f9ba-415e-b5d2-2aecd26ae81f"
                      class="img-fluid" alt="Minor sample post image">
                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                </div>

                <!--Excerpt-->
                <div class="col-md-9">
                  <p class="dark-grey-text">
                    <strong>19/08/2021</strong>
                  </p>
                  <a>Discovering Pop-up Stores
                    <i class="fa fa-angle-right float-right"></i>
                  </a>
                </div>

              </div>
              <!--/Small news-->

              <hr>



              <!--Small news-->
              <div class="row">
                <div class="col-md-3">

                  <!--Image-->
                  <div class="view overlay rounded z-depth-1 mb-4">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/notts.jpg?alt=media&token=28166491-7cd8-4b28-9911-b18bba52e577"
                      class="img-fluid" alt="Minor sample post image">
                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                </div>

                <!--Excerpt-->
                <div class="col-md-9 mb-4">
                  <p class="dark-grey-text">
                    <strong>17/08/2021</strong>
                  </p>
                  <a>Hello High Street, We Missed You!
                    <i class="fa fa-angle-right float-right"></i>
                  </a>
                </div>

              </div>
              <!--/Small news-->

            </div>
            <!--Grid column-->

          </div>
          <!--Grid row-->

        </section>

        <hr>
        <!--Fav Shops What's New -->
        <section class="wow fadeIn">

          <!--Section heading-->
          <h3 class="h3 text-center my-5 font-weight-bold">What's new from your favourite shops</h3>

          <!-- Grid row -->
          <div class="row">

            <!-- Grid column -->
            <div class="col-md-12">

              <!-- Card group-->
              <div class="card-group">

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">

                  <!-- Card image-->
                  <div class="view overlay">
                    <img class="card-img-top" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(26).jpg"
                      alt="Card image cap">
                    <a href="#!">
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!-- Card image-->

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 1</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">Look good, feel good.</a>
                    <!-- Text -->
                    <mdb-card-text>
                      <a>Love Summer for Longer
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>



                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">



                  <!-- Card video-->
                  <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ZS9BGGhbSCM"
                      allowfullscreen></iframe>
                  </div>

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 2</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">
                      <!-- Text -->
                    </a>
                    <!-- Text -->


                    <mdb-card-text>
                      <a>In store event
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>

                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">

                  <!-- Card image-->
                  <div class="view overlay">
                    <img class="card-img-top" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(28).jpg"
                      alt="Card image cap">
                    <a href="#!">
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!-- Card image-->

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 3</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">New collection</a>
                    <!-- Text -->

                    <mdb-card-text>
                      <a>New in store
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>

                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

              </div>
              <!-- Card group-->

            </div>
            <!-- Grid column -->

          </div>
          <!-- Grid row -->

          <!-- Grid row -->
          <div class="row">

            <!-- Grid column -->
            <div class="col-md-12">

              <!-- Card group-->
              <div class="card-group">

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">

                  <!-- Card image-->
                  <div class="view overlay">
                    <img class="card-img-top" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(26).jpg"
                      alt="Card image cap">
                    <a href="#!">
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!-- Card image-->

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 1</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">Look good, feel good.</a>
                    <!-- Text -->
                    <mdb-card-text>
                      <a>Love Summer for Longer
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>



                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">



                  <!-- Card video-->
                  <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ZS9BGGhbSCM"
                      allowfullscreen></iframe>
                  </div>

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 2</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">
                      <!-- Text -->
                    </a>
                    <!-- Text -->


                    <mdb-card-text>
                      <a>In store event
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>

                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">

                  <!-- Card image-->
                  <div class="view overlay">
                    <img class="card-img-top" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(28).jpg"
                      alt="Card image cap">
                    <a href="#!">
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!-- Card image-->

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 3</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">New collection</a>
                    <!-- Text -->

                    <mdb-card-text>
                      <a>New in store
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>

                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

              </div>
              <!-- Card group-->

            </div>
            <!-- Grid column -->

          </div>
          <!-- Grid row -->

          <!-- Grid row -->
          <div class="row">

            <!-- Grid column -->
            <div class="col-md-12">

              <!-- Card group-->
              <div class="card-group">

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">

                  <!-- Card image-->
                  <div class="view overlay">
                    <img class="card-img-top" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(26).jpg"
                      alt="Card image cap">
                    <a href="#!">
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!-- Card image-->

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 1</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">Look good, feel good.</a>
                    <!-- Text -->
                    <mdb-card-text>
                      <a>Love Summer for Longer
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>



                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">



                  <!-- Card video-->
                  <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ZS9BGGhbSCM"
                      allowfullscreen></iframe>
                  </div>

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 2</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">
                      <!-- Text -->
                    </a>
                    <!-- Text -->


                    <mdb-card-text>
                      <a>In store event
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>

                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

                <!-- Card -->
                <mdb-card class="card-personal mb-md-0 mb-4 ml-2 mr-2 mt-2">

                  <!-- Card image-->
                  <div class="view overlay">
                    <img class="card-img-top" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(28).jpg"
                      alt="Card image cap">
                    <a href="#!">
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!-- Card image-->

                  <!-- Card content -->
                  <mdb-card-body>

                    <!-- Title-->
                    <mdb-card-title>
                      <a>
                        <h4 class="card-title">Shop 3</h4>
                      </a>
                    </mdb-card-title>

                    <a class="card-meta">New collection</a>
                    <!-- Text -->

                    <mdb-card-text>
                      <a>New in store
                        <i class="fa fa-angle-right float-right"></i>
                      </a>
                    </mdb-card-text>

                  </mdb-card-body>
                  <!-- Card content -->

                </mdb-card>
                <!-- Card -->

              </div>
              <!-- Card group-->

            </div>
            <!-- Grid column -->

          </div>
          <!-- Grid row -->

        </section>
        <!-- Section: Group of shop notices cards -->


      <!-- </div> -->
    </mdb-tab>
    <mdb-tab heading="Favourites">
      <app-favourites></app-favourites>
    </mdb-tab>
    <mdb-tab heading="Shops">
      <app-shops></app-shops>
    </mdb-tab>

  </mdb-tabset>
<!-- </main> -->
<!--Main layout-->

<!-- <button mdbBtn class="btn btn-warning btn-rounded float-right btn-space" type="button"
    (click)="auth.signOut()" gradient="peach" size="lg" mdbWavesEffect>Google Logout <i class="fas fa-lock"></i></button>
    <br> -->

<!--Footer-->
<footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
  <hr class="my-4">

  <!--Copyright-->
  <div class=" footer-copyright py-3">
    © 2021 Copyright:
    <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
  </div>
  <!--/.Copyright-->

</footer>
<!--/.Footer-->