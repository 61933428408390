<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    .list-group-hover .list-group-item:hover {
        background-color: #f5f5f5;
    }

    #jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    #toprow {
        background: #F2F3F4;
        height: 5vh;
        margin-bottom: 10px;
        color: rgb(74, 33, 150);
        font-size: 1em;
        line-height: 5vh;
        text-align: center;
    }

    /* #avatar_img {
        object-fit: cover;
        border-radius: 50%;
        width: 150px;
        height: 150px;
    } */

    .avatar {
        vertical-align: middle;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, .2);
        -moz-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, .2);
        box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999, 0 2px 5px 4px rgba(0, 0, 0, .2);
    }
</style>
<header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
        <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
        <!-- Collapsible content -->

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/home">Home</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/style">Style</a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/collect">
                        Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                            class="badge badge-danger ml-1">{{collectItemsCount}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
                        <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
                        Wishlist
                    </a>
                </li>
                <li class="nav-item dropdown btn-group dropleft" dropdown>
                    <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
                        (click)="checkAuth()">
                        Profile<span class="caret"></span></a>
                    <div *ngIf="auth.user$ | async as user">
                        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
                            <div class="divider dropdown-divider"></div>
                            <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>

<div class="card grey lighten-4 chat-room">
    <div class="card-body">

        <!-- Grid row -->
        <div class="row px-2">

            <!-- Grid column -->
            <div class="col-md-6 col-xl-8 pr-md-4 px-lg-auto px-0">

                <div class="chat-message">

                    <ul class="list-unstyled chat">
                        <li class="d-flex justify-content-between mb-4"
                            *ngFor="let item of messages | async ; let i=index">
                            <div *ngIf="item.sender == 'shop'">
                                <img src="{{item.avatar}}" alt="avatar" class="avatar avatar-16">

                            </div>
                            <div class="chat-body white p-3 ml-2 z-depth-1">
                                <div class="header">
                                    <strong class="primary-font">{{item.senderName}}</strong>
                                    <small class="pull-right text-muted">
                                        <mdb-icon far icon="clock" class="ml-1"></mdb-icon>
                                        {{item.lastUpdate.seconds*1000 | date:
                                        'dd/MM/yyyy h:mm:ss a'}}
                                    </small>
                                </div>
                                <hr class="w-100">
                                <p class="mb-0 ml-3 mr-3">
                                    {{item.messageBody}}
                                </p>
                                <div *ngIf="item.addOrder">
                                    <br>
                                    <p>
                                        <mdb-icon far icon="fas fa-tag" class="ml-1 mr-1">
                                        </mdb-icon>
                                        <a (click)="getShopCollect('The Bead Shop')"> View order</a>
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="item.sender == 'customer'">
                                <img src="{{item.avatar}}" alt="avatar" class="avatar avatar-16">
                            </div>
                        </li>

                        <div [hidden]="!initialisedView">
                            <div class="white">
                                <div *ngIf="!productEnquiry" class="form-group basic-textarea">
                                    <textarea class="form-control pl-2 my-0" id="exampleFormControlTextarea2" rows="3"
                                        placeholder="New message to {{shopName}}" [(ngModel)]="newMessage"></textarea>
                                </div>
                                <div *ngIf="productEnquiry" class="form-group basic-textarea">
                                    <textarea class="form-control pl-2 my-0" id="exampleFormControlTextarea2" rows="3"
                                        placeholder="New message to {{shopName}} about: {{productName}}" [(ngModel)]="newMessage"></textarea>
                                </div>
                            </div>
                            <button mdbBtn color="success" class="waves-light" type="button"
                                class="waves-effect waves-dark float-right" (click)="addMessage()">
                                <mdb-icon far icon="paper-plane" class="mr-3"></mdb-icon>Send
                            </button>
                        </div>
                    </ul>

                </div>

            </div>
            <!-- Grid column -->


            <!-- Grid column -->
            <div *ngIf="!initialisedView" class="col-md-12 ">

                <h6 class="font-weight-bold mb-3 text-center text-lg-left">Retailers</h6>
                <div class="white z-depth-1 px-3 pt-3 pb-0">
                    <ul class="list-unstyled friend-list list-group-hover">

                        <li class="lighten-1 p-2" *ngFor="let item of messagesData; let i=index"
                            (click)="selectShop(i,item.shopId, item.read, item.shopName)" mdbWavesEffect>
                            <!-- <a class="d-flex d-flex justify-content-around"> -->
                                <a class="d-flex d-flex" >
                                <img src="{{item.avatar}}" alt="avatar"
                                    class="avatar rounded-circle d-flex align-self-center mr-3 z-depth-1">
                                <div class="text-small">
                                    <strong>{{item.senderName}}</strong><br>
                                    <div *ngIf="item.sender == 'customer'">
                                        <p class="last-message text-muted"><strong>You replied: </strong>{{
                                            (item.messageBody.length>80)?
                                            (item.messageBody | slice:0:80)+'...':(item.messageBody) }}</p>
                                    </div>
                                    <div *ngIf="item.sender == 'shop'">
                                        <p class="last-message text-muted">{{
                                            (item.messageBody.length>80)?
                                            (item.messageBody | slice:0:80)+'...':(item.messageBody) }}</p>
                                    </div>
                                </div>
                                <div class="chat-footer">
                                    <p class="text-smaller text-muted mb-0">{{item.lastUpdate.seconds*1000 | date:
                                        'dd/MM/yyyy h:mm:ss a'}}</p>
                                    <!-- <span class="badge badge-notification float-right">1</span> -->
                                    <div *ngIf="item.read">
                                        <i class="far fa-envelope-open fa-md ml-2"></i>
                                    </div>
                                    <div *ngIf="!item.read">
                                        <i class="fas fa-envelope fa-md ml-2"></i>
                                    </div>
                                    <!-- <span class="badge rounded-pill badge-notification bg-danger">1</span> -->
                                </div>
                            </a>
                        </li>

                    </ul>
                </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div *ngIf="initialisedView" class="col-md-6 col-xl-4 px-0">

                <h6 class="font-weight-bold mb-3 text-center text-lg-left">Retailers</h6>
                <div class="white z-depth-1 px-3 pt-3 pb-0">
                    <ul class="list-unstyled friend-list list-group-hover">

                        <li class="lighten-1 p-2" *ngFor="let item of messagesData; let i=index"
                            (click)="selectShop(i,item.shopId, item.read, item.shopName)" mdbWavesEffect>
                            <a class="d-flex d-flex justify-content-around">
                                <img src="{{item.avatar}}" alt="avatar"
                                    class="avatar rounded-circle d-flex align-self-center mr-3 z-depth-1">
                                <div class="text-small">
                                    <strong>{{item.senderName}}</strong><br>
                                    <p class="last-message text-muted">{{ (item.messageBody.length>35)?
                                        (item.messageBody | slice:0:25)+'...':(item.messageBody) }}</p>
                                </div>
                                <div class="chat-footer">
                                    <p class="text-smaller text-muted mb-0">{{item.lastUpdate.seconds*1000 | date:
                                        'dd/MM/yyyy h:mm:ss a'}}</p>
                                    <!-- <span class="badge badge-notification float-right">1</span> -->
                                    <div *ngIf="item.read">
                                        <i class="far fa-envelope-open fa-md"></i>
                                    </div>
                                    <div *ngIf="!item.read">
                                        <i class="fas fa-envelope fa-md"></i>
                                    </div>
                                    <!-- <span class="badge rounded-pill badge-notification bg-danger">1</span> -->
                                </div>
                            </a>
                        </li>

                    </ul>
                </div>

            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->

    </div>
</div>

 <!--Footer-->
 <footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
    <hr class="my-4">
    
    <!--Copyright-->
    <div class=" footer-copyright py-3">
      © 2021 Copyright:
      <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
    </div>
    <!--/.Copyright-->

  </footer>
  <!--/.Footer-->