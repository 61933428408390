<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    .jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    #img-thumbnail a img {
        display: block;
        margin: auto;
    }

    .img-thumbnail {
        display: flex;
        justify-content: center;
    }

    .image-container {
        width: 200px;
        /* display: flex; */
        justify-content: center;
        margin: 10px;
        padding: 10px;

        background: #fff;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        */
    }

    #toprow {
        background: #F2F3F4;
        /* height: 6vh; */
        margin-bottom: 10px;
        /* color: rgb(74, 33, 150); */
        line-height: 6vh;
        text-align: center;
    }

    /* h5 {
      font-weight:bold;
  } */
    img.resize {
        /* max-height: 100px;
      max-width: 100px; */
        height: auto;
        width: auto;
    }

    .bg-image {
        background: url(img/image.jpg) no-repeat center top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        max-height: 50%;
    }

    .btn-space-login {
        top: 10px;
        right: 10px;
        margin-right: 100px;
        margin-top: 30px;
        /* margin-bottom: 20px; */
    }

    .btn-space-logout {
        top: 10px;
        right: 10px;
        margin-top: -20px;
        margin-right: 100px;
        margin-bottom: 20px;

    }

    /* #authButton {
      top: 10px;
      right: 10px;
      padding: 2px;
      /* margin-right: 100px;
      margin-top: 30px;
      margin-bottom: 20px; */
</style>

<!-- <div *ngIf="auth.user$ | async as user; else login"> -->

<!-- <button class="button" (click)="auth.googleSignin()">Login with Google</button> -->

<!-- <ng-template .login>
    <button class="button" (click)="auth.googleSignin()">Login with Google</button>
  </ng-template> -->


<header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
        <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
        <!-- Collapsible content -->

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/home">Home</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/style">Style</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/collect">
                        Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                            class="badge badge-danger ml-1">{{collectItemsCount}}</span>
                    </a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
                        <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
                        Wishlist
                    </a>
                </li>
                <li class="nav-item dropdown btn-group dropleft" dropdown>
                    <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
                        (click)="checkAuth()">
                        Profile<span class="caret"></span></a>
                    <div *ngIf="auth.user$ | async as user">
                        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
                            <div class="divider dropdown-divider"></div>
                            <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>

<div class="dropdown" mdbDropdown>
    <a mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light btn btn-primary btn-lg" type="button"
        mdbWavesEffect><mdb-icon far icon="heart" class="mr-1"></mdb-icon>
        Retailer
    </a>

    <div class="dropdown-menu dropdown-primary">
        <a class="dropdown-item" (click)="getWishlistAll('All')">All</a>
        <a *ngFor="let retailer of allWishlistArray" class="dropdown-item"
            (click)="getWishlistOne(retailer)">{{retailer}}</a>
    </div>
</div>
<hr>

<div class="container">
    <div class="column">
        <div class="col-md-10" *ngIf=(!oneRetailerSelected)>
            <!--Card-->
            <div class="card card-ecommerce mt-2 mb-3" *ngFor="let item of wishlistArray">
                <!-- <div *ngFor="let item of wishlistArray"> -->
                <!-- <li class="list-group-item"> -->
                <mdb-card>
                    <h4 class="card-title ml-4 mt-2 mb-2"><strong>{{item.retailername}}</strong></h4>
                    <!--Card image-->
                    <div class="view overlay z-depth-1 image-container">
                        <img src="{{item.mainimage}}" alt="thumbnail"
                            (click)="productClicked(item.retailerreference, item.productreference)"
                            class="img-thumbnail" style="width: 200px">
                        <h5 class="color=blue">{{item.productname}}</h5>
                        <h6>{{item.productprice}}</h6>
                        <!-- <button type="button" class="btn btn-light justify btn-sm mb-2 float-right" (click)="deleteWishlistItem(item.productreference)">Remove</button> -->
                    </div>
                    <!--Card content-->
                    <div class="card-body text-center no-padding">
                        <!-- <h5 class="color=blue">{{item.productname}}</h5>
                        <h6>{{item.productprice}}</h6> -->
                        <!-- {{wishlistProduct.productheader}} -->
                        <!-- {{ (wishlistProduct | async)?.productheader}} -->
                        <!-- {{item.productheader}} -->
                        <!-- {{item.productdescription}} -->
                        <!-- <button type="button" class="btn btn-primary justify float-right">Click &
                                Collect</button> -->
                        <div *ngIf=(!oneRetailerSelected)>
                            <button type="button" class="btn btn-light justify btn-sm mb-2 float-right"
                                (click)="deleteWishlistItem(item.productreference, 'All')">Remove</button>
                        </div>
                    </div>
                </mdb-card>
                <!-- </li> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="column">
        <div class="col-md-10" *ngIf=(oneRetailerSelected)>
            <!--Card-->
            <div class="card card-ecommerce mt-2 mb-3" *ngFor="let item of wishlistOneArray">
                <!-- <div *ngFor="let item of wishlistArray"> -->
                <!-- <li class="list-group-item"> -->
                <mdb-card>
                    <h4 class="card-title ml-4 mt-2 mb-2"><strong>{{item.retailername}}</strong></h4>
                    <!--Card image-->
                    <div class="view overlay z-depth-1 image-container">
                        <img src="{{item.mainimage}}" alt="thumbnail"
                            (click)="productClicked(item.retailerreference, item.productreference)"
                            class="img-thumbnail" style="width: 200px">
                        <h5 class="color=blue">{{item.productname}}</h5>
                        <h6>{{item.productprice}}</h6>
                        <!-- <button type="button" class="btn btn-light justify btn-sm mb-2 float-right" (click)="deleteWishlistItem(item.productreference)">Remove</button> -->
                    </div>
                    <!--Card content-->
                    <div class="card-body text-center no-padding">
                        <!-- <h5 class="color=blue">{{item.productname}}</h5>
                        <h6>{{item.productprice}}</h6> -->
                        <!-- {{wishlistProduct.productheader}} -->
                        <!-- {{ (wishlistProduct | async)?.productheader}} -->
                        <!-- {{item.productheader}} -->
                        <!-- {{item.productdescription}} -->
                        <!-- <button type="button" class="btn btn-primary justify float-right">Click &
                                Collect</button> -->
                        <div *ngIf=(oneRetailerSelected)>
                            <button type="button" class="btn btn-light justify btn-sm mb-2 float-right"
                                (click)="deleteWishlistOneItem(item.productreference, item.retailername)">Remove</button>
                        </div>
                    </div>
                </mdb-card>
                <!-- </li> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>
<!-- </ul> -->
<!-- </div>
</div> -->

 <!--Footer-->
 <footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
    <hr class="my-4">
    
    <!--Copyright-->
    <div class=" footer-copyright py-3">
      © 2021 Copyright:
      <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
    </div>
    <!--/.Copyright-->

  </footer>
  <!--/.Footer-->