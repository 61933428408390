import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { Observable } from 'rxjs';
import { map, take } from "rxjs/operators";
import { Collect } from '../collect.model';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

@Component({
  selector: 'app-collect',
  templateUrl: './collect.component.html',
  styleUrls: ['./collect.component.scss']
})
export class CollectComponent implements OnInit {

  collectCollection: AngularFirestoreCollection<Collect>;
  allCollectCollection: AngularFirestoreCollection<Collect>;
  oneCollectCollection: AngularFirestoreCollection<Collect>;
  collectDoc: AngularFirestoreDocument<Collect>;
  collect: Observable<any>;
  collectArray: Array<AngularFirestoreDocument>;
  allCollectArray: Array<string>;
  collectData: any;
  collectProductData: any;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  userId: string;
  docid: string = "All";
  runOnceAlready: boolean = false;
  retailer:string;
  hasLoggedIn: boolean = false;


  constructor(private router: Router, public auth: AuthService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.retailer = params['docid']
    })
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.countCollects()
      console.log("ngOnInit - The userid is ", this.userId)
      this.allCollectArray = [];
      if (this.retailer == '' || this.retailer == undefined || this.retailer == null) {
      this.getCollectAll();
      }else {
        // this.getCollectAll();
        this.allCollectArray = [];
        this.getCollectOne(this.retailer);
      }
    })
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }


  getCollectAll() {
    console.log('Collect data being gathered');
    this.collectArray = [];
    this.allCollectCollection = this.afs.collection('Customers').doc(this.userId).collection('Collect')
    this.allCollectCollection.valueChanges()
      .pipe(
        map(res => {
          this.collectData = res;
          console.log('Collect length of items is: ', this.collectData.length);
          for (var i = 0; i <= this.collectData.length - 1; i++) {
            console.log('Collect collectDocRefId is: ', this.collectData[i].collectDocRefId);
            console.log('Retailer reference is: ', this.collectData[i].retailerreference);
            this.collectDoc = this.afs.collection('Retailers').doc(this.collectData[i].retailerreference).collection('ClickNCollect').doc(this.collectData[i].collectDocRefId);
            this.collectDoc.ref.get().then((doc) => {

              this.collectProductData = doc.data();
              if (this.allCollectArray.indexOf(this.collectProductData.retailerName) == -1) {
                this.allCollectArray.push(this.collectProductData.retailerName);
              }
              // this.collectArray = [];
              // if (this.collectArray.indexOf(this.collectData[i].collectDocRefId) == -1) {
              //   this.collectArray.push(this.collectProductData);
              // }
              this.collectArray.push(this.collectProductData);
              console.log('Collect Status is: ', this.collectProductData.status);
              console.log('Collect Message is: ', this.collectProductData.message);
              console.log('Collect Product Name is : ', this.collectProductData.productName);
            });
          }
        })
      )
      .subscribe();
  }

  getCollectOne(retailer: string) {
    console.log('Collect data being gathered');
    this.collectArray = [];
    this.oneCollectCollection = this.afs.collection('Customers').doc(this.userId).collection('Collect', ref => ref.where('retailerName', '==', retailer))
    this.oneCollectCollection.valueChanges()
      .pipe(
        map(res => {
          this.collectData = res;
          console.log('Collect length of items is: ', this.collectData.length);
          for (var i = 0; i <= this.collectData.length - 1; i++) {
            console.log('Collect collectDocRefId is: ', this.collectData[i].collectDocRefId);
            console.log('Retailer reference is: ', this.collectData[i].retailerreference);
            this.collectDoc = this.afs.collection('Retailers').doc(this.collectData[i].retailerreference).collection('ClickNCollect').doc(this.collectData[i].collectDocRefId);
            this.collectDoc.ref.get().then((doc) => {
              this.collectProductData = doc.data();
              // this.collectArray = [];
              // if (this.collectArray.indexOf(this.collectData[i].collectDocRefId) == -1) {
              //   this.collectArray.push(this.collectProductData);
              // }
              this.collectArray.push(this.collectProductData);
              console.log('Collect Status is: ', this.collectProductData.status);
              console.log('Collect Message is: ', this.collectProductData.message);
              console.log('Collect Product Name is : ', this.collectProductData.productName);
            });
          }
        })
      )
      .subscribe();
  }

}
