import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { auth } from 'firebase/app';
import algoliasearch from 'algoliasearch/lite';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { first, map, take } from "rxjs/operators";
import { Collect } from '../collect.model';
import { Retailer } from '../retailer.model';
import { Customer } from '../models/customer.model';
import { Message } from './message.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { getNameOfDeclaration } from 'typescript';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  constructor(private afs: AngularFirestore, private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    private fns: AngularFireFunctions,
    private AngularFireAuth: AngularFireAuth) {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
    })
  }

  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  collapsed = true;
  hasLoggedIn: boolean = false;

  //New attributes
  messageItemsArray: Array<AngularFirestoreDocument>;
  messagesData: any;
  allShopsMessages: AngularFirestoreCollection<Message>;
  allMessageItems: AngularFirestoreCollection<Message>;
  messages: Observable<Message[]>;
  selectedItem: number;
  runOnce: boolean = false;
  selectedIndex: number;
  newMessage: string;
  messageDoc: AngularFirestoreDocument<Message>;
  displayName: string;
  avatar: string;
  documentId: string;
  shopId: string;
  userId: string;
  firstShopName: string;
  initialisedView: boolean = false;
  navbarOpen = false;
  shopName: string;
  email: string;
  customerFirstName: string;
  customerLastName: string;
  customerData: any;
  customerDoc: AngularFirestoreDocument<Customer>;
  shopAvatar: string;
  shopData: any;
  shopDoc: AngularFirestoreDocument<Retailer>;
  retailerName: string;
  topic: string;
  directMessage: boolean = false;
  productName: string;
  productEnquiry: boolean = false;


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.shopId = params['shopId'];
      this.retailerName = params['retailerName'];
      this.topic = params['topic'];
      this.productName = params['productName'];
      this.directMessage = true;
    })
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid;
      this.displayName = user.displayName;
      this.avatar = user.photoURL
      this.email = user.email
      this.getName();
      if (this.shopId && this.retailerName) {
        this.selectShop(1, this.shopId, false, this.retailerName);
      } else {
        this.topic = 'Click & Collect';
        // this.getAllMessageItems();
      }
      this.countCollects();
      this.getAllMessageItems();
      console.log("ngOnInit - The userid is ", this.userId);
      // this.selectShop(1, 'WaOLjlKpJw3Joz2Rfz4S', false, 'Bookstones') ;
      // this.shopId = 'WaOLjlKpJw3Joz2Rfz4S';
      // this.getShop();
    })
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }

  getName() {
    //Firestore get customer name
    this.customerDoc = this.afs.collection('Customers').doc(this.userId);
    this.customerDoc.ref.get().then((doc) => {
      this.customerData = doc.data();
      this.customerLastName = this.customerData.customerLastName;
      this.customerFirstName = this.customerData.customerFirstName;
    });
  }

  getShop() {
    this.shopDoc = this.afs.collection('Retailers').doc(this.shopId);
    this.shopDoc.ref.get().then((doc) => {
      this.shopData = doc.data();
      this.shopAvatar = this.shopData.retailerlogo;
      this.retailerName = this.shopData.retailername;
      if (this.productName) {
        this.productEnquiry = true;
      }
    });
  }

  getShopCollect(retailerName: string) {
    console.log('Selected collect id is: ', retailerName);
    this.router.navigate(['/collect', retailerName]);
  }


  // **** New stuff here ****
  // ************************

  getAllMessageItems() {
    console.log('Messages data being gathered');
    this.messageItemsArray = [];
    this.messagesData = [];
    console.log('Initial query phase 1');
    //change messages id to the customerId
    // this.allShopsMessages = this.afs.collection('Customers').doc(this.userId).collection('Messages', ref => ref.orderBy('readStatus', 'desc').orderBy('lastUpdate', 'desc'))
    this.allShopsMessages = this.afs.collection('Customers').doc(this.userId).collection('Messages', ref => ref.orderBy('lastUpdate', 'desc'))

    this.allShopsMessages.valueChanges()
      // .pipe(take(1),
      .pipe(
        map(res => {
          this.messagesData = res;
          for (var i = 0; i <= this.messagesData.length - 1; i++) {
            // this.firstShopId = this.messagesData[0].shopId;
            // this.firstShopRead = this.messagesData[0].read;
            this.firstShopName = this.messagesData[0].senderName;
            console.log('First shopId is: ', this.messagesData[0].shopId);
            console.log('First shop readStatus is: ', this.messagesData[0].readStatus);
            // console.log('First shop name is: ', this.messagesData[0].senderName);
            console.log('First shop name is: ', this.firstShopName);
          }
        })
      )
      .subscribe();
    console.log('The first shop name is: ', this.firstShopName);
  }

  selectShop(index: number, shopId: string, read: boolean, shopName: string) {
    this.selectedIndex = index;
    this.shopId = shopId
    this.shopName = shopName;
    this.initialisedView = true;
    this.getShop();
    console.log('The selectedIndex item is: ', this.selectedIndex, ' the messageItemId is: ', shopId, ' read status is: ', read);
    this.allMessageItems = this.afs.collection('Customers').doc(this.userId).collection('Messages').doc(shopId).collection('MessageItems', ref => ref.orderBy('lastUpdate'));
    this.messages = this.allMessageItems.valueChanges();
    console.log('messages is: ', this.messages);
    if (!read) {
      index = -1;
      console.log('Will update the read status now');
      var messageDoc = this.afs.collection('Customers').doc(this.userId).collection('Messages').doc(shopId);
      return messageDoc.update({
        read: true,
        readStatus: 'read',
      })
    }
  }

  addMessage() {
    console.log('New message text is: ', this.newMessage)
    if (this.productEnquiry) {
      this.newMessage = 'Enquiry about ' + this.productName + ': ' + '\n' + this.newMessage;
    }
    // var topic = 'Click & Collect';
    var relatedDoc = 'relatedDoc Id here'
    //change messages id to the customerId
    var messageDoc = this.messageDoc = this.afs.collection('Customers').doc(this.userId).collection('Messages').doc(this.shopId);
    this.afs.collection('Customers').doc(this.userId).collection('Messages').doc(this.shopId).collection('MessageItems').add({
      messageBody: this.newMessage,
      customerId: this.userId,
      lastUpdate: new Date(),
      sender: 'customer',
      shopId: this.shopId,
      shopName: this.shopName,
      senderName: this.displayName,
      avatar: this.avatar
    })
      .then((docRef) => {
        console.log("Document written with  new function ID: ", docRef.id);
        this.documentId = docRef.id;
        this.afs.collection('Customers').doc(this.userId).collection('Messages').doc(this.shopId).set({
          topic: this.topic,
          lastUpdate: new Date(),
          relatedDoc: relatedDoc,
          lastMessage: this.newMessage,
          messageBody: this.newMessage,
          shopName: this.shopName,
          sender: 'customer',
          avatar: this.shopAvatar,
          shopId: this.shopId,
        }, { merge: true })
          // return messageDoc.update({
          //   topic: topic,
          //   lastUpdate: new Date(),
          //   relatedDoc: relatedDoc,
          //   lastMessage: this.newMessage,
          //   messageBody: this.newMessage,
          //   shopName: this.shopName,
          //   sender: 'customer'
          // })
          .then(() => {
            this.fns.httpsCallable('sendMessageCustomer')({ shopId: this.shopId, customerId: this.userId, emailAddr: this.email, lastName: this.customerLastName, messageItemId: this.documentId, topic: this.topic, messageBody: this.newMessage, senderName: this.displayName, sender: 'customer', avatar: this.avatar })
              .pipe(first())
              .subscribe(resp => {
                console.log({ resp });
              }, err => {
                console.error({ err });
              });
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        //change to use the actual shop senderName, not hardcoded The Bead Shop and avatar
        // this.fns.httpsCallable('sendMessageShop')({ shopId: this.shopId, customerId: this.customerId, messageItemId: this.documentId, messageBody: this.newMessage, senderName: 'The Bead Shop', sender: 'shop' , avatar: 'https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fthe-bead-shop-logo-1.png?alt=media&token=f33c4f50-2314-4ed8-b933-a7b0bfdc83e2'})
        // this.fns.httpsCallable('sendMessageCustomer')({ shopId: this.shopId, customerId: this.customerId, messageItemId: this.documentId, messageBody: this.newMessage, senderName: 'Robin Youlton', sender: 'customer', avatar: 'https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/faces%2Fry_avatar.png?alt=media&token=2ecfe1fc-b24a-4915-89ff-a415c48b36ee' })
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

}
