import { Component, forwardRef, Inject } from "@angular/core";
import { BaseWidget, NgAisInstantSearch } from "angular-instantsearch";
import { connectGeoSearch } from "instantsearch.js/es/connectors";
import { Router } from '@angular/router';

@Component({
  selector: "app-geo-search",
  templateUrl: './geo-search.component.html',
  styleUrls: ['./geo-search.component.scss']
})
export class GeoSearchComponent extends BaseWidget {
  state: {
    initialZoom: 4,
    clearMapRefinement: Function;
    hasMapMoveSinceLastRefine: Function;
    isRefineOnMapMove: Function;
    isRefinedWithMap: Function;
    items: { name: string; _geoloc: { lat: number; lng: number } }[];
    refine: Function;
    setMapMoveSinceLastRefine: Function;
    toggleRefineOnMapMove: Function;
    position: object;
  };



  constructor(
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent, private router: Router
  ) {
    super("GeoSearchComponent");
  }

  get center() {
    if (this.state.items && this.state.items.length > 0) {
      const [first] = this.state.items;
      return first._geoloc || { lat: 0, lng: 0 };
    }
    return { lat: 0, lng: 0 };
  }

  myClickFunction(docid) {
    let movieId = docid;
    //just added console.log which will display the event details in browser on click of the button.
    // alert("Movie is clicked " + docid);
    // console.log(docid);
    // this.router.navigate(['moviedetail',  { id: movieId }]);
    this.router.navigate(['/shopmain', docid]);
  }

  public ngOnInit() {
    this.createWidget(connectGeoSearch, {});
    super.ngOnInit();
  }
}