<style>
  #retailerlogo {
    /* border: 1px solid #ddd;
        border-radius: 4px; */
    padding: 5px;
    width: 150px;
  }

  #retailerlogo:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }

  #product {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
</style>

<header id="top-section">
  <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
    <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
    <!-- Collapsible content -->

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
      (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/home">Home</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/style">Style</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/collect">
            Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
              class="badge badge-danger ml-1">{{collectItemsCount}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
            <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
            Wishlist
          </a>
        </li>
        <li class="nav-item dropdown btn-group dropleft" dropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
            (click)="checkAuth()">
            Profile<span class="caret"></span></a>
          <div *ngIf="auth.user$ | async as user">
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
              <div class="divider dropdown-divider"></div>
              <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
<!-- <p>The Shop ID is: {{docid}}</p> -->



<ais-instantsearch [config]="config">
  <ais-configure [searchParameters]="searchParameters"></ais-configure>

  <div class="ais-SearchBox">

    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <!-- <img src="https://mdbootstrap.com/img/logo/mdb-transparent.png" height="30" alt="mdb logo"> -->
          <!-- <p class="mt-4">The Shop ID is: {{docid}}</p> -->
          <div class="mt-4">
            <img src="{{retailerlogo}}" id="retailerlogo" alt="" (click)="logoClicked()" class="z-depth-0">
          </div>
        </div>
        <div class="col-md-8">
          <div class="mt-4" style="widows: 600px;">

            <ais-search-box placeholder='Search for products' [searchAsYouType]="true" [autofocus]="true">
            </ais-search-box>

          </div>
        </div>
      </div>
    </div>

  </div>



  <!-- <div class="container">
        <div class="row">
            <div class="col-md-8">
                <p class="mt-4">The Shop ID is: {{docid}}</p>
            </div>
            <div class="col-md-4">
                <div class="mx-auto" style="width: 600px;">
                    <ais-search-box placeholder='Search for shops' [searchAsYouType]="true" [autofocus]="true">
                    </ais-search-box>
                </div>
            </div>
        </div>
    </div> -->

  <!-- <div class="container" style="margin-top:80px">
      <div class="col-sm-3">
        <ais-search-box placeholder='Search for shops' [searchAsYouType]="true" [autofocus]="true">
        </ais-search-box>
      </div>
    </div> -->

  <h2>{{displayname}}</h2>
  <div class="container" style="margin-top:30px">
    <div class="row">

      <div class="col-sm-3">

        <!-- <h2>Stretched Link in Card</h2> -->
        <p>Main Category</p>
        <ais-refinement-list attribute="displayname" [limit]="8"></ais-refinement-list>

        <!-- <h2>Stretched Link in Card</h2> -->
        <p>Size</p>
        <ais-refinement-list attribute="size" [limit]="8"></ais-refinement-list>

        <!-- <h2>Stretched Link in Card</h2> -->
        <p>3rd Category</p>
        <ais-refinement-list attribute="category3" [limit]="8"></ais-refinement-list>

        <!-- <h2>Stretched Link in Card</h2> -->
        <p>4th Category</p>
        <ais-refinement-list attribute="category4" [limit]="8"></ais-refinement-list>

      </div>

      <div class="col-sm-9">
        <ais-hits>
          <ng-template let-hits="hits">
            <!-- <ol class="ais-Hits-list"> -->
            <div class="card-columns">
              <!-- <li *ngFor="let hit of hits" class="ais-Hits-item"> -->
              <li *ngFor="let hit of hits" class="card">
                <img class="card-img-top" src="{{hit.mainimage}}" id="product" alt="Card image"
                  (click)="productClicked(hit.objectID)">
                <div class="card-body card-body-cascade text-center">
                  <h4 class="card-title">{{hit.objectID}}</h4>
                  <p class="card-text">
                    <ais-highlight attribute="name" [hit]="hit">
                    </ais-highlight>
                  </p>
                  <!-- <button (click)="myClickFunction(hit.objectID)" class="btn btn-primary stretched-link">See
                        Shop</button> -->
                </div>
                <!-- <div class="card-footer text-muted">
                      Footer
                    </div> -->
                <!-- </div> -->
              </li>
            </div>

            <!-- </ol> -->

          </ng-template>
        </ais-hits>
      </div>
    </div>
  </div>
  <ais-pagination></ais-pagination>
</ais-instantsearch>

<!--Footer-->
<footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
  <hr class="my-4">

  <!--Copyright-->
  <div class=" footer-copyright py-3">
    © 2021 Copyright:
    <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
  </div>
  <!--/.Copyright-->

</footer>
<!--/.Footer-->