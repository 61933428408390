import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { map } from "rxjs/operators";
import { Observable, throwError } from 'rxjs';
import { Collect } from '../collect.model';
import { Style } from '../style.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { DocumentRef } from '@agm/core/utils/browser-globals';

@Component({
  selector: 'app-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss']
})
export class StyleComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;


  styleArray: Array<AngularFirestoreDocument>;
  styleCollection: AngularFirestoreCollection<Style>;
  followStyleArray: Array<AngularFirestoreDocument>;
  followStyleCollection: AngularFirestoreCollection<Style>;
  styleData: any;
  followStyleData: any;
  myStyleDoc: AngularFirestoreDocument<Style>;
  followStyleDoc: AngularFirestoreDocument<Style>;
  myStyleData: any;
  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  myStyleDocRef: DocumentReference;
  myStyleDocId: string;
  followStyleLocalVersion: Array<string>;
  collectItemsCount: 0;
  faTag = faTag;
  faPortrait = faPortrait;
  faHeart = faHeart;
  faCog = faCog;
  userId: string;
  userName: string;
  userPhoto: string;
  hasLoggedIn: boolean = false;
  message1: string;
  message2: string;
  styleName: string = null;
  styleSummary: string;
  styleNameDefined: boolean = false;
  styleDoc: DocumentReference;



  constructor(private route: Router, public auth: AuthService,
    private afs: AngularFirestore,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth,
    private router: Router) {
  }

  ngOnInit() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid, this.userName = user.displayName, this.userPhoto = user.photoURL
      this.countCollects();
      console.log("style ngOnInit - The userid is: ", this.userId + " userName is: ", this.userName + " userPhoto is: ", this.userPhoto);
      this.getOwnStyle();
      this.getFollowStyle();

    })
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggled: boolean = false;
  handleSelection(event) {
    this.message1 += event.char;
    console.log(event.char);
    console.log(this.message1);
  }

  //call this wherever you want to close modal
  private closeModal(): void {
    this.closeBtn.nativeElement.click();
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }

  newStyle(styleName: string, styleSummary: string) {
    console.log('Style name is: ', styleName, ' Summary is: ', styleSummary);
    this.styleName = styleName;
    this.styleSummary = styleSummary;
    if (this.styleName == '' || this.styleName == undefined || this.styleName == null) {
      this.toastr.info('Enter a Style Name', 'STREETSHOPS', {
        timeOut: 2000,
      });
    }
    else {
      this.closeModal();
      this.addStyle();
      this.styleArray = [];
    }
  }

  addStyle() {
    this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists')
      .add({
        status: "active",
        followers: 0,
        view: 0,
        version: "1",
        stylename: this.styleName,
        styleSummary: this.styleSummary,
        owner: this.userName,
        createdBy: this.userId,
        ownerAvatar: this.userPhoto,
      }
      ).then(docRef => {
        this.myStyleDocId = docRef.id;
        this.myStyleDocRef = docRef;
        this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(this.myStyleDocId).set({
          styleid: docRef.id,
        }, { merge: true })
        console.log("Document written with ID: ", this.myStyleDocId)
        this.afs.collection('Customers').doc(this.userId).collection('MyStyle').add({
          styleid: docRef.id,
          changestate: "unchanged",
          source: "local",
          stylename: this.styleName,
          version: "1"
        })
        this.getOwnStyle();
      })
      .then(function () {

        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }

  getOwnStyle() {
    console.log('Style data being gathered');
    this.styleArray = [];
    var runCount = 0;
    this.styleCollection = this.afs.collection('Customers').doc(this.userId).collection('MyStyle', ref => ref.where('source', '==', 'local'));
    this.styleCollection.valueChanges()
      .pipe(
        map(res => {
          runCount = runCount + 1;
          if (runCount < 2) {
            this.styleData = res;
            this.styleArray = [];
            console.log('Style length of items is: ', this.styleData.length);
            for (var i = 0; i <= this.styleData.length - 1; i++) {
              console.log('StyleDocRefId is: ', this.styleData[i].styleid);
              console.log('Style local data is: ', this.styleData[i]);
              this.myStyleDoc = this.afs.collection('MyStyle').doc(this.userId).collection('Stylelists').doc(this.styleData[i].styleid);
              this.myStyleDoc.ref.get().then((doc) => {
                this.myStyleData = doc.data();
                this.styleArray.push(this.myStyleData);
                console.log('My Style data is: ', this.myStyleData);
              });
            }
          }
        })
      )
      .subscribe();
  }

  getFollowStyle() {
    console.log('Style data being gathered');
    console.log('user is: ', this.userId);
    this.followStyleArray = [];
    this.followStyleLocalVersion = [];
    var runCount = 0;
    this.followStyleCollection = this.afs.collection('Customers').doc(this.userId).collection('MyStyle', ref => ref.where('source', '==', 'friend' || 'shop'));
    this.followStyleCollection.valueChanges()
      .pipe(
        map(res => {
          runCount = runCount + 1;
          if (runCount < 2) {
            this.followStyleData = res;
            console.log('Style length of items is: ', this.followStyleData.length);
            for (var i = 0; i <= this.followStyleData.length - 1; i++) {
              console.log('StyleDocRefId is: ', this.followStyleData[i].styleid);
              console.log('Follow Style data is: ', this.followStyleData[i]);
              this.followStyleLocalVersion.push(this.followStyleData[i].version);
              console.log('Follow style version is: ', this.followStyleData[i].version);
              console.log('Follow style sharedBy is: ', this.followStyleData[i].sharedBy);
              this.followStyleDoc = this.afs.collection('MyStyle').doc(this.followStyleData[i].sharedBy).collection('Stylelists').doc(this.followStyleData[i].styleid);
              this.followStyleDoc.ref.get().then((doc) => {
                this.followStyleData = doc.data();
                this.followStyleArray.push(this.followStyleData);
                console.log('Follow Style data is: ', this.followStyleData);
              });
            }
          }
        }
        )
      )
      .subscribe();
  }

  showMyStyleList(styleid: string, styleOwner: string, styleName: string, styleSummary: string, ownerAvatar: string) {
    console.log('Selected style id is: ', styleid);
    this.router.navigate(['/mystyle/', styleid]);
  }

  showFollowStyleList(styleid: string, version: string, createdBy: string, styleName: string, styleSummary: string, ownerAvatar: string) {
    console.log('Selected style id is: ', styleid);
    this.updateLocalVersion(styleid, version);
    var followShareRoute = '/mystyle/' + styleid + '/' + createdBy + '/shared';
    this.router.navigate([followShareRoute]);
  }

  updateLocalVersion(styleid: string, version: string) {
    this.afs.collection('Customers').doc(this.userId).collection('MyStyle').doc(styleid).set({
      changestate: 'unchanged',
      version: version,
      lastRead: new Date(),
    }, { merge: true })
      .then(() => {
        console.log("Shared Style Data Document successfully written!");
      }
      )
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  deleteStylelist(styleid: string, listType: string) {
    const deletlist = this.afs.collection('Customers').doc(this.userId).collection('MyStyle', ref => ref.where('styleid', '==', styleid))
    deletlist.get().subscribe(delitems => delitems.forEach(doc => doc.ref.delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error deleting document: ", error);
      })))

    this.toastr.warning('Style list deleted', 'STREETSHOPS', {
      timeOut: 2000,
    });

    this.delay(3000).then(any => {
      //your task after delay.
      if (listType == 'followStyle') {
        this.getFollowStyle();
      } else {
        this.getOwnStyle();
      }
    });
  }

}
