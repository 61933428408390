<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    #jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    #toprow {
        background: #F2F3F4;
        /* height: 6vh; */
        margin-bottom: 10px;
        /* color: rgb(74, 33, 150); */
        line-height: 6vh;
        text-align: center;
    }

    /* h5 {
      font-weight:bold;
  } */
</style>
<header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
        <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
        <!-- Collapsible content -->

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/home">Home</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/style">Style</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/collect">
                        Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                            class="badge badge-danger ml-1">{{collectItemsCount}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
                        <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
                        Wishlist
                    </a>
                </li>
                <li class="nav-item dropdown btn-group dropleft" dropdown>
                    <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
                        (click)="checkAuth()">
                        Profile<span class="caret"></span></a>
                    <div *ngIf="auth.user$ | async as user">
                        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
                            <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
                            <div class="divider dropdown-divider"></div>
                            <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- <p>The Shop ID is: {{docid}}</p> -->
<!-- <ais-instantsearch [config]="config">
    <ais-search-box placeholder='Search for shops' [searchAsYouType]="true" [autofocus]="true" (keyup.enter)="onKey($event)">
    </ais-search-box>
  </ais-instantsearch> -->

<div class=" p-2 row" id="toprow">
    <div class="col-6 col-md-4 mt-2">
        <h5 style="font-weight:bold;" (click)="logoClicked()">{{ (retailer | async)?.retailername }}</h5>
    </div>
    <div class="col-6 col-md-4 mt-2">
        <h6>Premium Customers enjoy 15% off all purchases!
            <a href="https://www.mailorder-beads.co.uk/ordering-discount-info/" target="_blank">
                <i class="fas fa-external-link-alt"></i>
            </a>
        </h6>
    </div>
    <div class=" col-6 col-md-4 mt-2">
        <a mdbPageScroll href="#Contact">
            <h5 style="font-weight:bold; color: orange;">Contact <i class="far fa-envelope" style="color: orange;"></i>
            </h5>
        </a>
    </div>
</div>

<!-- <div class="row mb-5" style="height: 5vh;"></div> -->
<ais-instantsearch [config]="config">
    <!-- <ais-configure [searchParameters]="searchParameters"></ais-configure> -->

    <div class="ais-SearchBox">

        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <!-- <img src="https://mdbootstrap.com/img/logo/mdb-transparent.png" height="30" alt="mdb logo"> -->
                    <!-- <p class="mt-4">The Shop ID is: {{docid}}</p>
            <p class="mt-4">The Shop name is: {{ (retailer | async)?.retailername }}</p>
            <p class="mt-4">The Algolia Index is: {{ (retailer | async)?.algoliaindex }}</p> -->
                    <div class="mt-4 mb-3">
                        <img src="{{ (retailer | async)?.retailerlogo }}" id="retailerlogo" alt=""
                            (click)="logoClicked()" class="z-depth-0">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row mb-2" style="height: 5vh;"></div>
                    <div class="mt-5" style="widows: 600px;">
                        <ais-instantsearch [config]="config">
                            <ais-search-box placeholder='Search for products' [searchAsYouType]="true"
                                [autofocus]="false" (keyup.enter)="onKey($event)">
                            </ais-search-box>
                        </ais-instantsearch>

                    </div>
                </div>
            </div>
        </div>

    </div>

    <!--Main layout-->
    <main class="mt-2 pt-4">
        <div class="container dark-grey-text mt-5">

            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <img src="{{ (product | async)?.mainimage }}" class="img-fluid mb-5" alt="">

                    <div class="row mt5 mb-5">
                        <mdb-image-modal [modalImages]="images" type="margin"></mdb-image-modal>
                    </div>

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6 mb-4">

                    <!--Content-->
                    <div class="p-4">

                        <div class="mb-3">
                            <a href="">
                                <span class="badge purple mr-1">Category 2</span>
                            </a>
                            <a href="">
                                <span class="badge blue mr-1">New</span>
                            </a>
                            <a href="">
                                <span class="badge red mr-1">Bestseller</span>
                            </a>
                        </div>

                        <h3>{{ (product | async)?.productname }}</h3>

                        <p class="lead">
                            <span class="mr-1">
                                <del></del>
                            </span>
                            <span>{{ (product | async)?.productprice }}</span>
                        </p>
                        <a href="{{ (product | async)?.onlineurl }}" target="_blank">
                            Buy Online <mdb-icon fas icon="cart-arrow-down"></mdb-icon>
                        </a>
                        <p class="lead font-weight-bold">Description</p>

                        <p>{{ (product | async)?.productheader }}</p>

                        <br>

                        <p>{{ (product | async)?.productdescription }}</p>

                        <!-- <form class="d-flex justify-content-left mt-5 mb-5"> -->
                            
                            <p>Ask the shop about this product</p>
                            <button class="btn btn-success btn-md my-0 p ml-3" (click)="messageShop()"
                                type="submit">Contact
                                <i class="fas fa-envelope ml-1"></i>
                            </button>
                        <!-- </form> -->

                        <hr>

                        <!-- Group of material radios - option 1 -->
                        <div class="form-check" *ngFor="let variant of variants | async;">
                            <input type="radio" class="form-check-input" (click)="variantSelected(variant.variantType)"
                                id="'{{variant.variantType}}'" name="groupOfMaterialRadios">
                            <label class="form-check-label" for="'{{variant.variantType}}'">{{variant.variantLabel}}
                                {{variant.variantType}} {{variant.variantPrice}}</label>
                        </div>

                        <hr>


                        <form class="d-flex justify-content-left">
                            <!-- Default input -->
                            <input type="number" value="1" aria-label="Search" class="form-control"
                                style="width: 100px">
                            <button class="btn btn-primary btn-md my-0 p" (click)="collectButtonClicked()"
                                type="submit">Click & Collect
                                <i class="fas fa-tag ml-1"></i>
                            </button>
                        </form>

                        <hr>

                        <div class="dropdown" mdbDropdown>
                            <a mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light"
                                type="button" mdbWavesEffect>
                                Add to My Style
                            </a>

                            <div class="dropdown-menu dropdown-primary">
                                <a *ngFor="let style of styleArray" class="dropdown-item"
                                    (click)="addToStyle(style.styleid,style.version)">{{style.stylename}}</a>
                            </div>
                            <button type="button" type="Submit" (click)="addWishlist()"
                                class="btn btn-danger btn-rounded">Add to Wishlist <i class="far fa-heart"></i></button>
                            <hr>
                        </div>

                    </div>
                    <!--Content-->

                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

            <hr>

            <!--Grid row-->
            <div class="row d-flex justify-content-center wow fadeIn">

                <!--Grid column-->
                <div class="col-md-6 text-center">

                    <h4 class="my-4 h4">Additional information</h4>

                    <p class="text-left">Pack size: {{ (product | async)?.packsize }}</p>
                    <p class="text-left">Colour: {{ (product | async)?.colour }}</p>
                    <p class="text-left">Shape: {{ (product | async)?.shape }}</p>



                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

            <hr>
            <div class="row d-flex justify-content-center wow fadeIn">
                <div class="col-md-6 text-center">
                    <h4 class="my-4 h4">Video Tutorial</h4>
                </div>
            </div>


            <div class="embed-responsive embed-responsive-16by9">
                <!-- <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/nroIYBNbC5I" -->
                <iframe class="embed-responsive-item" [src]="getVideo((product | async)?.videoid)"
                    allowfullscreen></iframe>
            </div>

            <p>YoutubeURL is: {{youTubeURL}} </p>

            <hr>

            <section>
                <div class="row d-flex justify-content-center wow fadeIn">
                    <div class="col-md-6 text-center">
                        <h5 class="my-4 h5">Related Products</h5>
                    </div>
                </div>

                <!--Grid row-->
                <div class="row wow fadeIn">

                    <!--Grid column-->
                    <div class="col-md-6 text-center mb-4">

                        <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fkits-and-patterns-300x300.jpg?alt=media&token=f54115b8-2e72-4d38-8321-6778a4f496f5"
                            class="img-fluid" alt="">

                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-md-6 text-center mb-4">

                        <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2FMixed-Media-and-Extras-300x300.jpg?alt=media&token=fe243d62-defb-4277-b88a-0c261e75e9e9"
                            class="img-fluid" alt="">

                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-md-6 text-center mb-4">

                        <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2FThreads-and-wire-300x300.jpg?alt=media&token=0b96ccd2-7d0b-4373-9107-88909c821b1c"
                            class="img-fluid" alt="">

                    </div>
                    <!--Grid column-->

                </div>
                <!--Grid row-->
            </section>

        </div>
        <hr>
    </main>
    <!--Main layout-->

    <!--Footer-->
    <footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
        <hr class="my-4">

        <!--Copyright-->
        <div class=" footer-copyright py-3">
            © 2021 Copyright:
            <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
        </div>
        <!--/.Copyright-->

    </footer>
    <!--/.Footer-->