<style>
  #retailerlogo {
    /* border: 1px solid #ddd;
      border-radius: 4px; */
    padding: 5px;
    width: 150px;
  }

  #retailerlogo:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }

  .center {
    width: 75%;
    margin: 10px auto;
  }

  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #jumbotron1 {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .map-container-5 {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .map-container-5 iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  #up_button {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  #categoryimage {
    /* border: 1px solid #ddd;
      border-radius: 4px; */
    padding: 5px;
    width: 50px;
  }

  #toprow {
    background: #F2F3F4;
    /* height: 6vh; */
    margin-bottom: 10px;
    /* color: rgb(74, 33, 150); */
    line-height: 6vh;
    text-align: center;
  }

  /* h5 {
    font-weight:bold;
} */
  img.resize {
    max-height: 100px;
    max-width: 100px;
    /* height: auto;
        width: auto; */
  }
</style>
<header id="top-section">
  <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
    <a class="navbar-brand" routerLink="/home">STREETSHOPS</a>
    <!-- Collapsible content -->

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
      (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/home">Home</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/style">Style</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link" pageScroll routerLink="/collect">
            Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
              class="badge badge-danger ml-1">{{collectItemsCount}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="checkAuth()" pageScroll routerLink="/wishlist">
            <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
            Wishlist
          </a>
        </li>
        <li class="nav-item dropdown btn-group dropleft" dropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect
            (click)="checkAuth()">
            Profile<span class="caret"></span></a>
          <div *ngIf="auth.user$ | async as user">
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Account</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Subscriptions</a>
              <a class="dropdown-item waves-light" href="#" mdbWavesEffect>Settings</a>
              <div class="divider dropdown-divider"></div>
              <a class="dropdown-item waves-light" (click)="auth.signOut()" mdbWavesEffect>Logout</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
<!-- <p>The Shop ID is: {{docid}}</p> -->
<!-- <ais-instantsearch [config]="config">
  <ais-search-box placeholder='Search for shops' [searchAsYouType]="true" [autofocus]="true" (keyup.enter)="onKey($event)">
  </ais-search-box>
</ais-instantsearch> -->

<div class=" p-2 row" id="toprow">
  <div class="col-6 col-md-4 mt-2">
    <h5 style="font-weight:bold;" (click)="logoClicked()">{{ (retailer | async)?.retailername }}</h5>
  </div>
  <div class="col-6 col-md-4 mt-2">
    <h6>Premium Customers enjoy 15% off all purchases!
      <a href="https://www.mailorder-beads.co.uk/ordering-discount-info/" target="_blank">
        <i class="fas fa-external-link-alt"></i>
      </a>
    </h6>
  </div>
  <div class=" col-6 col-md-4 mt-2">
    <a mdbPageScroll href="#Contact">
      <h5 style="font-weight:bold; color: orange;">Contact <mdb-icon far icon="envelope" class="ml-1"
          style="color: orange;"></mdb-icon>
      </h5>
    </a>
  </div>
</div>

<!-- <div class="row mb-5" style="height: 5vh;"></div> -->
<ais-instantsearch [config]="config">
  <!-- <ais-configure [searchParameters]="searchParameters"></ais-configure> -->

  <div class="ais-SearchBox">

    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <!-- <img src="https://mdbootstrap.com/img/logo/mdb-transparent.png" height="30" alt="mdb logo"> -->
          <!-- <p class="mt-4">The Shop ID is: {{docid}}</p>
          <p class="mt-4">The Shop name is: {{ (retailer | async)?.retailername }}</p>
          <p class="mt-4">The Algolia Index is: {{ (retailer | async)?.algoliaindex }}</p> -->
          <div class="mt-4 mb-3">
            <img src="{{ (retailer | async)?.retailerlogo }}" id="retailerlogo" alt="" (click)="logoClicked()"
              class="z-depth-0">
          </div>
        </div>
        <div class="col-md-8">
          <div class="row mb-2" style="height: 5vh;"></div>
          <div class="mt-5" style="widows: 600px;">
            <ais-instantsearch [config]="config">
              <ais-search-box placeholder='Search for products' [searchAsYouType]="true" [autofocus]="false"
                (keyup.enter)="onKey($event)">
              </ais-search-box>
            </ais-instantsearch>

          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Nav tabs -->
  <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-dark mb-2'" [justified]="true" [contentClass]="'card'"
    (click)="getCustomerData()">
    <!--Panel 1-->
    <mdb-tab heading="Home">
      <div class="row">
        <div class="col-12">
          <div class="jumbotron p-0" id="top-section">
            <mdb-card style="background-image: url('{{ (retailer | async)?.wpimage1 }}')" id="jumbotron1">
              <div class="text-white text-center rgba-stylish-strong py-5 px-4">
                <div class="row">
                  <div class="col text-right mr-4 mt-4">
                    <button mdbBtn class="btn btn-warning btn-rounded" color="warning" mdbWavesEffect
                      (click)="addShopFavourite()">Add Favourite
                      <mdb-icon fas icon="store" class="ml-1"></mdb-icon>
                    </button>
                    <!-- <h5 class="h5 orange-text">Favourite Shop</h5>
                    <div class="switch">
                      <label>
                        Off
                        <input type="checkbox">
                        <span class="lever"></span> On
                      </label>
                    </div> -->
                  </div>
                </div>
                <div class="py-5">


                  <!-- <img src="{{ (retailer | async)?.wpimage1 }}" alt=""
                    class="z-depth-0"> -->
                  <h1>
                    <mdb-badge class="h1">{{ (retailer | async)?.retailername }}</mdb-badge>
                  </h1>
                  <h3 class="mt-5 mb-5 pb-2 px-md-5 mx-md-5">{{ (retailer | async)?.retailerdesc }}</h3>

                  <a mdbPageScroll href="#Video">
                    <h5 style="font-weight:bold; color: orange;">Our video <mdb-icon fas icon="film"
                        style="color: orange;" class="ml-1"></mdb-icon>
                    </h5>
                  </a>



                  <!-- <a mdbBtn gradient="peach" mdbWavesEffect>
                    <mdb-icon fas icon="clone" class="left"></mdb-icon> Collect
                  </a>
                  <a mdbBtn gradient="peach" mdbWavesEffect>
                    <mdb-icon fas icon="clone" class="left"></mdb-icon> Wishlist
                  </a>
                  <a mdbBtn gradient="peach" mdbWavesEffect>
                    <mdb-icon fas icon="clone" class="left"></mdb-icon> Favourites
                  </a>
                  <a mdbBtn gradient="peach" mdbWavesEffect>
                    <mdb-icon fas icon="clone" class="left"></mdb-icon> Loyalty
                  </a> -->
                </div>
              </div>
            </mdb-card>
          </div>
          <!-- Jumbotron -->
        </div>
      </div>


      <!--Section: Products v.3-->
      <section class="section pb-3 wow fadeIn" data-wow-delay="0.3s">

        <!--Section heading-->
        <h1 class="font-weight-bold text-center h1 my-5">Our Most Popular Items</h1>
        <!--Section description-->

        <p class="text-center grey-text mb-5 mx-auto w-responsive">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit. Fugit, error amet numquam iure provident voluptate esse
          quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</p>

        <!--Grid row-->
        <div class="row">

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4">

            <!--Card-->
            <div class="card card-ecommerce">

              <!--Card image-->
              <div class="view overlay z-depth-1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fczech-mix-faceted-mix-300x300.jpg?alt=media&token=0345e04a-664f-4c1a-b05a-ffb3b3212163"
                  class="card-img-top" alt="">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
              <!--Card image-->

              <!--Card content-->
              <div class="card-body text-center no-padding">
                <!--Category & Title-->
                <a href="" class="text-muted">
                  <h5>Beads</h5>
                </a>
                <h4 class="card-title">
                  <strong>
                    <a href="">Glass Beads</a>
                  </strong>
                </h4>

                <!--Description-->
                <p class="card-text">Neque porro quisquam est, qui dolorem ipsum quia dolor.
                </p>
              </div>
              <!--Card content-->
              <!--Card footer-->
              <div class="card-footer">
                <span class="float-left">£29
                  <span class="discount">was £32.99</span>
                </span>
                <span class="float-right">
                  <a class="" data-toggle="tooltip" data-placement="top" title="Add to Wishlist">
                    <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                  </a>
                </span>
              </div>
            </div>
            <!--Card-->

          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4">

            <!--Card-->
            <div class="card card-ecommerce">

              <!--Card image-->
              <div class="view overlay z-depth-1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fplastic-beads.jpg?alt=media&token=54b87613-8edd-4f13-90ce-5cf2b8bfad51"
                  class="card-img-top" alt="">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
              <!--Card image-->

              <!--Card content-->
              <div class="card-body text-center no-padding">
                <!--Category & Title-->
                <a href="" class="text-muted">
                  <h5>Beads</h5>
                </a>
                <h4 class="card-title">
                  <strong>
                    <a href="">Plastic Beads</a>
                  </strong>
                </h4>

                <!--Description-->
                <p class="card-text">Neque porro quisquam est, qui dolorem ipsum quia dolor.
                </p>
              </div>
              <!--Card content-->
              <!--Card footer-->
              <div class="card-footer">
                <span class="float-left">£25
                  <span class="discount">was £33</span>
                </span>
                <span class="float-right">
                  <a class="" data-toggle="tooltip" data-placement="top" title="Add to Wishlist">
                    <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                  </a>
                </span>
              </div>
            </div>
            <!--Card-->

          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4">

            <!--Card-->
            <div class="card card-ecommerce">

              <!--Card image-->
              <div class="view overlay z-depth-1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fround-wood-300x300.jpg?alt=media&token=7da5b971-3a30-4e3d-a857-aea3ce1de3f2"
                  class="card-img-top" alt="">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
              <!--Card image-->

              <!--Card content-->
              <div class="card-body text-center no-padding">
                <!--Category & Title-->
                <a href="" class="text-muted">
                  <h5>Beads</h5>
                </a>
                <h4 class="card-title">
                  <strong>
                    <a href="">Wooden Beads</a>
                  </strong>
                </h4>

                <!--Description-->
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing minima.
                </p>
              </div>
              <!--Card content-->
              <!--Card footer-->
              <div class="card-footer">
                <span class="float-left">£49
                  <span class="discount">was £50</span>
                </span>
                <span class="float-right">
                  <a class="" data-toggle="tooltip" data-placement="top" title="Add to Wishlist">
                    <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                  </a>
                </span>
              </div>
            </div>
            <!--Card-->

          </div>
          <!--Grid column-->

          <!--Fourth column-->
          <div class="col-lg-3 col-md-6 mb-4">

            <!--Card-->
            <div class="card card-ecommerce">

              <!--Card image-->
              <div class="view overlay z-depth-1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2FSemi-precious-300x300.jpg?alt=media&token=342dccfc-09a1-409d-8683-6ac008f3e181"
                  class="card-img-top" alt="">
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
              <!--Card image-->

              <!--Card content-->
              <div class="card-body text-center no-padding">
                <!--Category & Title-->
                <a href="" class="text-muted">
                  <h5>Beads</h5>
                </a>
                <h4 class="card-title">
                  <strong>
                    <a href="">Precious Beads</a>
                  </strong>
                </h4>

                <!--Description-->
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing minima.
                </p>
              </div>
              <!--Card content-->

              <!--Card footer-->
              <div class="card-footer">
                <span class="float-left">£49
                  <span class="discount">was £58</span>
                </span>
                <span class="float-right">
                  <a class="active" data-toggle="tooltip" data-placement="top" title="Added to Wishlist">
                    <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                  </a>
                </span>
              </div>

            </div>
            <!--Card-->

          </div>
          <!--Fourth column-->

        </div>
        <!--Grid row-->

      </section>

      <!--Section: Products v.5-->
      <section class="text-center pb-3 my-5">
        <!--Section heading-->
        <h2 class="h1 py-5">New in</h2>
        <!--Section description-->
        <p class="grey-text pb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam
          iure
          provident voluptate esse quasi,
          veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</p>

        <!--Carousel Wrapper-->
        <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'"
          [animation]="'slide'">
          <!--First slide-->
          <mdb-slide>

            <!-- <div class="card-deck"> -->
            <div *ngFor="let product of products | async ; let i=index">
              <div *ngIf="i == 0 || i == 1 || i == 2 ">
                <!-- <div *ngIf="product.productname == 'Navy London Leather Trainers'"> -->
                <!-- <div *ngIf="product.productid == '60901' || product.productname == 'Navy London Leather Trainers' "> -->
                <!-- <div class="col-md-4"> -->
                <div class="col-md-4 clearfix d-none d-md-block">
                  <!-- <div class="card-group"> -->
                  <!--Card-->
                  <mdb-card cascade="true" narrower="true" class="card-ecommerce card h-100">
                    <!-- <mdb-card> -->
                    <!--Card image-->
                    <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                      <!-- <div class="view view-cascade overlay waves-light" mdbWavesEffect> -->
                      <mdb-card-img src="{{product.mainimage}}" alt="sample photo"></mdb-card-img>
                      <!-- <mdb-card-img
                        src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/img%20(57).jpg"
                        alt="sample photo"></mdb-card-img> -->
                      <a>
                        <div class="mask waves-effect waves-light"></div>
                      </a>
                    </div>
                    <!--Card image-->
                    <!--Card content-->
                    <mdb-card-body cascade="true" class="text-center no-padding">
                      <!--Category & Title-->
                      <a href="" class="text-muted">
                        <h5>{{product.productid}}</h5>
                      </a>
                      <mdb-card-title>
                        <h4 class="card-title my-4">
                          <strong>
                            <a href="">{{product.productname}}</a>
                          </strong>
                        </h4>
                      </mdb-card-title>
                      <!--Description-->
                      <mdb-card-text>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                        adipisci.
                      </mdb-card-text>
                    </mdb-card-body>
                    <mdb-card-footer class="card-footer text-muted text-center">
                      <span class="float-left">£29</span>
                      <span class="float-right">
                        <a class="" data-toggle="tooltip" data-placement="top" title=""
                          data-original-title="Quick Look">
                          <mdb-icon far icon="eye" class="ml-3"></mdb-icon>
                        </a>
                        <a class="" data-toggle="tooltip" data-placement="top" title=""
                          data-original-title="Add to Wishlist">
                          <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                        </a>
                      </span>
                    </mdb-card-footer>
                    <!--Card content-->
                  </mdb-card>
                  <!--Card-->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>
            </div>

          </mdb-slide>
          <!--/First slide-->
          <!--Second slide-->
          <mdb-slide>
            <div *ngFor="let product of products | async ; let i=index">
              <div *ngIf="i == 3 || i == 4 || i == 5 ">
                <div class="col-md-4 card h-100">
                  <!--Card-->

                  <mdb-card cascade="true" narrower="true" class="card-ecommerce card h-100">
                    <!--Card image-->
                    <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                      <mdb-card-img src="{{product.mainimage}}" alt="sample photo"></mdb-card-img>
                      <a>
                        <div class="mask waves-effect waves-light"></div>
                      </a>
                    </div>
                    <!--Card image-->
                    <!--Card content-->
                    <mdb-card-body cascade="true" class="text-center no-padding">
                      <!--Category & Title-->
                      <a href="" class="text-muted">
                        <h5>{{product.productname}}</h5>
                      </a>
                      <mdb-card-title>
                        <h4 class="card-title my-4">
                          <strong>
                            <a href="">{{product.productname}}</a>
                          </strong>
                        </h4>
                      </mdb-card-title>
                      <!--Description-->
                      <mdb-card-text>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                        adipisci.
                      </mdb-card-text>
                      <!-- Card footer -->
                    </mdb-card-body>
                    <mdb-card-footer class="card-footer text-muted text-center">
                      <span class="float-left">£29</span>
                      <span class="float-right">
                        <a class="" data-toggle="tooltip" data-placement="top" title=""
                          data-original-title="Quick Look">
                          <mdb-icon far icon="eye" class="ml-3"></mdb-icon>
                        </a>
                        <a class="" data-toggle="tooltip" data-placement="top" title=""
                          data-original-title="Add to Wishlist">
                          <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                        </a>
                      </span>
                    </mdb-card-footer>

                    <!--Card content-->
                  </mdb-card>
                  <!--Card-->
                </div>
              </div>
            </div>
          </mdb-slide>
          <!--/Second slide-->
          <!--Third slide-->
          <mdb-slide>
            <div class="col-md-4">
              <!--Card-->
              <mdb-card cascade="true" narrower="true" class="card-ecommerce card h-100">
                <!--Card image-->
                <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                  <mdb-card-img
                    src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2FMixed-Media-and-Extras-300x300.jpg?alt=media&token=fe243d62-defb-4277-b88a-0c261e75e9e9"
                    alt="sample photo"></mdb-card-img>
                  <a>
                    <div class="mask waves-effect waves-light"></div>
                  </a>
                </div>
                <!--Card image-->
                <!--Card content-->
                <mdb-card-body cascade="true" class="text-center no-padding">
                  <!--Category & Title-->
                  <a href="" class="text-muted">
                    <h5>Beads</h5>
                  </a>
                  <mdb-card-title>
                    <h4 class="card-title my-4">
                      <strong>
                        <a href="">Glass Beads</a>
                      </strong>
                    </h4>
                  </mdb-card-title>
                  <!--Description-->
                  <mdb-card-text>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                    adipisci.
                  </mdb-card-text>
                </mdb-card-body>
                <!--Card footer-->
                <mdb-card-footer>
                  <span class="float-left">£29</span>
                  <span class="float-right">
                    <a class="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Quick Look">
                      <mdb-icon far icon="eye" class="ml-3"></mdb-icon>
                    </a>
                    <a class="" data-toggle="tooltip" data-placement="top" title=""
                      data-original-title="Add to Wishlist">
                      <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                    </a>
                  </span>
                </mdb-card-footer>

                <!--Card content-->
              </mdb-card>
              <!--Card-->
            </div>
            <div class="col-md-4 clearfix d-none d-md-block">
              <!--Card-->
              <mdb-card cascade="true" narrower="true" class="card-ecommerce card h-100">
                <!--Card image-->
                <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                  <mdb-card-img
                    src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fswarovski-beads-and-pendant-300x300.jpg?alt=media&token=2c6705b8-cca1-42d0-9d6f-9327719f0628"
                    alt="sample photo"></mdb-card-img>
                  <a>
                    <div class="mask waves-effect waves-light"></div>
                  </a>
                </div>
                <!--Card image-->
                <!--Card content-->
                <mdb-card-body cascade="true" class="text-center no-padding">
                  <!--Category & Title-->
                  <a href="" class="text-muted">
                    <h5>Beads</h5>
                  </a>
                  <mdb-card-title>
                    <h4 class="card-title my-4">
                      <strong>
                        <a href="">Plastic Beads</a>
                      </strong>
                    </h4>
                  </mdb-card-title>
                  <!--Description-->
                  <mdb-card-text>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                    adipisci.
                  </mdb-card-text>
                  <!--Card footer-->
                </mdb-card-body>
                <mdb-card-footer>
                  <span class="float-left">£29</span>
                  <span class="float-right">
                    <a class="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Quick Look">
                      <mdb-icon far icon="eye" class="ml-3"></mdb-icon>
                    </a>
                    <a class="" data-toggle="tooltip" data-placement="top" title=""
                      data-original-title="Add to Wishlist">
                      <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                    </a>
                  </span>
                </mdb-card-footer>

                <!--Card content-->
              </mdb-card>
              <!--Card-->
            </div>
            <div class="col-md-4 clearfix d-none d-md-block">
              <!--Card-->
              <mdb-card cascade="true" narrower="true" class="card-ecommerce card h-100">
                <!--Card image-->
                <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                  <mdb-card-img
                    src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fretailer4%2Fswarovski-beads-and-pendant-300x300.jpg?alt=media&token=2c6705b8-cca1-42d0-9d6f-9327719f0628"
                    alt="sample photo"></mdb-card-img>
                  <a>
                    <div class="mask waves-effect waves-light"></div>
                  </a>
                </div>
                <!--Card image-->
                <!--Card content-->
                <mdb-card-body cascade="true" class="text-center no-padding">
                  <!--Category & Title-->
                  <a href="" class="text-muted">
                    <h5>Beads</h5>
                  </a>
                  <mdb-card-title>
                    <h4 class="card-title my-4">
                      <strong>
                        <a href="">Wooden Beads</a>
                      </strong>
                    </h4>
                  </mdb-card-title>
                  <!--Description-->
                  <mdb-card-text>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                    adipisci.
                  </mdb-card-text>
                  <!--Card footer-->
                </mdb-card-body>
                <mdb-card-footer>
                  <span class="float-left">£29</span>
                  <span class="float-right">
                    <a class="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Quick Look">
                      <mdb-icon far icon="eye" class="ml-3"></mdb-icon>
                    </a>
                    <a class="" data-toggle="tooltip" data-placement="top" title=""
                      data-original-title="Add to Wishlist">
                      <mdb-icon far icon="heart" style="color:red" class="ml-3"></mdb-icon>
                    </a>
                  </span>
                </mdb-card-footer>

                <!--Card content-->
              </mdb-card>
              <!--Card-->
            </div>
          </mdb-slide>
          <!--/Third slide-->
        </mdb-carousel>
        <!--/.Carousel Wrapper-->

      </section>
      <!--Section: Products v.5-->



      <!--Section: Features v.4-->
      <section>

        <!-- <div class="card-deck align-items-center justify-content-center d-flex wow"> -->
        <div class="card-deck justify-content-center align-items-center">
          <div *ngFor="let category of categories | async ; let i=index">
            <!-- <div *ngIf="i == 0 || i == 1 || i == 2 "> -->
            <!-- Card -->
            <div class="main-div">
              <mdb-card class="mb-4" cascade="true" narrower="true" style="width: 20rem;"
                (click)="categoryClicked(category.categoryname, category.categorysubmenu, category.displayname)">
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                  <!-- Card img -->
                  <mdb-card-img src="{{category.categoryimage}}" alt="sample photo" class="card-img-top">
                  </mdb-card-img>
                  <a>
                    <div class="mask"></div>
                  </a>
                </div>
                <!--Card content-->
                <mdb-card-body class="text-center no-padding">
                  <!--Title-->
                  <mdb-card-title>
                    <h4>{{ category.displayname }}</h4>
                  </mdb-card-title>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
          <!-- </div> -->

        </div>

        <!-- Streak Section -->
        <div class="streak streak-photo"
          style="background-image:url('https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/wooden-beads-at-the-nottingham-bead-shop.jpg?alt=media&token=837757dc-fc3c-498d-b050-192feeeed31e')">

          <div class="flex-center white-text pattern-1" style="background-attachment: fixed">
            <ul class="list-unstyled">
              <li>
                <h1 class=" white-text">
                  <strong>Highest quality</strong> ethically sourced beads and craft materials
                </h1>
              </li>

            </ul>
          </div>

        </div>
        <!-- /.Streak Section -->



        <!--Section heading-->

        <!-- Section: Features v.3 -->
        <section class="my-5">

          <!-- Section heading -->
          <h2 class="h1-responsive font-weight-bold text-center my-5">Starting out in Jewellery making?</h2>
          <!-- Section description -->
          <p class="lead grey-text text-center w-responsive mx-auto mb-5">Starting out in jewellery making can be
            daunting, especially when everyone around you seems to be making expert Kumihimo braids and you’re still
            learning the basics.</p>

          <!-- Grid row -->
          <div class="row">

            <!-- Grid column -->
            <div class="col-lg-5 text-center text-lg-left mb-5">
              <img class="img-fluid"
                src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Introduction-to-Jewellery-k.jpg?alt=media&token=783693b2-4122-4d1a-b7b2-80d1a63ddcde"
                alt="Sample image">
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-lg-7">

              <!-- Grid row -->
              <div class="row mb-3">

                <!-- Grid column -->
                <div class="col-1">
                  <i class="fas fa-share fa-lg indigo-text"></i>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-xl-10 col-md-11 col-10">
                  <h5 class="font-weight-bold mb-3">Start with what you enjoy the most</h5>
                  <p class="grey-text">When it comes to jewellery making the choices are endless and it is unrealistic
                    to try and master all styles of jewellery at once, when you’re only just building on your skills. To
                    make life easier for yourself, choose one particular style, or method of jewellery making and work
                    on perfecting that first, before you move on to anything new.</p>
                </div>
                <!-- Grid column -->

              </div>
              <!-- Grid row -->

              <!-- Grid row -->
              <div class="row mb-3">

                <!-- Grid column -->
                <div class="col-1">
                  <i class="fas fa-share fa-lg indigo-text"></i>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-xl-10 col-md-11 col-10">
                  <h5 class="font-weight-bold mb-3">Stick to one or two basic skills first</h5>
                  <p class="grey-text">Regardless of the type of jewellery making you decide to go into, there are a few
                    basic skills that you are going to need to learn. There is a lot of crossover when it comes to
                    jewellery making, so these basic skills will always be with you, regardless of how complicated you
                    decide to make your pieces in the future. </p>
                </div>
                <!-- Grid column -->

              </div>
              <!-- Grid row -->

              <!--Grid row-->
              <div class="row">

                <!-- Grid column -->
                <div class="col-1">
                  <i class="fas fa-share fa-lg indigo-text"></i>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-xl-10 col-md-11 col-10">
                  <h5 class="font-weight-bold mb-3">Invest in Yourself</h5>
                  <p class="grey-text mb-0">Whilst you are learning, your jewellery won’t need to be perfect, however as
                    you become more advanced, you will need to take a little more pride in the appearance of your
                    jewellery. In time, hacking at your craft wire with the kitchen knife won’t be good enough and you
                    will need proper jewellery making tools. Not only will your jewellery look better, but it will make
                    it much easier and quicker for you. </p>
                </div>
                <!-- Grid column -->

              </div>
              <!--Grid row-->

            </div>
            <!--Grid column-->

          </div>
          <!-- Grid row -->

        </section>


        <!-- Streak Section -->
        <div class="streak streak-photo"
          style="background-image:url('https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/workshop-in-progress-priv-.jpg?alt=media&token=0e61687e-45b1-44c4-acf7-f679522b5b86')">

          <div class="flex-center white-text pattern-1" style="background-attachment: fixed">
            <ul class="list-unstyled">
              <li>
                <h1 class=" white-text">
                  <strong>Jewellery Making Classes</strong> (currently virtual only)
                  <a href="https://www.mailorder-beads.co.uk/workshops/" target="_blank">
                    <i style="color:orange" class="fas fa-external-link-alt"></i>
                  </a>
                </h1>
              </li>
            </ul>
          </div>

        </div>
        <!-- /.Streak Section -->
        <!-- Section: Features v.3 -->

        <!--Grid row-->

      </section>
      <!--/Section: Features v.4-->

      <!--Grid row-->
      <div class="row mt-3">

        <!--Grid column-->
        <div class="col-md-4 mb-4">

          <!--Card-->
          <div class="card card-cascade narrower">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header peach-gradient" id="Video">
              <h5 class="mb-0">Video</h5>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <div class="card-body card-body-cascade text-center">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/fqsdBp28_ow"
                  allowfullscreen></iframe>
              </div>


            </div>
            <!--/.Card content-->

          </div>
          <!--/.Card-->

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-4 mb-4">

          <!--Card-->
          <div class="card card-cascade narrower">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header peach-gradient">
              <h5 class="mb-0">Location</h5>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <div class="card-body card-body-cascade text-center">
              <p class="card-text">{{ (retailer | async)?.address }}</p>
              <!--Google map-->
              <div id="map-container-google-9" class="z-depth-1-half map-container-5" style="height: 300px">
                <!-- <iframe src="{{ (retailer | async)?.gmapurl }}" frameborder="0" style="border:0" allowfullscreen></iframe> -->
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d570.4802125085446!2d-1.1519955895125185!3d52.954063057805136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c22a9c475d05%3A0xdde51db09d9eb410!2sThe%20Bead%20Shop%20(Nottingham)%20Limited!5e0!3m2!1sen!2suk!4v1594833012805!5m2!1sen!2suk"
                  frameborder="0" style="border:0" allowfullscreen></iframe> -->
              </div>


            </div>
            <!--/.Card content-->

          </div>
          <!--/.Card-->

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-4 mb-4">

          <!--Card-->
          <div class="card card-cascade narrower">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header peach-gradient" id="Contact">
              <h5 class="mb-0">Contact</h5>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <div class="card-body card-body-cascade text-center">
              <button mdbBtn class="btn btn-warning btn-rounded" color="success" mdbWavesEffect
                (click)="messageShop()">Contact us
                <mdb-icon fas icon="envelope" class="ml-1"></mdb-icon>
              </button>
              <div class="card-body card-body-cascade text-center">
                <p class="card-text">Open: {{ (retailer | async)?.openingtimes }}</p>
                <p class="card-text">Phone: {{ (retailer | async)?.telephone }}</p>
              </div>

              <h4>Follow Us</h4>
              <!--Facebook-->
              <a type="button" mdbBtn floating="true" size="sm" color="fb" class="waves-light" mdbWavesEffect a
                href="{{ (retailer | async)?.facebookurl }}" target="_blank">
                <mdb-icon fab icon="facebook-f"></mdb-icon>
              </a>
              <!--Twitter-->
              <a type="button" mdbBtn floating="true" size="sm" color="tw" class="waves-light" mdbWavesEffect a
                href="{{ (retailer | async)?.twitterurl }}" target="_blank">
                <mdb-icon fab icon="twitter"></mdb-icon>
              </a>
              <!--Instagram +-->
              <a type="button" mdbBtn floating="true" size="sm" color="dribbble" class="waves-light" mdbWavesEffect a
                href="{{ (retailer | async)?.instagramurl }}" target="_blank">
                <mdb-icon fab icon="instagram"></mdb-icon>
              </a>
              <!--Pintrest +-->
              <a type="button" mdbBtn floating="true" size="sm" color="dribbble" class="waves-light" mdbWavesEffect a
                href="{{ (retailer | async)?.pintresturl }}" target="_blank">
                <mdb-icon fab icon="pinterest"></mdb-icon>
              </a>
              <!--Youtube+-->
              <a type="button" mdbBtn floating="true" size="sm" color="dribbble" class="waves-light" mdbWavesEffect a
                href="{{ (retailer | async)?.youtubeurl }}" target="_blank">
                <mdb-icon fab icon="youtube"></mdb-icon>
              </a>

            </div>
            <!--/.Card content-->

          </div>
          <!--/.Card-->

        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
      <button floating="true" size="lg" color="red" id="up_button" class="waves-light" mdbPageScroll href="#top-section"
        mdbWavesEffect style="right:35px; bottom:35px;" [pageScrollDuration]="750">
        <!-- <i class="fas fa-arrow-up fa-1x mr-4  green p-3 white-text rounded "
                  aria-hidden="true"></i> -->
        <mdb-icon fas icon="arrow-up"></mdb-icon>
      </button>
    </mdb-tab>

    <!--Panel 2-->
    <mdb-tab heading="Loyalty">
      <div class="row">
        <div class="col-12">
          <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
            reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
            porro voluptate odit minima.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
            reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
            porro voluptate odit minima.</p> -->
          <!--Grid row-->
          <div class="row">

            <!--Grid column-->
            <div class="col-md-6 mb-4">

              <!-- Card -->
              <div class="card gradient-card">

                <div class="card-image" style="background-image: url('{{ (retailer | async)?.retailerlogosmall }}')">

                  <!-- Content -->
                  <!-- <a href="/qrcode/{{docid}}/{{retailername}}/{{retailerLogo}}"> -->
                  <a routerLink="/qrcode/{{docid}}">
                    <div class="text-white d-flex h-100 mask blue-gradient-rgba">
                      <div class="first-content align-self-center p-3">
                        <h3 class="card-title">Your Loyalty Credits</h3>
                        <p class="lead mb-0">Click on this card to see details</p>
                      </div>
                      <div class="second-content align-self-center mx-auto text-center">
                        <i class="far fa-star fa-3x"></i>
                      </div>
                    </div>
                  </a>

                </div>

                <!-- Data -->
                <div class="third-content ml-auto mr-4 mb-2">
                  <p class="text-uppercase text-muted">Your Points</p>
                  <h4 class="font-weight-bold float-right">{{ (loyalty| async)?.currentPoints }}</h4>
                </div>

                <!-- Content -->
                <div class="card-body white">
                  <div class="progress md-progress">
                    <div class="progress-bar bg-primary" role="progressbar" style="width: 25%;" aria-valuenow="25"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="text-muted">Earn loyalty points as you spend</p>
                  <h4 class="text-uppercase font-weight-bold my-4">Details</h4>
                  <p class="text-muted" align="justify">Every £5 spend = 1 loyalty point*, Collect 20 points = £10 spend
                    on anything!</p>
                </div>

              </div>
              <!-- Card -->

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6 mb-4">

              <!-- Card -->
              <div class="card gradient-card">

                <div class="card-image" style="background-image: url('{{ (retailer | async)?.retailerlogosmall }}')">
                  <!-- Content -->
                  <a href="#!">
                    <div class="text-white d-flex h-100 mask purple-gradient-rgba">
                      <div class="first-content align-self-center p-3">
                        <h3 class="card-title">Premium Customer Status</h3>
                        <p class="lead mb-0">Click on this card to see details</p>
                      </div>
                      <div class="second-content  align-self-center mx-auto text-center">
                        <i class="far fa-money-bill-alt fa-3x"></i>
                      </div>
                    </div>
                  </a>

                </div>

                <!-- Data -->
                <div class="third-content  ml-auto mr-4 mb-2">
                  <p class="text-uppercase text-muted">Current Spend</p>
                  <h4 class="font-weight-bold float-right">£178.50</h4>
                </div>

                <!-- Content -->
                <div class="card-body white">
                  <div class="progress md-progress">
                    <div class="progress-bar purple lighten-2" role="progressbar" style="width: 25%" aria-valuenow="25"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="text-muted">Premium Customers enjoy 15% off of all purchases</p>
                  <h4 class="text-uppercase font-weight-bold my-4">Details</h4>
                  <p class="text-muted" align="justify">Spend £350 between 1st January – 31st December to become a
                    Premium Customer</p>
                </div>

              </div>
              <!-- Card -->

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6 mb-4">

              <!-- Card -->
              <div class="card gradient-card">

                <div class="card-image" style="background-image: url('{{ (retailer | async)?.retailerlogosmall }}')">
                  <!-- Content -->
                  <a href="#!">
                    <div class="text-white d-flex h-100 mask peach-gradient-rgba">
                      <div class="first-content align-self-center p-3">
                        <h3 class="card-title">Loyalty Offer</h3>
                        <p class="lead mb-0">Click on this card to see details</p>
                      </div>
                      <div class="second-content  align-self-center mx-auto text-center">
                        <i class="fas fa-gift fa-3x"></i>
                      </div>
                    </div>
                  </a>

                </div>

                <!-- Data -->
                <div class="third-content  ml-auto mr-4 mb-2">
                  <p class="text-uppercase text-muted">Your Points</p>
                  <h4 class="font-weight-bold float-right">20</h4>
                </div>

                <!-- Content -->
                <div class="card-body white animated">
                  <div class="progress md-progress">
                    <div class="progress-bar amber darken-3" role="progressbar" style="width: 75%" aria-valuenow="75"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="text-muted">Another Loyalty Offer</p>
                  <h4 class="text-uppercase font-weight-bold my-4">Details</h4>
                  <p class="text-muted" align="justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
                    vel dolores qui, necessitatibus aut eaque magni mollitia tenetur molestiae sit quae quos quaerat
                    amet exercitationem atque animi odio.</p>
                </div>

              </div>
              <!-- Card -->

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6 mb-4">

              <!-- Card -->
              <div class="card gradient-card">
                <div class="card-image" style="background-image: url('{{ (retailer | async)?.retailerlogosmall }}')">
                  <!-- Content -->
                  <a href="#!">
                    <div class="text-white d-flex h-100 mask aqua-gradient-rgba">
                      <div class="first-content align-self-center p-3">
                        <h3 class="card-title">Loyalty Offer</h3>
                        <p class="lead mb-0">Click on this card to see details</p>
                      </div>
                      <div class="second-content  align-self-center mx-auto text-center">
                        <i class="fas fa-gift fa-3x"></i>
                      </div>
                    </div>
                  </a>

                </div>

                <!-- Data -->
                <div class="third-content  ml-auto mr-4 mb-2">
                  <p class="text-uppercase text-muted">Your Points</p>
                  <h4 class="font-weight-bold float-right">20</h4>
                </div>

                <!-- Content -->
                <div class="card-body white">
                  <div class="progress md-progress">
                    <div class="progress-bar cyan lighten-1" role="progressbar" style="width: 50%" aria-valuenow="50"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <p class="text-muted">Another Loyalty Offer</p>
                  <h4 class="text-uppercase font-weight-bold my-4">Details</h4>
                  <p class="text-muted" align="justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
                    vel dolores qui, necessitatibus aut eaque magni mollitia tenetur molestiae sit quae quos quaerat
                    amet exercitationem atque animi odio.</p>
                </div>

              </div>
              <!-- Card -->

            </div>
            <!--Grid column-->

          </div>
          <!--Grid row-->
        </div>
      </div>
    </mdb-tab>
    <!--Panel 3-->
    <mdb-tab heading="Collect">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-8">
              <img src="{{ (retailer | async)?.retailerlogo }}" class="rounded float-left resize mb-2 mr-3" alt="...">
              <p>Here are the items you have requested for Click & Collect. We will let you know the moment your items
                are
                ready for collection from the shop.</p>
            </div>
            <div class="col-4">
              <a routerLink="/qrcode/{{docid}}">
                <img class="resize mb-2 mr-0" src="../assets/img/shutterstock_767922013.png" />Show QR Code
              </a>
            </div>
          </div>
          <div class="list-group-flush">
            <div class="list-group-item">
              <h4 class="mb-0 mt-3"><i class="fas fa-check fa-1x mr-4  green p-3 white-text rounded "
                  aria-hidden="true"></i><strong>Ready to collect</strong></h4>
            </div>
            <!-- Table  -->
            <table class="table table-bordered">
              <!-- Table head -->
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Qty</th>
                  <!-- <th>Date Ordered</th> -->
                  <th>Collect by</th>
                </tr>
              </thead>
              <!-- Table head -->

              <!-- Table body -->
              <tbody *ngFor="let item of collectArray">
                <tr *ngIf="item.status == 'Ready to collect'">
                  <!-- <div *ngIf="item.status == 'READY TO COLLECT'"> -->
                  <th scope="row">
                    <!-- Material unchecked -->
                    <div class="form-check">
                      <!-- <input type="checkbox" class="form-check-input" id="tableMaterialCheck2"> -->
                      <label class="form-check-label" for="tableMaterialCheck2">{{item.productName}} {{item.variant}}
                      </label>
                    </div>
                  </th>
                  <td>x 1</td>
                  <!-- <td>26-07-2020</td> -->
                  <td>{{item.collectBy}}</td>
                  <!-- </div> -->
                </tr>
              </tbody>
              <!-- Table body -->
            </table>
            <!-- Table  -->
            <div class="list-group-item">
              <h4 class="mb-0 mt-3"> <i class="fas fa-cog fa-1x mr-4 mr-4 grey p-3 white-text rounded"
                  aria-hidden="true"></i><strong>In Progress</strong></h4>
            </div>
            <!-- Table  -->
            <table class="table table-bordered">
              <!-- Table head -->
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Date Ordered</th>
                </tr>
              </thead>
              <!-- Table head -->

              <!-- Table body -->
              <tbody *ngFor="let item of collectArray">
                <tr *ngIf="item.status == 'In progress'">
                  <th scope="row">
                    <!-- Material unchecked -->
                    <div class="form-check">
                      <!-- <input type="checkbox" class="form-check-input" id="tableMaterialCheck3"> -->
                      <label class="form-check-label" for="tableMaterialCheck3">{{item.productName}}
                        {{item.variant}}</label>
                    </div>
                  </th>
                  <td>x 1</td>
                  <td>{{item.timestamp.seconds*1000 | date:
                    'dd/MM/yyyy h:mm:ss a'}}</td>
                  <!-- <td>tbc</td> -->
                </tr>
              </tbody>
              <!-- Table body -->
            </table>
            <!-- Table  -->

            <!-- Table  -->
            <div class="list-group-item">
              <h4 class="mb-0 mt-3"> <i class="fas fa-plus-square fa-1x mr-4 mr-4 blue p-3 white-text rounded"
                  aria-hidden="true"></i><strong>New</strong></h4>
            </div>
            <!-- Table  -->
            <table class="table table-bordered">
              <!-- Table head -->
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Date Ordered</th>
                </tr>
              </thead>
              <!-- Table head -->

              <!-- Table body -->
              <tbody *ngFor="let item of collectArray">
                <tr *ngIf="item.status == 'New'">
                  <th scope="row">
                    <!-- Material unchecked -->
                    <div class="form-check">
                      <!-- <input type="checkbox" class="form-check-input" id="tableMaterialCheck3"> -->
                      <label class="form-check-label" for="tableMaterialCheck3">{{item.productName}}
                        {{item.variant}}</label>
                    </div>
                  </th>
                  <td>x 1</td>
                  <td>{{item.timestamp.seconds*1000 | date:
                    'dd/MM/yyyy h:mm:ss a'}}</td>
                  <!-- <td>tbc</td> -->
                </tr>
              </tbody>
              <!-- Table body -->
            </table>
            <!-- Table  -->
            <!-- <div class="list-group-item">
                <p class="mb-0"><i class="fab fa-snapchat fa-1x mr-4 mr-4 purple p-3 white-text rounded" aria-hidden="true"></i>Vacation</p>
              </div> -->
          </div>
        </div>
      </div>
    </mdb-tab>
    <!--Panel 4-->
    <mdb-tab heading="Wishlist">
      <!-- <div class="row"> -->
      <div class="container">
        <div class="column">
          <div class="col-md-10">
            <!-- <ul class="list-group"> -->
            <div *ngFor="let item of wishlistArray" class="card card-ecommerce mt-2 mb-3">
              <!-- <li class="list-group-item"> -->
              <mdb-card>
                <h4 class="card-title ml-4 mt-2"><strong>{{item.retailername}}</strong></h4>
                <div class="col-sm">
                  <img src="{{item.mainimage}}" alt="thumbnail"
                    (click)="productClicked(item.retailerreference, item.productreference)" class="img-thumbnail"
                    style="width: 200px">
                </div>
                <div class="col-lg">
                  <h5 class="color=blue">{{item.productname}}</h5>
                  <h6>{{item.productprice}}</h6>
                  <!-- {{wishlistProduct.productheader}} -->
                  <!-- {{ (wishlistProduct | async)?.productheader}} -->
                  <!-- {{item.productheader}} -->
                  <!-- {{item.productdescription}} -->
                  <!-- <button type="button" class="btn btn-primary justify float-right">Click &
                                    Collect</button> -->
                  <button type="button" class="btn btn-light justify btn-sm mb-2 float-right"
                    (click)="deleteWishlistOneItem(item.productreference, item.retailername)">`Remove</button>
                </div>
              </mdb-card>
              <!-- </li> -->
            </div>
          </div>
        </div>
        <!-- </ul> -->
      </div>
      <!-- </div> -->
    </mdb-tab>


  </mdb-tabset>

  <!--Footer-->
  <footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
    <hr class="my-4">

    <!--Copyright-->
    <div class=" footer-copyright py-3">
      © 2021 Copyright:
      <a href="https://smartcontx.com" target="_blank"> StreetShops Ltd </a>
    </div>
    <!--/.Copyright-->

  </footer>
  <!--/.Footer-->