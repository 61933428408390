import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from "rxjs/operators";
import { Collect } from '../collect.model';
import { auth } from 'firebase/app';
import algoliasearch from 'algoliasearch/lite';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Product } from '../product.model';
import { Retailer } from '../retailer.model';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit {

  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  retailerLogo: string;
  retailername: string;
  userId: string;
  docid: string;
  navbarOpen = false;
  hasLoggedIn: boolean = false;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth) {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
    })
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // this.retailername = params['retailername']
      // this.retailerLogo = params['retailerlogo']
      this.docid = params['docid']
    });
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.countCollects()
      console.log("ngOnInit - The userid is ", this.userId)
    })
  }

  logoClicked() { // without type info
    this.router.navigate(['/shopmain', this.docid]);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }

}