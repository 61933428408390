import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { Collect } from '../collect.model';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  collectCollection: AngularFirestoreCollection<Collect>;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  faTag = faTag;
  faPortrait = faPortrait;
  faHeart = faHeart;
  faCog = faCog;
  userId: string;
  login: any;
  hasLoggedIn: boolean = false;

  constructor(private route: Router, public auth: AuthService,
    private afs: AngularFirestore,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth) {
    // this.AngularFireAuth.authState.subscribe(user => {
    //   if (user) this.userId = user.uid
    //   console.log("Constructor - The userid is ", this.userId)
    // })
  }

  ngOnInit() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.userId = user.uid;
      this.countCollects();
      console.log("ngOnInit - The userid is ", this.userId)
      }
    })
    // console.log("The userid is ", this.userId)
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }

  refresh(): void {
    window.location.reload();
  }

}
