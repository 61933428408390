<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    .jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .iphone {
        height: 50%;
        width: 50%;
        margin-top: 2%;
    }

    .laptop {
        height: 50%;
        width: 50%;
        margin-top: 2%;
    }

    .shopper {
        height: 75%;
        width: 75%;
        /* margin-top: 2%; */
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    #toprow {
        background: #F2F3F4;
        /* height: 6vh; */
        margin-bottom: 10px;
        /* color: rgb(74, 33, 150); */
        line-height: 6vh;
        text-align: center;
    }

    /* h5 {
      font-weight:bold;
  } */
    img.resize {
        /* max-height: 100px;
      max-width: 100px; */
        height: auto;
        width: auto;
    }

    .bg-image {
        background: url(img/image.jpg) no-repeat center top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        max-height: 50%;
    }

    .btn-space-login {
        top: 10px;
        right: 10px;
        margin-right: 10%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .btn-space-logout {
        top: 10px;
        right: 10px;
        margin-right: 10%;
        margin-top: 30px;
        /* margin-bottom: 20px; */
    }

    /* #authButton {
      top: 10px;
      right: 10px;
      padding: 2px;
      /* margin-right: 100px;
      margin-top: 30px;
      margin-bottom: 20px; */
</style>

<header>
    <div *ngIf="auth.user$ | async as user; else login">
        <button mdbBtn id="authButton" class="btn btn-warning btn-rounded float-right btn-space-logout" type="button"
            (click)="auth.signOut()" gradient="peach" size="lg" mdbWavesEffect>Logout <i
                class="fas fa-unlock"></i></button>
        <br>
    </div>

    <!-- <div *ngIf="auth.user$ | async as user; else login"></div> -->

    <ng-template #login>
        <button mdbBtn id="authButton" class="btn btn-warning btn-rounded float-right btn-space-login" type="button"
            (click)="loginUser()" gradient="peach" size="lg" mdbWavesEffect>Login <i class="fas fa-lock"></i></button>
        <br>
    </ng-template>

</header>

<img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Flanding_page_logo_image_ideas2%20(1).jpg?alt=media&token=96b27209-c139-40d9-8329-d49f406b3fc1"
    class="img-fluid" alt="..." />


<!--Section: Features v.4-->
<section class="ml-5 mr-5">

    <!--Section heading-->
    <h2 class="h1 py-5 font-weight-bold text-center">THE NEXT GENERATION SHOPPING PLATFORM</h2>
    <!--Section description-->
    <p class=" mb-5 pb-3 lead black-text text-center">STREETSHOPS bridges the gap between online and physical shops.
        <br><br>
        A platform with equal levels of integration
        services for large retailers and also immediately accessible to smaller independents.
    </p>

    <section class="text-center">
        <!--Grid row-->
        <div class="row">

            <!--Grid column-->
            <div class="col-md-12 mb-2 center-on-small-only flex-center">
                <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Flanding_page_logo_image_ideas_shopper.jpg?alt=media&token=6af45187-547f-4727-acb0-57e4d6012643"
                    class="img-fluid shopper view overlay rounded z-depth-1-half" alt="..." />
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <!-- <div class="col-md-4 mb-2 ml-5 mr-5 center-on-small-only flex-center ">
                <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Flanding_page_logo_image_ideas_ip7%20(1).jpg?alt=media&token=dc568b7b-22a3-4071-aa78-a827853ae693"
                    alt="" class="z-depth-0 iphone">
            </div> -->
            <!--Grid column-->

        </div>
    </section>

    <h2 class="h1 py-5 font-weight-bold text-center">NEW CUSTOMER INTERFACES</h2>
    <p class="px-5 mb-5 pb-3 lead black-text text-center">STREETSHOPS for the Shopper is their own smartphone and web
        desktop application, a portal to the High Street, a feature rich shopping aid.</p>
    <!--Grid row-->
    <div class="row mt-5">

        <!--Grid column-->
        <div class="col-md-3 ml-5 mt-5">

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">
                    <mdb-icon fab icon="connectdevelop" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Discover & Connect</h5>
                    <p class="black-text">Find shops by location, by name, by type, and by brand. Learn what’s new
                        across the
                        high street community.</p>
                </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">

                    <mdb-icon far icon="thumbs-up" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Favourites</h5>
                    <p class="black-text">Receive notifications on events, offers, and new releases from favourite
                        shops.
                    </p>
                </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">
                    <mdb-icon far icon="list-alt" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Style Lists</h5>
                    <p class="black-text">Create personal style lists and share across social media.</p>
                </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">
                    <mdb-icon far icon="lightbulb" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Product Suggestions</h5>
                    <p class="black-text">Personalised
                        product suggestions curated by shopkeepers for individual customers .</p>
                </div>
            </div>
            <!--Grid row-->

        </div>
        <!--Grid column-->

        <!-- Grid column-->
        <div class="col-md-4 mb-2 ml-5 center-on-small-only flex-center ">
            <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Flanding_page_logo_image_ideas_iphone7c.jpg?alt=media&token=d397367b-71a6-41e7-b01d-713fdeff3d7b"
                alt="" class="z-depth-0 iphone">
        </div>
        <!--Grid column -->

        <!--Grid column-->
        <div class="col-md-3 ml-5 mt-5">

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">
                    <mdb-icon fas icon="tag" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Click & Collect</h5>
                    <p class="black-text">Plan entire shopping trips with a common click & collect service.
                    </p>
                </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">
                    <mdb-icon far icon="star" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Loyalty</h5>
                    <p class="black-text">Gain loyalty points to redeem rewards from each shop.</p>
                </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">
                    <mdb-icon far icon="heart" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Wish List</h5>
                    <p class="black-text">Manage wish lists with a common function across every shop.</p>
                </div>
            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row mb-3">
                <div class="col-2">
                    <mdb-icon far icon="comments" size="2x" class="blue-text"></mdb-icon>
                </div>
                <div class="col-10 text-left">
                    <h5 class="font-weight-bold">Messaging</h5>
                    <p class="black-text">Connect with shops using personal chat
                        messaging.</p>
                </div>
            </div>
            <!--Grid row-->

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>
<!--/Section: Features v.4-->

<!--Section: Features v.2-->
<section class="text-center ml-5 mr-5 mt-5">

    <!--Section heading-->
    <h2 class="h1 py-5 font-weight-bold">THE ART OF PHYSICAL RETAIL WITH THE SCIENCE OF DIGITAL</h2>
    <!--Section description-->
    <p class="px-5 mb-5 pb-3 lead black-text">STREETSHOPS equips retailers with the latest digital technologies.
        <br><br>For each retailer, a shop admin application is used to manage click & collect, loyalty, catalogue,
        marketing, and packed with features to encourage customer engagement.
    </p>


    <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Fshutterstock_479205715.jpg?alt=media&token=2d1a02a2-b557-447e-acd0-ffcf58c8bd0b"
        class="img-fluid shopper view overlay rounded z-depth-1-half mb-5" alt="..." />


    <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Flanding_page_logo_image_ideas_laptop_done.jpg?alt=media&token=0313af4c-f8e2-4093-975f-77a3de120c56"
        class="img-fluid shopper" alt="..." />

    <!--Grid row-->
    <div class="row text-left mt-5">

        <!--Grid column-->
        <div class="col-md-3 mb-4">
            <div class="col-1 col-md-2 float-left">
                <mdb-icon fas icon="bullhorn" size="2x" class="blue-text"></mdb-icon>
            </div>
            <div class="col-10 col-md-9 col-lg-10 float-right">
                <h4 class="font-weight-bold">Marketing</h4>
                <p class="black-text">Create marketing notices targeted to customer segments, or the broader community.
                </p>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-3 mb-4">
            <div class="col-1 col-md-2 float-left">
                <mdb-icon fas icon="users" size="2x" class="blue-text"></mdb-icon>
            </div>
            <div class="col-10 col-md-9 col-lg-10 float-right">
                <h4 class="font-weight-bold">Customer Relations</h4>
                <p class="black-text">Handle click & collect orders, retail loyalty program, and communicate directly
                    with customers by topic.</p>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-3 mb-4">
            <div class="col-1 col-md-2 float-left">
                <mdb-icon fas icon="boxes" size="2x" class="blue-text"></mdb-icon>
            </div>
            <div class="col-10 col-md-9 col-lg-10 float-right">
                <h4 class="font-weight-bold">Product Catalogue</h4>
                <p class="black-text">Create a product catalogue using existing digital assets, embellish with audio and
                    video.</p>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-3 mb-4">
            <div class="col-1 col-md-2 float-left">
                <mdb-icon fas icon="store" size="2x" class="blue-text"></mdb-icon>
            </div>
            <div class="col-10 col-md-9 col-lg-10 float-right">
                <h4 class="font-weight-bold">Shop Front</h4>
                <p class="black-text">Customise the digital shop front with video, image galleries, and editorial.</p>
            </div>
        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>
<!--Section: Features v.2-->

<!--Section: Features v.2-->
<section class="text-center mt-5">
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ZS9BGGhbSCM"
                allowfullscreen></iframe>
        </div>
        <div class="col-md-2"></div>
    </div>
</section>

<!--Section: Features v.2-->
<section class="text-center ml-5 mr-5 mt-5 -mb-5">
    <!--Section heading-->
    <h2 class="h1 py-5 font-weight-bold">Join the Beta</h2>
    <div class="container">
        <div class="row">
            <div class="col-md">

                <img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Flanding_page_logo_image_ideas_map_notts%20(2).jpg?alt=media&token=fb8d0b19-87f6-45f7-b3ef-0c8c3ac62caa"
                    class="img-fluid view overlay rounded z-depth-1-half" alt="" />

            </div>
            <div class="col-md">
                <p class="px-5 mb-5 mt-3 pb-3 lead black-text">
                    We are now onboarding Nottingham independent retailers.<br><br>If you are a Nottingham retailer, we
                    will be delighted if you can
                    join us on the STREETSHOPS Beta program.</p>
            </div>
            <div class="col-md mt-5">
                <div class="mt-4">
                    <button mdbBtn color="primary" class="waves-light" type="button" (click)="joinBeta.toggle()"
                        mdbWavesEffect>
                        <mdb-icon fas icon="pen" class="mr-1"></mdb-icon>Contact us
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Section: Contact v.2 -->
    <section class="my-5 mt-5 mb-5 ml-2 mr-2">

        <div mdbCollapse #joinBeta="bs-collapse">
            <!-- Section heading -->
            <div class="container" style="margin-top:50px ;" id="signup">
                <div class="row">
                    <!-- <div class="col-md-2"></div> -->
                    <div class="col-md-8">
                        <!-- Material form contact -->
                        <!-- <div class="row">
                    <div class="col-md-6"> -->
                        <mdb-card>
                            <mdb-card-header class="elegant-color-dark text-center white-text py-4">
                                <h5><strong>Contact us</strong></h5>
                            </mdb-card-header>

                            <!-- Card content -->
                            <mdb-card-body class="px-lg-5 pt-0">
                                <!-- Form -->
                                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                    <!-- Name -->
                                    <div class="md-form mt-3">
                                        <input type="text" id="materialContactFormName" class="form-control" mdbInput
                                            mdbValidate formControlName="nameInput" />
                                        <label for="materialContactFormName">Name</label>
                                        <mdb-error *ngIf="nameInput.invalid && (nameInput.dirty || nameInput.touched)">
                                            Input invalid
                                        </mdb-error>
                                        <mdb-success *ngIf="nameInput.valid && (nameInput.dirty || nameInput.touched)">
                                        </mdb-success>
                                    </div>

                                    <!-- E-mail -->
                                    <div class="md-form">
                                        <input type="email" id="materialContactFormEmail" class="form-control" mdbInput
                                            mdbValidate formControlName="emailInput" />
                                        <label for="materialContactFormEmail">E-mail</label>
                                        <mdb-error
                                            *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">Input
                                            invalid
                                        </mdb-error>
                                        <mdb-success
                                            *ngIf="emailInput.valid && (emailInput.dirty || emailInput.touched)">
                                        </mdb-success>
                                    </div>

                                    <!-- Message -->
                                    <div class="md-form">
                                        <textarea type="text" id="materialContactFormMessage"
                                            class="form-control md-textarea" mdbInput mdbValidate
                                            formControlName="message"></textarea>
                                        <label for="materialContactFormMessage">Message</label>
                                        <mdb-error *ngIf="message.invalid && (message.dirty || message.touched)">Input
                                            invalid</mdb-error>
                                        <mdb-success *ngIf="message.valid && (message.dirty || message.touched)">
                                        </mdb-success>
                                    </div>


                                    <div class="row" style="margin-top:150px ;"></div>
                                    <!-- Send button -->
                                    <button mdbBtn class="pt-5" color="success" rounded="true" block="true"
                                        class="z-depth-0 my-4 waves-effect" mdbWavesEffect type="submit"
                                        [disabled]="!contactForm.valid">
                                        Send
                                    </button>
                                </form>
                                <!-- Form -->
                            </mdb-card-body>
                        </mdb-card>
                        <!-- Material form contact -->
                        <!-- </div> -->
                        <!-- </div> -->
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- Section: Contact v.2 -->
</section>


<img src="https://firebasestorage.googleapis.com/v0/b/ssdev-54939.appspot.com/o/Retailers%2Flanding_page_logo_image_ideas_footer_done%20(1).jpg?alt=media&token=f014185e-34a8-49a8-b57a-3bbebf38fd58"
    class="img-fluid mt-5" alt="..." />



<!--Footer-->
<footer class="page-footer text-center font-small mdb-color darken-2 mt-4 wow fadeIn">
    <hr class="my-4">

    <!--Copyright-->
    <div class=" footer-copyright py-3">
        © 2021 Copyright:
        <a href="https://smartcontx.com" target="_blank"> StreetShops™ Ltd </a>
    </div>
    <!--/.Copyright-->

</footer>
<!--/.Footer-->