import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { auth } from 'firebase';
import { Observable } from 'rxjs';
import { map, take } from "rxjs/operators";
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';
import { Product } from '../product.model';
import { Retailer } from '../retailer.model';
import { Wishlist } from '../wishlist.model';
import { Collect } from '../collect.model';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  // docid: string;
  productsCollection: AngularFirestoreCollection<Product>;
  retailerCollection: AngularFirestoreCollection<Retailer>;
  wishlistCollection: AngularFirestoreCollection<Wishlist>;
  collectCollection: AngularFirestoreCollection<Collect>;
  wishlists: Observable<Wishlist[]>;

  userId: string;
  collectDoc: AngularFirestoreDocument<Collect>;
  collect: Observable<any>;
  collectArray: Array<AngularFirestoreDocument>;
  collectData: any;
  collectProductData: any;
  collects: Observable<Collect[]>;
  collectsdata: any;
  collectItemsCount: 0;
  hasLoggedIn: boolean = false;

  wishlistDoc: AngularFirestoreDocument<Wishlist>;
  wishlistdata: any;
  wishlistDocRefData: any;
  wishlistDocRef: DocumentReference;
  wishlist: Observable<any>;
  wishlistProduct: any;
  productDescription: Array<any>;
  wishlistArray: Array<AngularFirestoreDocument>;
  wishlistOneArray: Array<AngularFirestoreDocument>;
  allWishlistArray: Array<AngularFirestoreDocument>;
  productheader: Array<string>;
  wishlistLength: 0;
  retailername: string;
  runGetAllOnce: boolean = false;
  oneRetailerSelected: boolean = false;

  public wishlistProducts: Wishlist[] = [];

  constructor(private afs: AngularFirestore, private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    private AngularFireAuth: AngularFireAuth) {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
    })
  }

  ngOnInit() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) this.userId = user.uid
      this.collectArray = [];
      this.countCollects()
      console.log("shopmain ngOnInit - The userid is ", this.userId)
      this.allWishlistArray = [];
      this.getWishlistAll('All');
    })
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  productClicked(docid: string, productid: string) {
    this.router.navigate(['/product', docid, productid]);
    console.log("Product item selected: ", productid)
  }

  countCollects() {
    this.collectCollection = this.afs.collection('Customers').doc(this.userId).collection('CollectStatus', ref => ref.where('status', '==', 'READY TO COLLECT'));
    this.collectCollection.valueChanges()
      .pipe(map(res => {
        this.collectsdata = res;
        this.collectItemsCount = this.collectsdata.length;
        console.log('Number of Collect items is: ', this.collectItemsCount);
      })
      )
      .subscribe();
  }

  checkAuth() {
    this.AngularFireAuth.authState.subscribe(user => {
      if (user) { this.hasLoggedIn = true }
      else if (!this.hasLoggedIn) {
        console.log("user has NOT logged in");
        this.toastr.warning('Please Login', 'STREETSHOPS', {
          timeOut: 2000,
        });
      }
    })
  }

  getWishlistAll(retailer: string) {
    console.log('Wishlist data being gathered');
    // if (!this.runGetAllOnce) {
    this.oneRetailerSelected = false;
    if (!this.oneRetailerSelected) {
      this.allWishlistArray = [];
      this.wishlistArray = [];
      this.wishlistOneArray = [];
      var runCount = 0;
      if (retailer === 'All') {
        this.wishlistCollection = this.afs.collection('Customers').doc(this.userId).collection('Wishlist')
      } else {
        this.wishlistCollection = this.afs.collection('Customers').doc(this.userId).collection('Wishlist', ref => ref.where('retailername', '==', retailer))
      }
      this.wishlistCollection.valueChanges()
        .pipe(
          map(res => {
            this.allWishlistArray = [];
            this.wishlistArray = [];
            this.wishlistOneArray = [];
            this.wishlistdata = res;
            console.log('#######   runcount is now: ', runCount);
            console.log('Wishlist length of items is: ', this.wishlistdata.length);
            runCount = runCount + 1;
            if (runCount < 2) {
              this.wishlistLength = this.wishlistdata.length;
              console.log('1st WishlistLength is: ', this.wishlistLength);
              console.log('Wishlist data All is: ', this.wishlistdata);

              for (var i = 0; i <= this.wishlistLength - 1; i++) {
                console.log('Wishlist productreference is: ', this.wishlistdata[i].productreference);
                console.log('Wishlist retailerreference is: ', this.wishlistdata[i].retailerreference);
                this.wishlistDoc = this.afs.collection('Retailers').doc(this.wishlistdata[i].retailerreference).collection('Products').doc(this.wishlistdata[i].productreference);
                this.wishlistDoc.ref.get().then((doc) => {
                  this.wishlistProduct = doc.data();
                  if (this.allWishlistArray.indexOf(this.wishlistProduct.retailername) == -1) {
                    this.allWishlistArray.push(this.wishlistProduct.retailername);
                  }
                  if (this.wishlistArray.indexOf(this.wishlistProduct.productreference) == -1) {
                    this.wishlistArray.push(this.wishlistProduct);
                  }
                  // this.wishlistArray.push(this.wishlistProduct);
                  console.log('Product description is: ', this.wishlistProduct.productdescription);
                  console.log('Wishlist Product Header is : ', this.wishlistProduct.productheader);
                  console.log('Wishlist Product Image is : ', this.wishlistProduct.mainimage);
                });
              }
            }

          })
        )
        .subscribe();
      //   this.runGetAllOnce = true;
      // }
    }
  }

  getWishlistOne(retailer: string) {
    console.log('Wishlist data being gathered');
    this.oneRetailerSelected = true;
    // this.wishlistArray = [];
    var runCount = 0;
    this.wishlistCollection = this.afs.collection('Customers').doc(this.userId).collection('Wishlist', ref => ref.where('retailername', '==', retailer))
    this.wishlistCollection.valueChanges()
      .pipe(
        map(res => {
          this.allWishlistArray = [];
          this.wishlistArray = [];
          this.wishlistOneArray = [];
          runCount = runCount + 1;
          if (runCount < 2) {
            this.wishlistdata = res;
            console.log('Wishlist length of items is: ', this.wishlistdata.length);
            this.wishlistLength = this.wishlistdata.length;
            console.log('1st WishlistLength is: ', this.wishlistLength);
            console.log('Wishlist data All is: ', this.wishlistdata);
            for (var i = 0; i <= this.wishlistLength - 1; i++) {
              console.log('Wishlist productreference is: ', this.wishlistdata[i].productreference);
              console.log('Wishlist retailerreference is: ', this.wishlistdata[i].retailerreference);
              this.wishlistDoc = this.afs.collection('Retailers').doc(this.wishlistdata[i].retailerreference).collection('Products').doc(this.wishlistdata[i].productreference);
              this.wishlistDoc.ref.get().then((doc) => {
                this.wishlistProduct = doc.data();
                this.wishlistOneArray.push(this.wishlistProduct);
                console.log('Product description is: ', this.wishlistProduct.productdescription);
                console.log('Wishlist Product Header is : ', this.wishlistProduct.productheader);
                console.log('Wishlist Product Image is : ', this.wishlistProduct.mainimage);
              });
            }
          }
        })
      )
      .subscribe();
  }

  deleteWishlistItem(productreference: string, retailer: string) {
    this.afs.collection('Customers').doc(this.userId).collection('Wishlist').doc(productreference).delete()
      .then(function () {
        // this.getWishlistAll();
        // this.runGetAllOnce = false;
        console.log("Wishlist Document successfully deleted");
        // if (retailer != 'All') {
        //   this.getWishlistOne(retailer);
        // } else {
        //   this.getWishlistAll('All');
        // }
      })
      .catch(function (error) {
        console.error("Error deleting document: ", error);
      });
    this.getWishlistAll('All');
  }

  deleteWishlistOneItem(productreference: string, retailer: string) {
    this.afs.collection('Customers').doc(this.userId).collection('Wishlist').doc(productreference).delete()
      .then(function () {
        // this.getWishlistAll();
        // this.runGetAllOnce = false;
        console.log("Wishlist Document successfully deleted");
        // if (retailer != 'All') {
        //   this.getWishlistOne(retailer);
        // } else {
        //   this.getWishlistAll('All');
        // }
      })
      .catch(function (error) {
        console.error("Error deleting document: ", error);
      });
    this.getWishlistOne(retailer);
  }

}
