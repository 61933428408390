import { Injectable } from '@angular/core';
import {
  AngularFirestoreDocument,
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Product } from './product.model';
import { Observable } from 'rxjs';

@Injectable()
export class ProductsService {
// moviesCollectection: AngularFirestoreCollection<Movie>;
// movies: Observable<Movie[]>;

  constructor(public afs: AngularFirestore) {
    // this.movies = this.afs.collection('Movies').doc('zvlLqw1G8DhqM7X0LFiI').valueChanges();
    // this.movies = this.afs.collection('Movies').snapshotChanges().map(changes  => {
    //   return changes.map(a  => {
    //     const data = a.payload.doc.data() as Movie;
    //     data.starring = a.payload.doc.starring
    //     return data;
    //   });
    // });
  }

  // getMovies(){
  //   return this.movies;
  // }
}
