<style>
    #retailerlogo {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 150px;
    }

    #retailerlogo:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }

    .jumbotron1 {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .map-container-5 {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .map-container-5 iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    #up_button {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #categoryimage {
        /* border: 1px solid #ddd;
        border-radius: 4px; */
        padding: 5px;
        width: 50px;
    }

    #toprow {
        background: #F2F3F4;
        /* height: 6vh; */
        margin-bottom: 10px;
        /* color: rgb(74, 33, 150); */
        line-height: 6vh;
        text-align: center;
    }

    /* h5 {
      font-weight:bold;
  } */
    img.resize {
        /* max-height: 100px;
      max-width: 100px; */
        height: auto;
        width: auto;
    }

    .bg-image {
        background: url(img/image.jpg) no-repeat center top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        max-height: 50%;
    }

    .btn-space-login {
        top: 10px;
        right: 10px;
        margin-right: 100px;
        margin-top: 30px;
        /* margin-bottom: 20px; */

    }

    .btn-space-logout {
        top: 10px;
        right: 10px;
        margin-top: -20px;
        margin-right: 100px;
        margin-bottom: 20px;

    }

    /* #authButton {
      top: 10px;
      right: 10px;
      padding: 2px;
      /* margin-right: 100px;
      margin-top: 30px;
      margin-bottom: 20px; */
</style>

<!-- <div *ngIf="auth.user$ | async as user; else login"> -->

<!-- <button class="button" (click)="auth.googleSignin()">Login with Google</button> -->

<!-- <ng-template .login>
    <button class="button" (click)="auth.googleSignin()">Login with Google</button>
  </ng-template> -->


<header id="top-section">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark navbar-static-top">
        <a class="navbar-brand" routerLink="/">STREETSHOPS</a>
        <!-- Collapsible content -->

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" pageScroll routerLink="/home">What's New</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/favourites">Favourites</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/shops">Shops</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/style">Style</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" pageScroll routerLink="/messages">Messages<span class="badge  ml-2"><i
                                class="far fa-envelope"></i></span><span class="badge badge-danger ml-1">1</span></a>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto nav-flex-icons">
                <li class="nav-item">
                    <a class="nav-link" href="/shoptypes">
                        <!-- <fa-icon [icon]='faTag'></fa-icon>  -->
                        Collect<span class="badge  ml-2"><i class="fas fa-tag"></i></span><span
                            class="badge badge-danger ml-1">4</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <!-- <fa-icon [icon]='faHeart'></fa-icon> -->
                        Wishlist
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <!-- <fa-icon [icon]='faPortrait'></fa-icon>  -->
                        Profile
                    </a>
                </li>


                <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
              aria-expanded="false">User1</a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#">User2</a>
                <a class="dropdown-item" href="#">User3</a>
                <a class="dropdown-item" href="#">Kids</a>
              </div>
            </li> -->
            </ul>
        </div>
    </nav>
</header>

<!-- Sign in button -->
<button mdbBtn id="authButton" class="btn btn-warning btn-rounded float-right btn-space-login" type="button"
    (click)="auth.googleSignin()" gradient="peach" size="lg" mdbWavesEffect>Google Login <i
        class="fas fa-lock"></i></button>


<div class="card">
    <!--Card content-->
    <div class="card-body px-lg-5">
        <!-- Form -->
        <form class="text-center" style="color: #757575;" action="#!">
            <p>Login with your Google account.</p>
            <p>
                <a href="" target="_blank">Terms & Conditions</a>
            </p>
        </form>
        <!-- Form -->
    </div>
</div>


<div *ngIf="auth.user$ | async as user" action="backHome()"></div>
