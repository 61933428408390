import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { faTag, faPortrait, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Component({
  selector: 'modal-form',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  // validatingForm: FormGroup;

  constructor(private router: Router, public auth: AuthService,
    private AngularFireAuth: AngularFireAuth) {
  }

  ngOnInit() {
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  checkAuth(next, state): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map(user => !!user), // <-- map to boolean
      tap(loggedIn => {
        if (loggedIn) {
          console.log('Google logged in - access allowed');
          this.router.navigate(['/home']);
        }
        // else {
        // console.log('access denied');
        // this.router.navigate(['/login']);
        // }
      })
    );
  }

  backHome() {
    console.log('Google logged in - access allowed');
          this.router.navigate(['/home']);
  }

}